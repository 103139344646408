import { createSlice } from '@reduxjs/toolkit'
import { cleanCheckboxArray } from '../../helpers/checkboxArray'

export const FormReducer = createSlice({
    name: 'form',
    initialState: {
        data: {},
        resultObject: { name: '', result: '' },
    },
    reducers: {
        setData: (state, action) => {
            const newData = action.payload

            state.data = newData
        },
        addString: (state, action) => {
            const id = action.payload
            const object = { ...state.data }

            object[id] = ''

            state.data = object
        },
        addArray: (state, action) => {
            const id = action.payload
            const object = { ...state.data }

            object[id] = []

            state.data = object
        },
        setString: (state, action) => {
            const { id, value } = action.payload

            const object = { ...state.data }

            object[id] = value

            state.data = object
        },
        setCheckbox: (state, action) => {
            const { id, value, max } = action.payload

            const object = { ...state.data }

            const array = object[id]

            if (array.includes(value)) {
                array[value] = null
                object[id] = array
            } else {
                if (max === 0 || cleanCheckboxArray(array).length < max) {
                    object[id][value] = value
                } else {
                    alert('Нельзя добавить больше вариантов')
                }
            }

            state.data = object
        },
        setMultipleCheckbox: (state, action) => {
            const { id, variantId, variantValue, max } = action.payload

            const object = { ...state.data }

            const array = object[id]

            if (array.includes(variantValue)) {
                array[variantId] = null
                object[id] = array
            } else {
                if (max === 0 || cleanCheckboxArray(array).length < max) {
                    array[variantId] = variantValue
                } else {
                    alert('Нельзя добавить больше вариантов')
                }
            }

            state.data = object
        },
        addTable: (state, action) => {
            const { id, value } = action.payload

            const object = { ...state.data }
            const oldArray = object[id]

            oldArray.push(value)

            state.data = object
        },
        deleteTable: (state, action) => {
            const id = action.payload

            const object = { ...state.data }
            const oldArray = object[id]

            oldArray.pop()

            state.data = object
        },
        setMotivationObject: (state, action) => {
            const { motivationId, questionId, value } = action.payload

            const object = { ...state.data }
            const oldObject = object[motivationId]

            oldObject[questionId] = value

            state.data = object
        },
        setCheckboxInput: (state, action) => {
            const { questionId, variantId, value } = action.payload

            const object = { ...state.data }

            const array = object[questionId]

            array[variantId] = value

            state.data = object
        },
        setResultObject: (state, action) => {
            state.resultObject = action.payload
        }
    }
})

export const { setData, addString, addArray, setString, setCheckbox, setMultipleCheckbox, addTable, deleteTable, setMotivationObject, setCheckboxInput, setResultObject } = FormReducer.actions

export default FormReducer.reducer