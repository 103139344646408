import React from 'react'

const MyTableHead = ({ columns }) => {
    return (
        <thead>
            <tr className="my-table-head">
                {
                    columns.map((column) => (
                        <th key={column.id} className="my-table__cell">{column.text}</th>
                    ))
                }
            </tr>
        </thead>
    )
}

export default React.memo(MyTableHead)