import emailjs from '@emailjs/browser'
import { React, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setEndTime, setFinish } from '../features/counter/PersonReducer'
import { calculateAssessment } from '../features/counter/PathsReducer'

import {addFinalTags} from '../features/counter/TaskThreeReducer'

const PathsAnalysis = () => {
    const dispatch = useDispatch()

    const [ active, setActive ] = useState(false)

    const personName = useSelector((state) => state.person.name)
    const personSurname = useSelector((state) => state.person.surname)
    const personMiddleName = useSelector((state) => state.person.middleName)

    const resultString = useSelector((state) => state.paths.resultString)

    const firstArrayResultString = useSelector((state) => state.taskThree.firstArrayResultString)
    const secondArrayResultString = useSelector((state) => state.taskThree.secondArrayResultString)
    const thirdArrayResultString = useSelector((state) => state.taskThree.thirdArrayResultString)
    const fourthArrayResultString = useSelector((state) => state.taskThree.fourthArrayResultString)
    const fifthArrayResultString = useSelector((state) => state.taskThree.fifthArrayResultString)

    const pathsArray = useSelector((state) => state.paths.pathsArray)

    const checked = useSelector((state) => state.afterTaskOne.checkedValueString)
    const missingValue = useSelector((state) => state.afterTaskOne.missingValue)
    const descriptionValue = useSelector((state) => state.afterTaskOne.descriptionValue)

    const startTime = useSelector((state) => state.person.startTime)
    let endTime = useSelector((state) => state.person.endTime)

    const form = useRef()

    const show = () => {
        if (personName !== "" && personSurname !== "" && personMiddleName !== "" && pathsArray.length !== 0 && missingValue !== "" && descriptionValue !== "") {
            dispatch(setEndTime(new Date().toString()))
            dispatch(calculateAssessment())

            dispatch(addFinalTags())

            setActive(true)
        } else {
            alert("Заполните все поля")
        }
    }

    const close = () => {
        dispatch(setEndTime(''))
        setActive(false)
    }

    const send = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_ly6g8fs',
             'template_rnmz7vf',
              form.current,
               '9Vy5M8XkviRuxwvGM')
            .then((result) => {
                console.log(result.text)
            }, (error) => {
                console.log(error.text)
            })

        dispatch(setFinish(true))
        close()
    }

    return (
        <div className="display flex justify-content-center">
            <button onClick={() => show()} className={`display-flex flex-direction-column margin-bottom margin-inline-auto text-justify-center ${active === false ? '' : 'element-hidden'} buttonStyle`}>Отправить</button>

            <div className = {` ${active === true ? '' : 'element-hidden'} display-flex flex-direction-row`}>
                <form ref={form} onSubmit={send} className = {`display-flex flex-direction-row`}>
                    <input type="hidden" name="from" value={`${personSurname} ${personName} ${personMiddleName}`} />
                    <input type="hidden" name="planner_one" value={firstArrayResultString} />
                    <input type="hidden" name="planner_two" value={secondArrayResultString} />
                    <input type="hidden" name="planner_three" value={thirdArrayResultString} />
                    <input type="hidden" name="planner_four" value={fourthArrayResultString} />
                    <input type="hidden" name="planner_five" value={fifthArrayResultString} />
                    <input type="hidden" name="route"  value={resultString} />
                    <input type="hidden" name="isTrue"  value={checked} />
                    <input type="hidden" name="missing"  value={missingValue} />
                    <input type="hidden" name="decision"  value={descriptionValue} />
                    <input type="hidden" name="start" value={startTime} />
                    <input type="hidden" name="end" value={endTime} />

                    <input type="submit" value="Да" className="margin-right margin-bottom pointer buttonStyle" />
                </form>
                <button className="margin-bottom buttonStyle" onClick={() => close()}>Нет</button>
            </div>


        </div>
    )
}

export default PathsAnalysis