import React from 'react'

import logo from '../images/logo.svg'

const Navbar = () => {
  return (
    <div className="navbar">
        <img src={logo} alt="Логотип" className="navbar__logo" />
    </div>
  )
}

export default React.memo(Navbar)