import { createSlice } from '@reduxjs/toolkit'

export const PersonReducer = createSlice({
    name: 'person',
    initialState: {
        name: '',
        surname: '',
        middleName: '',
        startTime: '',
        endTime: '',
        isFinished: false,
    },
    reducers: {
        setPersonName: (state, action) => {
            state.name = action.payload
        },
        setPersonSurname: (state, action) => {
            state.surname = action.payload
        },
        setPersonMiddleName: (state, action) => {
            state.middleName = action.payload
        },
        setStartTime: (state, action) => {
            state.startTime = action.payload
        },
        setEndTime: (state, action) => {
            state.endTime = action.payload
        },
        setFinish: (state, action) => {
            state.isFinished = action.payload
        }
    }
})

export const {setPersonName, setPersonSurname, setPersonMiddleName, setStartTime, setEndTime, setFinish} = PersonReducer.actions

export default PersonReducer.reducer
