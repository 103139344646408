import {createSlice} from '@reduxjs/toolkit'

import {paths} from '../../data/data'

export const RouteReducer = createSlice({
    name: "route",
    initialState: {
        routes: [
            {
                firstColumn: "ДОМ",
                secondColumn: "",
                thirdColumn: "13.00",
            },
        ],
        checked: false,
        missing: "",
        reasons: "",
        result: "",
    },
    reducers: {
        addRoute: (state, action) => {
            state.routes.push(action.payload)
        },
        deleteRoute: (state) => {
            if (state.routes.length > 1) {
                state.routes.pop()
            }
        },
        setChecked: (state, action) => {
            state.checked = action.payload
        },
        setMissing: (state, action) => {
            state.missing = action.payload
        },
        setReasons: (state, action) => {
            state.reasons = action.payload
        },
        analysis: (state) => {
            const array = state.routes

            let result = ""

            let prevEntranceName = ""
            let prevEntranceTime = ""
            let prevExitTime = ""

            let entranceName = ""
            let entranceTime = ""
            let exitTime = ""

            let finalAssessment = 0

            if (array.length > 1) {

                result += `<table style="border-collapse: collapse;"><tbody><tr><th style="text-align: center; border: 2px solid black; padding: 4px;">Пункт посещения</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Время прибытия</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Время убытия</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Критерий 1</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Критерий 2</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Критерий 3</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Баллы</th></tr><tr><td style="text-align: center; border: 2px solid black; padding: 4px;">ДОМ</td><td style="text-align: center; border: 2px solid black; padding: 4px;"></td><td style="text-align: center; border: 2px solid black; padding: 4px;">13:00</td><td style="text-align: center; border: 2px solid black; padding: 4px;"></td><td style="text-align: center; border: 2px solid black; padding: 4px;">Время убытия установлено верно</td><td style="text-align: center; border: 2px solid black; padding: 4px;">Событий нет</td><td style="text-align: center; border: 2px solid black; padding: 4px;"></td></tr>`

                for (let i = 1; i < array.length; i++) {

                    let routeStatus = ""
                    let entranceTimeStatus = ""
                    let exitTimeStatus = ""
                    let placeStatus = "Событий нет"

                    let prevObject = array[i - 1]
                    let object = array[i]

                    let assessment = 0

                    prevEntranceName = prevObject.firstColumn
                    prevEntranceTime = prevObject.secondColumn
                    prevExitTime = prevObject.thirdColumn

                    entranceName = object.firstColumn
                    entranceTime = object.secondColumn
                    exitTime = object.thirdColumn

                    /* Проверка пути */

                    let pathsObject = paths.find(f => f.name === entranceName)

                    let find = pathsObject.listOfPlaces.find(f => f.name === prevEntranceName)

                    let entranceTimeArray = entranceTime.split(".")
                    let exitTimeArray = exitTime.split(".")
                    let prevExitTimeArray = prevExitTime.split(".")

                    if (typeof find !== 'undefined') {
                        routeStatus = "Правильный путь"

                        if (entranceName === "ОФИС" && i === array.length - 1 && ((parseInt(entranceTimeArray[0], 10) === 13 && parseInt(entranceTimeArray[1], 10) < 60) || (parseInt(entranceTimeArray[0], 10) === 14 && parseInt(entranceTimeArray[1], 10) === 0))) {
                            assessment += 20
                            placeStatus = `<span style="color: green;">Успел на работу</span>`
                        } else if (prevEntranceName === "МАГАЗИН" && entranceName === "КВАРТИРА ТЕЩИ") {
                            placeStatus = `<span style="color: green;">Заменил смеситель</span>`
                        } else if (prevEntranceName === "ХИМЧИСТКА" && entranceName === "ДОМ") {
                            placeStatus = `<span style="color: green;">Занёс вещи из химчистки</span>`
                        }

                        assessment += pathsObject.assessment
                    } else {
                        routeStatus = `<span style="color: red;">Неправильный путь</span>`
                        entranceTimeStatus = `<span style="color: red;">Нельзя посчитать</span>`
                    }

                    if (parseInt(entranceTimeArray[0], 10) <= parseInt(prevExitTimeArray[0], 10)) {

                        /* Проверка времени прибытия */
                        if (routeStatus === "Правильный путь" && parseInt(entranceTimeArray[1], 10) - parseInt(prevExitTimeArray[1], 10) === find.minutes) {
                            entranceTimeStatus = `<span style="color: green;">Время прибытия установлено верно</span>`
                        } else {
                            entranceTimeStatus = `<span style="color: red;">Время прибытия установлено неверно</span>`
                        }
                    } else {
                        entranceTimeStatus = `<span style="color: red;">Нельзя посчитать</span>`
                    }

                    /* Проверка времени убытия */
                    if (parseInt(exitTimeArray[0], 10) <= parseInt(entranceTimeArray[0], 10)) {

                        if (entranceName !== "ОФИС" && entranceName !== "ПАРК" && entranceName !== "КВАРТИРА ТЕЩИ") {
                            if (parseInt(exitTimeArray[1], 10) - parseInt(entranceTimeArray[1], 10) === pathsObject.time) {
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        } else if (entranceName === "ПАРК" || entranceName === "КВАРТИРА ТЕЩИ") {
                            if (parseInt(exitTimeArray[1], 10) - parseInt(entranceTimeArray[1], 10) >= pathsObject.time) {
                                assessment += pathsObject.bonusAssessment * (parseInt(exitTimeArray[1], 10) - parseInt(entranceTimeArray[1], 10) - pathsObject.time)

                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        }
                    } else {
                        exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                    }

                    finalAssessment += assessment

                    result += `<tr><td style="text-align: center; border: 2px solid black; padding: 4px;">${entranceName}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${entranceTime}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${exitTime}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${entranceTimeStatus}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${exitTimeStatus}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${placeStatus}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${assessment}</td></tr>`
                }
            }

            result += `</tbody></table><br/><span style="font-weight: bold;">Итоговая оценка: ${finalAssessment}</span><br/><br/>`

            if (state.checked === true) {
                result += `<span style="color: green;">Все пункты посещены</span><br/><br/>`
            } else {
                result += `<span style="color: red;">Не все пункты посещены</span><br/><br/>`
            }

            state.result = result
        }
    }
})


export const {addRoute, deleteRoute, setChecked, setMissing, setReasons, analysis} = RouteReducer.actions

export default RouteReducer.reducer
