import { createSlice } from '@reduxjs/toolkit'

import { places, assessments } from './data'

export const PathsReducer = createSlice({
    name: 'paths',
    initialState: {
        pathsArray: [],
        resultString: '',
    },
    reducers: {
        addToArrays: (state, action) => {
            state.pathsArray.push(action.payload)
        },
        deleteFromArrays: (state) => {
            state.pathsArray.pop()
        },
        deleteFromResultString: (state) => {

            let newResultString = ''

            if (state.pathsArray.length === 0) {
                state.resultString = newResultString
            } else {
                let resultString = state.resultString

                let newResultArray = resultString.replaceAll(";&#13", "#").split(";")

                newResultArray.pop()
                newResultArray.pop()                

                for (let i = 0; i < newResultArray.length; i++) {
                    newResultString += newResultArray[i].replaceAll("#", ";&#13") + ";"
                }

                state.resultString = newResultString
            }
        },
        calculateAssessment: (state) => {
            let array = state.pathsArray
            let finalAssessment = 0

            let resultString = '<table style="border-collapse: collapse;"><thead><tr><th style="border: 2px solid black; padding: 4px; text-align: center;">Место входа</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Время выхода</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Место входа</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Время входа</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Критерий 1</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Критерий 2</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Критерий 3</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Критерий 4</th><th style="border: 2px solid black; padding: 4px; text-align: center;">Баллы</th></tr></thead><tbody>'

            for (let i = 0; i < array.length; i++) {

                let newString = ""
    
                let exitPlace = ''
                let exitTime = ''
                let entrancePlace = ''
                let entranceTime = ''
                let placeStatus = ''
                let exitTimeStatus = ''
                let entranceTimeStatus = ''
                let timingStatus = 'Событий нет'
    
                let object = array[i]
    
                exitPlace = object.exitPlace
                exitTime = object.exitTime
                entrancePlace = object.entrancePlace
                entranceTime = object.entranceTime
        
                let exitObject = places.find(f => f.name === exitPlace)
        
                let exitFind = exitObject.listOfPlaces.find(f => f.placeName === entrancePlace)
        
                let pathsEntranceTime = entranceTime.split('.')
                let pathsExitTime = exitTime.split('.')
    
                let assessment = 0
        
                if (typeof exitFind !== 'undefined') {
                    placeStatus = `<span style="color: green;">Правильный путь</span>`
        
                    if (parseInt(pathsEntranceTime[0], 10) > parseInt(pathsExitTime[0], 10)) {
                        if ((60 - parseInt(pathsExitTime[1], 10) + parseInt(pathsEntranceTime[1], 10)) === exitFind.time) {
                            entranceTimeStatus = `<span style="color: green;">Время прибытия установлено верно</span>`
                        } else {
                            entranceTimeStatus = `<span style="color: red;">Время прибытия установлено неверно</span>`
                        }
                    } else {
                        if (parseInt(pathsEntranceTime[1], 10) - parseInt(pathsExitTime[1], 10) === exitFind.time) {
                            entranceTimeStatus = `<span style="color: green;">Время прибытия установлено верно</span>`
                        } else {
                            entranceTimeStatus = `<span style="color: red;">Время прибытия установлено неверно</span>`
                        }
                    }
                } else {
                    entranceTimeStatus = `<span style="color: red;">Нельзя посчитать</span>`
                    placeStatus = `<span style="color: red;">Неправильный путь</span>`
                }
        
                if (array.length > 1 && i !== 0) {
                    let prevEntranceObjectTime = array[i - 1].entranceTime.split('.')
                    let prevEntranceObjectFind = places.find(f => f.name === array[i - 1].entrancePlace)
        
                    if (exitPlace === "вокзал") {
                        if (parseInt(pathsExitTime[0], 10) > parseInt(prevEntranceObjectTime[0], 10)) {
                            if ((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) > 0 && (60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) <= 6) {
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        } else {
                            if ((parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10)) > 0 && (parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10) <= 6)) {
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        }
                    } else if (exitPlace !== "вокзал" && exitPlace !== "офис" && exitPlace !== "больница") {
                        if (parseInt(pathsExitTime[0], 10) > parseInt(prevEntranceObjectTime[0], 10)) {
                            if ((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) === prevEntranceObjectFind.minutes) {
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        } else {
                            if (parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10) === prevEntranceObjectFind.minutes) {
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        }
                    } else if (exitPlace === "офис") {
                        if (parseInt(pathsExitTime[0], 10) > parseInt(prevEntranceObjectTime[0], 10)) {
                            if ((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) >= exitObject.minutes) {
                                console.log((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)))
                                if (placeStatus === "Правильный путь") {
                                    assessment += (2 * ((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) - exitObject.minutes))
                                }
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        } else {
                            if ((parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10)) >= exitObject.minutes) {
                                console.log((parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10)))
                                if (placeStatus === "Правильный путь") {
                                    assessment += (2 * (parseInt(pathsEntranceTime[1], 10) - parseInt(pathsExitTime[1], 10) - exitObject.minutes))
                                }
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        }
                    } else if (exitPlace === "больница") {
                        if (parseInt(pathsExitTime[0], 10) > parseInt(prevEntranceObjectTime[0], 10)) {
                            if ((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) >= exitObject.minutes) {
                                if (placeStatus === "Правильный путь") {
                                    assessment += (3 * ((60 - parseInt(prevEntranceObjectTime[1], 10) + parseInt(pathsExitTime[1], 10)) - exitObject.minutes))
                                }
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        } else {
                            if ((parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10)) >= exitObject.minutes) {
                                if (placeStatus === "Правильный путь") {
                                    assessment += (3 * (parseInt(pathsExitTime[1], 10) - parseInt(prevEntranceObjectTime[1], 10) - exitObject.minutes))
                                }
                                exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                            } else {
                                exitTimeStatus = `<span style="color: red;">Время выхода установлено неверно</span>`
                            }
                        }
                    }
                } else {
                    exitTimeStatus = `<span style="color: green;">Время выхода установлено верно</span>`
                }
    
                let assessmentObject = assessments.find(f => f.placeName === entrancePlace)
                if (typeof assessmentObject !== 'undefined') {
                    assessment += assessmentObject.mark
                }
        
                if (placeStatus === "Правильный путь") {
                    if (entrancePlace === "дом") {
                        if (i === array.length - 1) {
                            if ((parseInt(pathsEntranceTime[0], 10) === 18 && parseInt(pathsEntranceTime[1], 10) <= 30) || ((parseInt(pathsEntranceTime[0], 10) === 17))) {
                                assessment += 20
                                timingStatus = `<span style="color: green;">Домой успел</span>`
                            } else {
                                timingStatus = `<span style="color: red;">Домой не успел</span>`
                            }
                        } else {
                            if ((parseInt(pathsEntranceTime[0], 10) === 18 && parseInt(pathsEntranceTime[1], 10) <= 30) || ((parseInt(pathsEntranceTime[0], 10) === 17))) {
                                let nextObject = array[i + 1]

                                let nextObjectExitTime = nextObject.exitTime.split('.')

                                if (parseInt(nextObjectExitTime[1], 10) === 18 && parseInt(nextObjectExitTime[1], 10) > 30) {
                                    assessment += 20
                                    timingStatus = `<span style="color: green;">Домой успел</span>`
                                } else {
                                    timingStatus = `<span style="color: red;">Домой не успел</span>`
                                }
                            } else {
                                timingStatus = `<span style="color: red;">Домой не успел</span>`
                            }
                        }
                    } else if (entrancePlace === "дельта") {
                        if (parseInt(pathsEntranceTime[0], 10) === 17 && parseInt(pathsEntranceTime[1], 10) < 30 ) {
                            assessment += 20
                            timingStatus = `<span style="color: green;">Успел в Дельта</span>`
                        } else {
                            timingStatus = `<span style="color: red;">Не успел в Дельта</span>`
                        }
                    } else if (entrancePlace === "вокзал") {
                        if (((parseInt(pathsEntranceTime[0], 10) === 17 && parseInt(pathsEntranceTime[1], 10) >= 57) || (parseInt(pathsEntranceTime[0], 10) === 18 && parseInt(pathsEntranceTime[1], 10) <= 3))) {
                            assessment += 20
                            timingStatus = `<span style="color: green;">Успел на вокзал</span>`
                        } else {
                            timingStatus = `<span style="color: red;">Не успел на вокзал</span>`
                        }
                    } else {
                        timingStatus = "<span>Событий нет</span>"
                    }
                }
    
                finalAssessment += assessment

                newString = `<tr><td style="border: 2px solid black; padding: 4px; text-align: center;">${exitPlace}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${exitTime}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${entrancePlace}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${entranceTime}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${placeStatus}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${exitTimeStatus}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${entranceTimeStatus}</td><td  style="border: 2px solid black; padding: 4px; text-align: center;">${timingStatus}</td><td style="border: 2px solid black; padding: 4px; text-align: center;">${assessment}</td></tr>`
    
                resultString += newString
            }

            resultString += `</tbody></table><br/><br/><span style="font-weight: bold;">Итоговая оценка: ${finalAssessment}</span>`

            state.resultString = resultString
        },
    }
})

export const { addToArrays, deleteFromArrays, deleteFromResultString, calculateAssessment, addFinalAssessment } = PathsReducer.actions

export default PathsReducer.reducer