import React from 'react'

const Finish = () => {
    return (
        <div className="width-full text-justify-center">
            <p className="width-quarter margin-inline-auto padding-all border-main border-radius background-color-white">Результат успешно отправлен</p>
        </div>
    )
}

export default Finish
