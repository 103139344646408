export const formData = [
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'bottomInput',
        text: 'Номер мобильного телефона',
        placeholder: '',
    },
    {
        id: 2,
        type: 'bottomInput',
        text: 'Должность, структурное подразделение',
        placeholder: '',
    },
    {
        id: 3,
        type: 'bottomInput',
        text: 'ФИО, должность руководителя',
        placeholder: '',
    },
    {
        id: 4,
        type: 'textarea',
        text: 'Какой из периодов профессиональной деятельности  внес наибольший вклад в Ваше развитие. Почему?',
        placeholder: '',
    },
    {
        id: 5,
        type: 'textarea',
        text: 'Опишите зоны профессиональной деятельности, в которых Вы обладаете наибольшим практическим опытом и специальными знаниями',
        placeholder: '',
    },
    {
        id: 6,
        type: 'textarea',
        text: 'Укажите пожалуйста три главных достижения в своей профессиональной деятельности',
        placeholder: '',
    },
    {
        id: 7,
        type: 'textarea',
        text: 'Чем привлекает занимаемая должность и работа в данной организации?',
        placeholder: '',
    },
    {
        id: 8,
        type: 'textarea',
        text: 'Что, по Вашему мнению, побуждает людей работать наиболее эффективно?',
        placeholder: '',
    },
    {
        id: 9,
        type: 'textarea',
        text: 'Какими главными качествами должен обладать исполнитель для успешного выполнения своих обязанностей?',
        placeholder: '',
    },
    {
        id: 10,
        type: 'textarea',
        text: 'Какими главными качествами должен обладать руководитель подразделения для  успешного управления своим коллективом?',
        placeholder: '',
    },
    {
        id: 11,
        type: 'textarea',
        text: 'Назовите главные недостатки руководителя, которые больше всего могут мешать эффективной работе подразделения?',
        placeholder: '',
    },
    {
        id: 12,
        type: 'textarea',
        text: 'Являетесь ли Вы руководителем коллектива? Если да, то укажите общее количество подчиненных и количество подчиненных, которым Вы непосредственно ставите задачи',
        placeholder: '',
    },
    {
        id: 13,
        type: 'textarea',
        text: 'Ориентированы ли Вы на дальнейший карьерный рост? Если да, какая должность в данной организации для Вас является привлекательной?',
        placeholder: '',
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'При каких условиях Вы готовы возглавить решение задачи (отметьте 2 варианта)',
        variants: [
            {
                id: 0,
                text: 'Вы являетесь начальником в данной группе',
            },
            {
                id: 1,
                text: 'Вы точно знаете, как решать данную задачу',
            },
            {
                id: 2,
                text: 'кроме Вас больше некому взять на себя ответственность',
            },
            {
                id: 3,
                text: 'если есть более опытные коллеги, не буду мешать им руководить',
            },
            {
                id: 4,
                text: 'это дело руководителя назначать ответственных',
            },
        ],
        max: 2,
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'В каком случае Вы  готовы (или не готовы) отстаивать свою точку зрения, которую считаете правильной, перед вышестоящим руководством? (отметьте один вариант)',
        variants: [
            {
                id: 0,
                text: 'не буду спорить, так как начальник отвечает за все',
            },
            {
                id: 1,
                text: 'выскажу свое мнение, но спорить не буду',
            },
            {
                id: 2,
                text: 'буду отстаивать, если лично отвечаю за конечный результат',
            },
            {
                id: 3,
                text: 'буду отстаивать, если не отвечаю за конечный результат, но от моего результата в значительной степени зависит успех работы',
            },
            {
                id: 4,
                text: 'буду отстаивать, если пострадает репутация организации',
            },
        ],
        max: 1,
    },
    {
        id: 16,
        type: 'textarea',
        text: 'Ваши профессиональные планы на ближайший год (3 года)?',
        placeholder: '',
    },
    {
        id: 17,
        type: 'textarea',
        text: 'Внесите предложения для повышения рейтинга организации в которой вы работаете? (учитывая реальные условия)',
        placeholder: '',
    },
    {
        id: 18,
        type: 'textarea',
        text: 'Увлечения, хобби',
        placeholder: '',
    },
]

export const sendInfo = {
    serviceId: 'service_xipv1kq',
    templateId: 'template_ynht9gf',
    publicKey: 'Oa3hGTSUoOdxk6wwd',
}