export const formData = [
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'bottomInput',
        text: 'Дата рождения',
        placeholder: '',
    },
    {
        id: 2,
        type: 'bottomInput',
        text: 'Место рождения',
        placeholder: '',
    },
    {
        id: 3,
        type: 'bottomInput',
        text: 'Семейное положение',
        placeholder: '',
    },
    {
        id: 4,
        type: 'bottomInput',
        text: 'Дети',
        placeholder: '(количество, возраст)',
    },
    {
        id: 5,
        type: 'bottomInput',
        text: 'Адрес регистрации',
        placeholder: '',
    },
    {
        id: 6,
        type: 'bottomInput',
        text: 'Адрес проживания',
        placeholder: '',
    },
    {
        id: 7,
        type: 'bottomInput',
        text: 'Контактный телефон',
        placeholder: '',
    },
    {
        id: 8,
        type: 'bottomInput',
        text: 'Должностная позиция, на которую претендуете',
        placeholder: '',
    },
    {
        id: 9,
        type: 'bottomInput',
        text: 'Минимальный уровень заработной платы',
        placeholder: '',
    },
    {
        id: 10,
        type: 'select',
        text: 'Откуда узнали о конкурсе?',
        options: [
            {
                id: 0,
                text: 'Выберите вариант',
            },
            {
                id: 1,
                text: 'рекомендации руководства Управления культуры г. Казани',
            },
            {
                id: 2,
                text: 'рекомендации руководства учреждений культуры',
            },
            {
                id: 3,
                text: 'увидел объявление',
            },
            {
                id: 4,
                text: 'другое',
            },
        ],
    },
    {
        id: 11,
        type: 'select',
        text: 'Наличие военного билета:',
        options: [
            {
                id: 0,
                text: 'Выберите вариант',
            },
            {
                id: 1,
                text: 'есть',
            },
            {
                id: 2,
                text: 'являюсь призывником',
            },
            {
                id: 3,
                text: 'вхожу в категорию лиц, не подлежащих призыву',
            },
        ],
    },
    {
        id: 12,
        type: 'table',
        text: 'Образование (учебные заведения, курсы)',
        columns: [
            {
                id: 0,
                text: 'Год поступления и окончания',
            },
            {
                id: 1,
                text: 'Учебное заведение',
            },
            {
                id: 2,
                text: 'Факультет, специальность',
            },
            {
                id: 3,
                text: 'Уровень образования',
            },
        ],
    },
    {
        id: 13,
        type: 'table',
        text: 'Опыт работы',
        columns: [
            {
                id: 0,
                text: 'Период работы (м.г/м.г)',
            },
            {
                id: 1,
                text: 'Должность, место работы',
            },
            {
                id: 2,
                text: 'Причина поиска (смены) работы',
            },
        ],
    },
    {
        id: 14,
        type: 'textarea',
        text: 'Перечислите подробно все должностные обязанности, выполняемые Вами',
        placeholder: '',
    },
    {
        id: 15,
        type: 'table',
        text: 'Дополнительная информация',
        columns: [
            {
                id: 0,
                text: 'Степень владения ПК (программы)',
            },
            {
                id: 1,
                text: 'Степень владения иностранными языками (указать какими)',
            },
            {
                id: 2,
                text: 'Наличие водительского удостоверения и автомобиля',
            },
            {
                id: 3,
                text: 'Судимость (есть, нет), статья',
            },
        ],
    },
    {
        id: 16,
        type: 'table',
        text: 'Близкие родственники (жена/муж, отец, мать, братья, сестры)',
        columns: [
            {
                id: 0,
                text: 'Степень родства',
            },
            {
                id: 1,
                text: 'Ф.И.О.',
            },
            {
                id: 2,
                text: 'Дата рождения',
            },
            {
                id: 3,
                text: 'Место работы, должность',
            },
            {
                id: 4,
                text: 'Место жительства',
            },
        ],
    },
    {
        id: 17,
        type: 'textarea',
        text: 'Перечислите три Ваших главных личных результата, достижением которых Вы гордитесь.',
        placeholder: '',
    },

    {
        id: 18,
        type: 'textarea',
        text: 'В подготовке каких проектов Вы принимали участие? Опишите Ваш личный вклад в их реализацию.',
        placeholder: '',
    },

    {
        id: 19,
        type: 'textarea',
        text: 'Опишите зоны профессиональной деятельности, в которых Вы обладаете наибольшим практическим опытом и специальными знаниями.',
        placeholder: '',
    },

    {
        id: 20,
        type: 'textarea',
        text: 'Ваши преимущества.',
        placeholder: '(деловые, личностные качества)',
    },

    {
        id: 21,
        type: 'textarea',
        text: 'Что, по Вашему мнению, стимулирует людей работать наиболее эффективно?',
        placeholder: '',
    },

    {
        id: 22,
        type: 'textarea',
        text: 'Чем привлекает должность руководителя образовательного учреждения?',
        placeholder: '',
    },
    {
        id: 23,
        type: 'checkbox',
        text: 'Во время проведения переговоров, какими Вы предпочитаете пользоваться стратегиями (отметить)',
        variants: [
            {
                id: 0,
                text: 'противоборство, отстаивание своих интересов',
            },
            {
                id: 1,
                text: 'компромисс',
            },
            {
                id: 2,
                text: 'сотрудничество',
            },
            {
                id: 3,
                text: 'уступка',
            },
            {
                id: 4,
                text: 'избегание',
            },
        ],
        max: 0,
    },
    {
        id: 24,
        type: 'checkbox',
        text: 'При каких условиях Вы готовы возглавить решение задачи (отметьте 2 варианта)',
        variants: [
            {
                id: 0,
                text: 'Вы являетесь начальником в данной группе',
            },
            {
                id: 1,
                text: 'Вы точно знаете, как решать данную задачу',
            },
            {
                id: 2,
                text: 'кроме Вас больше некому взять на себя ответственность',
            },
            {
                id: 3,
                text: 'если есть более опытные коллеги, не буду мешать им руководить',
            },
            {
                id: 4,
                text: 'это дело руководителя назначать ответственных за задачу',
            },
        ],
        max: 2,
    },
    {
        id: 25,
        type: 'checkbox',
        text: 'В каком случае Вы готовы (или не готовы) отстаивать свою точку зрения, которую считаете правильной, перед вышестоящим руководством? Отметьте один вариант',
        variants: [
            {
                id: 0,
                text: 'не буду спорить, так как начальник отвечает за все',
            },
            {
                id: 1,
                text: 'выскажу свое мнение, но спорить не буду',
            },
            {
                id: 2,
                text: 'буду отстаивать, если лично отвечаю за конечный результат',
            },
            {
                id: 3,
                text: 'буду отстаивать, если не отвечаю за конечный результат, но от моего результата в значительной степени зависит успех работы',
            },
            {
                id: 4,
                text: 'буду отстаивать, чтобы не пострадала репутация организации',
            },
        ],
        max: 1,
    },
    {
        id: 26,
        type: 'textarea',
        text: 'Ваши профессиональные планы на ближайший год?',
        placeholder: '',
    },
    {
        id: 27,
        type: 'textarea',
        text: 'Увлечения, хобби',
        placeholder: '',
    },
]

export const sendInfo = {
    serviceId: 'service_egfqjqe',
    templateId: 'template_o5w3jma',
    publicKey: 'mItIAVRlV2ngkZHPp',
}