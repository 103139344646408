export const formData = [
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'textarea',
        text: 'Как давно Вы работаете руководителем данного отдела?',
        placeholder: '',
    },
    {
        id: 2,
        type: 'textarea',
        text: 'Сколько человек у Вас в подчинении?',
        placeholder: '',
    },
    {
        id: 3,
        type: 'textarea',
        text: 'Как Вы думаете, какие качества необходимы хорошему руководителю?',
        placeholder: '',
    },
    {
        id: 4,
        type: 'textarea',
        text: 'Как бы Вы описали себя как руководителя?',
        placeholder: '',
    },
    {
        id: 5,
        type: 'textarea',
        text: 'В какой сфере деятельности Вы добились наибольшего успеха?',
        placeholder: '',
    },
    {
        id: 6,
        type: 'textarea',
        text: 'Считаете ли Вы себя лучшим кандидатом на должность руководителя Вашего отдела?',
        placeholder: '(Если да, то почему? Если нет, то кого Вы считаете лучшим кандидатом?)',
    },
    {
        id: 7,
        type: 'textarea',
        text: 'Сколько часов в неделю Вы посвящаете своей работе?',
        placeholder: '',
    },
    {
        id: 8,
        type: 'textarea',
        text: 'Как бы Вы описали свой стиль управления или лидерства?',
        placeholder: '',
    },
    {
        id: 9,
        type: 'textarea',
        text: 'Каковы Ваши сильные стороны как руководителя?',
        placeholder: '',
    },
    {
        id: 10,
        type: 'textarea',
        text: 'Какие факторы Вы учитываете, оценивая работу Ваших сотрудников?',
        placeholder: '',
    },
    {
        id: 11,
        type: 'textarea',
        text: 'Каковы три главных фактора улучшения командной работы и успеха?',
        placeholder: '',
    },
    {
        id: 12,
        type: 'textarea',
        text: 'Вы когда-нибудь увольняли сотрудника? По какой причине?',
        placeholder: '',
    },
    {
        id: 13,
        type: 'textarea',
        text: 'Как Вы встречаете и адаптируете новых сотрудников в своем отделе?',
        placeholder: '',
    },
    {
        id: 14,
        type: 'textarea',
        text: 'По какому принципу Вы распределяете работу в своем отделе?',
        placeholder: '',
    },
    {
        id: 15,
        type: 'textarea',
        text: 'Выстраиваете ли Вы личные отношения с подчиненными вне работы?',
        placeholder: '',
    },
    {
        id: 16,
        type: 'textarea',
        text: 'По каким критериям Вы измеряете результаты Вашего отдела?',
        placeholder: '',
    },
    {
        id: 17,
        type: 'table',
        text: 'Укажите, пожалуйста, результаты Вашего отдела за последние 3 года:',
        columns: [
            {
                id: 0,
                text: 'Критерий',
            },
            {
                id: 1,
                text: '2023г',
            },
            {
                id: 2,
                text: '2022г',
            },
            {
                id: 3,
                text: '2021г',
            },
        ],
    },
    {
        id: 18,
        type: 'textarea',
        text: 'Укажите, пожалуйста, план работы для Вашего отдела на год?',
        placeholder: '',
    },
    {
        id: 19,
        type: 'textarea',
        text: 'Какие ресурсы Вам потребуются?',
        placeholder: '',
    },
    {
        id: 20,
        type: 'textarea',
        text: 'Где и как Вы их планируете получить?',
        placeholder: '',
    },
    {
        id: 21,
        type: 'checkboxAgreement',
        agreements: [
            {
                id: 0,
                text: 'Я подтверждаю достоверность всего изложенного выше и не возражаю против проверки.',
            },
            {
                id: 1,
                text: 'Я согласен (-на) пройти оценку компетенций и не претендую на получение материалов и результатов оценки.',
            },
        ],
        max: 0,
    },
]

export const sendInfo = {
    serviceId: 'service_iygqvkf',
    templateId: 'template_xwwjzcf',
    publicKey: 'zaDWd6wxZPFnyEdLP',
}