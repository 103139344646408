import React from 'react'

import RouteTable from './RouteTable'
import RouteAfterTable from './RouteAfterTable'

import { orgrukpaths } from '../../../images/orgrukpaths.js'

const RouteList = () => {
    return (

        <div className="width-full display-flex flex-direction-column">
            <div className="routeComponent margin-top-huge padding-all display-flex flex-direction-row margin-inline-auto justify-content-between">
                <div className="width-half">
                    {orgrukpaths}
                </div>

                <RouteTable />
            </div>


            <RouteAfterTable />
        </div>
    )
}

export default RouteList