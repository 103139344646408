import React from 'react'
import MyCheckbox from './MyCheckbox'

const CheckboxAgreement = ({ input, onChange }) => {
    const { id, agreements, max, value } = input
    
    return (
        <div className="checkbox-question__list">
            {
                agreements.map((agreement) => (
                    <MyCheckbox key={agreement.id} questionId={id} variantId={agreement.id} value={value} onChange={onChange} text={agreement.text} max={max} />
                ))
            }
        </div>
    )
}

export default CheckboxAgreement