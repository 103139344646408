import React, { useRef, useEffect } from 'react'

const MyTextarea = ({ input, onChange }) => {
    const { id, text, placeholder, value } = input

    const textareaRef = useRef(null)

    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = '0px'
            const scrollHeight = textareaRef.current.scrollHeight
            textareaRef.current.style.height = scrollHeight + 'px'
        }
    }, [value])

    return (
        <label className="my-textarea__label">
            <span className="_question-text">{id + 1}. {text}</span>

            <textarea ref={textareaRef} className="my-textarea" value={value} onChange={(e) => onChange(id, e)} placeholder={placeholder} />
        </label>
    )
}

export default React.memo(MyTextarea)