import React from 'react'
import UserFullCardQuestion from './UserFullCardQuestion'

const UserFullCardList = ({ currentCard }) => {
    return (
        <ul className='userFullCardList'>
            <li className='userFullCardItem'>
                <strong>Участник тестирования:</strong> {currentCard?.userInfo?.userId}
            </li>
            <li className='userFullCardItem'>
                <strong>ФИО:</strong> {currentCard?.userInfo?.userName}
            </li>

            {
                currentCard?.answerInfo?.answerList.map(question => (
                    <UserFullCardQuestion key={question?.questionId} question={question} />
                ))
            }
            
        </ul>
    )
}

export default UserFullCardList