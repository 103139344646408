import { React, useState } from 'react'
import { cards } from '../features/counter/data'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'

const Slider = () => {
    const [ activeSlide, setActiveSlide ] = useState(1)

    const prevSliderHandler = (id) => {
        if (id === 1) {
            setActiveSlide(cards.length)
        } else if (id > 1) {
            setActiveSlide(activeSlide - 1)
        } else {
            setActiveSlide(cards.length - 1)
        }
    }

    const nextSliderHandler = (id) => {
        if (id === cards.length) {
            setActiveSlide(1)
        } else if (id < cards.length) {
            setActiveSlide(activeSlide + 1)
        } else {
            setActiveSlide(cards.length - 1)
        }
    }

    return (
        <div className="width-full margin-inline-auto padding-all align-center justify-content-center display-flex flex-direction-column text-justify-full">
            {cards.map((card) => (
                
                <div key={card.id} className={activeSlide === card.id ? 'display-flex flex-direction-column align-center border-main border-radius width-two-thirds background-color-white' : 'element-hidden'}>
                    
                    <div className="display-flex flex-direction-column padding-all-large">

                        <div className="">
                            <h1 className="font-weight-bold">Сообщение {card.id}.</h1>

                            <div className="position-relative margin-top-large margin-bottom-large">
                                <p className={`position-absolute position-top-0 ${card.id === 11 ? 'position-left-0' : 'position-right-0'}`}>{card.data}</p>
                                <p className={`position-absolute position-top-5 ${card.id === 11 ? 'position-left-0' : 'position-right-0'}`}>{card.data2}</p>
                            </div>
                        </div>

                        <p className={`margin-top-huge margin-bottom-large ${card.id === 5 || card.id === 6 || card.id === 10 || card.id === 11 || card.id === 12 || card.id === 13 || card.id === 14 ? 'margin-inline-auto' : ''}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.to}</p>

                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.firstParagraph}</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.secondParagraph}</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.thirdParagraph}</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.fourthParagraph}</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.fifthParagraph}</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.sixthParagraph}</p>

                        <div className="position-relative display-flex flex-direction-column">
                            <p className="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.afterText}</p>
                            <p className="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{card.afterText2}</p>
                        </div>

                        <div className="position-relative display-flex flex-direction-column">
                            <p className="position-absolute position-bottom-5 position-right-0">{card.author}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            <p className="position-absolute position-bottom-0 position-right-0">{card.author2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        </div>
                    </div>

                </div>
            ))}

            <div className="display-flex flex-direction-row margin-top-large">
                <button className="hugeText arrowButton margin-right" onClick={() => prevSliderHandler(activeSlide)}>
                    <FiArrowLeft />
                </button>

                <button className="hugeText arrowButton" onClick={() => nextSliderHandler(activeSlide)}>
                    <FiArrowRight />
                </button>
            </div>
        </div>
    )
}

export default Slider