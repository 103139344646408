import { createSlice } from '@reduxjs/toolkit'

export const TaskThreeReducer = createSlice({
    name: 'taskThree',
    initialState: {
        firstArray: [],
        firstArrayResultString: `<table style="border-collapse: collapse;"><tbody><tr><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время начала</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время окончания</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Задача</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Решение</td></tr>`,
        secondArray: [],
        secondArrayResultString: `<table style="border-collapse: collapse;"><tbody><tr><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время начала</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время окончания</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Задача</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Решение</td></tr>`,
        thirdArray: [],
        thirdArrayResultString: `<table style="border-collapse: collapse;"><tbody><tr><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время начала</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время окончания</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Задача</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Решение</td></tr>`,
        fourthArray: [],
        fourthArrayResultString: `<table style="border-collapse: collapse;"><tbody><tr><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время начала</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время окончания</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Задача</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Решение</td></tr>`,
        fifthArray: [],
        fifthArrayResultString: `<table style="border-collapse: collapse;"><tbody><tr><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время начала</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Время окончания</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Задача</td><td style="font-weight: bold; text-align: center; padding: 4px; border: 2px solid black;">Решение</td></tr>`,
    },
    reducers: {
        addToFirstArray: (state, action) => {
            state.firstArray.push(action.payload)
        },
        addToFirstArrayResultString: (state, action) => {
            let object = action.payload

            state.firstArrayResultString += `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`
        },
        deleteFromFirstArray: (state, action) => {
            let find = state.firstArray.find(f => f.dealId === action.payload)

            state.firstArray.splice(state.firstArray.indexOf(find), 1)
        },
        editFirstArray: (state, action) => {
            let edit = state.firstArray.find(f => f.dealId === action.payload.dealId)

            state.firstArray[state.firstArray.indexOf(edit)] = action.payload
        },
        editFirstArrayResultString: (state, action) => {
            const object = action.payload

            const regexObject = state.firstArray.find(f => f.dealId === object.dealId)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            const newString = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`

            state.firstArrayResultString = state.firstArrayResultString.replace(regex, newString)

        },
        deleteFromFirstArrayResultString: (state, action) => {
            const regexObject = state.firstArray.find(f => f.dealId === action.payload)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            state.firstArrayResultString = state.firstArrayResultString.replace(regex, "")
        },
        addToSecondArray: (state, action) => {
            state.secondArray.push(action.payload)
        },
        addToSecondArrayResultString: (state, action) => {
            let object = action.payload

            state.secondArrayResultString += `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`
        },
        deleteFromSecondArray: (state, action) => {
            let find = state.secondArray.find(f => f.dealId === action.payload)

            state.secondArray.splice(state.secondArray.indexOf(find), 1)
        },
        editSecondArray: (state, action) => {
            let edit = state.secondArray.find(f => f.dealId === action.payload.dealId)

            state.secondArray[state.secondArray.indexOf(edit)] = action.payload
        },
        editSecondArrayResultString: (state, action) => {
            const object = action.payload

            const regexObject = state.secondArray.find(f => f.dealId === object.dealId)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            const newString = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`

            state.secondArrayResultString = state.secondArrayResultString.replace(regex, newString)

        },
        deleteFromSecondArrayResultString: (state, action) => {
            const regexObject = state.secondArray.find(f => f.dealId === action.payload)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            state.secondArrayResultString = state.secondArrayResultString.replace(regex, "")
        },
        addToThirdArray: (state, action) => {
            state.thirdArray.push(action.payload)
        },
        addToThirdArrayResultString: (state, action) => {
            let object = action.payload

            state.thirdArrayResultString += `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`
        },
        deleteFromThirdArray: (state, action) => {
            let find = state.thirdArray.find(f => f.dealId === action.payload)

            state.thirdArray.splice(state.thirdArray.indexOf(find), 1)
        },
        editThirdArray: (state, action) => {
            let edit = state.thirdArray.find(f => f.dealId === action.payload.dealId)

            state.thirdArray[state.thirdArray.indexOf(edit)] = action.payload
        },
        editThirdArrayResultString: (state, action) => {
            const object = action.payload

            const regexObject = state.thirdArray.find(f => f.dealId === object.dealId)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`
            

            const newString = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`

            state.thirdArrayResultString = state.thirdArrayResultString.replace(regex, newString)

        },
        deleteFromThirdArrayResultString: (state, action) => {
            const regexObject = state.thirdArray.find(f => f.dealId === action.payload)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            state.thirdArrayResultString = state.thirdArrayResultString.replace(regex, "")
        },
        addToFourthArray: (state, action) => {
            state.fourthArray.push(action.payload)
        },
        addToFourthArrayResultString: (state, action) => {
            let object = action.payload

            state.fourthArrayResultString += `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`
        },
        deleteFromFourthArray: (state, action) => {
            let find = state.fourthArray.find(f => f.dealId === action.payload)

            state.fourthArray.splice(state.fourthArray.indexOf(find), 1)
        },
        editFourthArray: (state, action) => {
            let edit = state.fourthArray.find(f => f.dealId === action.payload.dealId)

            state.fourthArray[state.fourthArray.indexOf(edit)] = action.payload
        },
        editFourthArrayResultString: (state, action) => {
            const object = action.payload

            const regexObject = state.fourthArray.find(f => f.dealId === object.dealId)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            const newString = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`

            state.fourthArrayResultString = state.fourthArrayResultString.replace(regex, newString)

        },
        deleteFromFourthArrayResultString: (state, action) => {
            const regexObject = state.fourthArray.find(f => f.dealId === action.payload)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            state.fourthArrayResultString = state.fourthArrayResultString.replace(regex, "")
        },
        addToFifthArray: (state, action) => {
            state.fifthArray.push(action.payload)
        },
        addToFifthArrayResultString: (state, action) => {
            let object = action.payload

            state.fifthArrayResultString += `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`
        },
        deleteFromFifthArray: (state, action) => {
            let find = state.fifthArray.find(f => f.dealId === action.payload)

            state.fifthArray.splice(state.fifthArray.indexOf(find), 1)
        },
        editFifthArray: (state, action) => {
            let edit = state.fifthArray.find(f => f.dealId === action.payload.dealId)

            state.fifthArray[state.fifthArray.indexOf(edit)] = action.payload
        },
        editFifthArrayResultString: (state, action) => {
            const object = action.payload

            const regexObject = state.fifthArray.find(f => f.dealId === object.dealId)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            const newString = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${object.decision}</td></tr>`

            state.fifthArrayResultString = state.fifthArrayResultString.replace(regex, newString)

        },
        deleteFromFifthArrayResultString: (state, action) => {
            const regexObject = state.fifthArray.find(f => f.dealId === action.payload)

            const regex = `<tr><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.from}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.to}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.deal}</td><td style="text-align: center; padding: 4px; border: 2px solid black;">${regexObject.decision}</td></tr>`

            state.fifthArrayResultString = state.fifthArrayResultString.replace(regex, "")
        },
        addFinalTags: (state) => {
            state.firstArrayResultString += '</tbody></table><br/><br/>'
            state.secondArrayResultString += '</tbody></table><br/><br/>'
            state.thirdArrayResultString += '</tbody></table><br/><br/>'
            state.fourthArrayResultString += '</tbody></table><br/><br/>'
            state.fifthArrayResultString += '</tbody></table><br/><br/>'
        },
    },
})

export const { addToFirstArray, addToFirstArrayResultString, deleteFromFirstArray, deleteFromFirstArrayResultString, editFirstArray, editFirstArrayResultString, addToSecondArray, addToSecondArrayResultString, deleteFromSecondArray, deleteFromSecondArrayResultString, editSecondArray, editSecondArrayResultString, addToThirdArray, addToThirdArrayResultString, deleteFromThirdArray, deleteFromThirdArrayResultString, editThirdArray, editThirdArrayResultString, addToFourthArray, addToFourthArrayResultString, deleteFromFourthArray, deleteFromFourthArrayResultString, editFourthArray, editFourthArrayResultString, addToFifthArray, addToFifthArrayResultString, deleteFromFifthArray, deleteFromFifthArrayResultString, editFifthArray, editFifthArrayResultString, addFinalTags } = TaskThreeReducer.actions

export default TaskThreeReducer.reducer