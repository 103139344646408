import React from 'react'

import MyRow from './MyRow'

const MyTableBody = ({ value }) => {
    return (
        <tbody>
            {
                value.map((columns) => (
                    <MyRow key={value.indexOf(columns)} columns={columns} />
                ))
            }
        </tbody>
    )
}

export default React.memo(MyTableBody)