import React from 'react'

const MyPopup = ({ isOpen, children }) => {

    return (
        <article className={`${isOpen ? '' : 'element-hidden'} my-popup`}>
            {children}
        </article>
    )
}

export default React.memo(MyPopup)