import React, { useEffect } from 'react'
import { useTimer } from './hooks/useTimer'

const Timer = ({ input, inputs, analysis, sendInfo }) => {
    const { hours, minutes, seconds, tick } = useTimer(input, inputs, analysis, sendInfo)
    
    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    })

    return (
        <p className="timer">{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</p>
    )
}

export default Timer