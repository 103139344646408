import {createSlice} from '@reduxjs/toolkit'

export const PlannerReducer = createSlice({
    name: "planner",
    initialState: {
        firstArray: [],
        secondArray: [],
        result: "",
    },
    reducers: {
        addToFirstArray: (state, action) => {
            state.firstArray.push(action.payload)
        },
        deleteFromFirstArray: (state, action) => {
            let find = state.firstArray.find(f => f.id === action.payload)

            state.firstArray.splice(state.firstArray.indexOf(find), 1)
        },
        editFirstArray: (state, action) => {
            let edit = state.firstArray.find(f => f.id === action.payload.id)

            state.firstArray[state.firstArray.indexOf(edit)] = action.payload

        },
        addToSecondArray: (state, action) => {
            state.secondArray.push(action.payload)
        },
        deleteFromSecondArray: (state, action) => {
            let find = state.secondArray.find(f => f.id === action.payload)

            state.secondArray.splice(state.secondArray.indexOf(find), 1)
        },
        editSecondArray: (state, action) => {
            let edit = state.secondArray.find(f => f.id === action.payload.id)

            state.secondArray[state.secondArray.indexOf(edit)] = action.payload
        },
        plannerAnalysis: (state) => {
            let result = `<span style="font-weight: bold;">28 апреля, четверг</span><br/><table style="border-collapse: collapse;"><tbody><tr><th style="text-align: center; border: 2px solid black; padding: 4px;">Время начала</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Время окончания</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Примечания</th></tr>`

            for (let i = 0; i < state.firstArray.length; i++) {
                let object = state.firstArray[i]

                result += `<tr><td style="text-align: center; border: 2px solid black; padding: 4px;">${object.start}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${object.end}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${object.note}</td></tr>`
            }

            result += `</tbody></table><br/><span style="font-weight: bold;">29 апреля, пятница</span><br/><table style="border-collapse: collapse;"><tbody><tr><th style="text-align: center; border: 2px solid black; padding: 4px;">Время начала</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Время окончания</th><th style="text-align: center; border: 2px solid black; padding: 4px;">Примечания</th></tr>`

            for (let i = 0; i < state.secondArray.length; i++) {
                let object = state.secondArray[i]

                result += `<tr><td style="text-align: center; border: 2px solid black; padding: 4px;">${object.start}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${object.end}</td><td style="text-align: center; border: 2px solid black; padding: 4px;">${object.note}</td></tr>`
            }

            result += "</tbody></table><br/>"

            state.result = result
        },
    }
})

export const {addToFirstArray, deleteFromFirstArray, editFirstArray, addToSecondArray, deleteFromSecondArray, editSecondArray, plannerAnalysis} = PlannerReducer.actions

export default PlannerReducer.reducer