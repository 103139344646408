import React from 'react'

const MotivationInputs = ({ inputs, onChange }) => {
  return (
    <ol className='motivation-inputs__list'>
        {
            inputs.map(input => (
                <li key={input.id}>
                    {input.id + 1}
                    <input className='motivation-inputs__list-item__input' type='number' min="0" max="11" value={input.value} onChange={(evt) => onChange(evt, input.id)} />
                </li>
            ))
        }
    </ol>
  )
}

export default React.memo(MotivationInputs)