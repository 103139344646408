const createBottomInputObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        text: input.text,
        placeholder: input.placeholder,
        value: data[input.id],
        withoutId: input.withoutId ? true : false,
    }
}

const createCheckboxObject = (input, data) => {
    const object = {
        id: input.id,
        type: input.type,
        text: input.text,
        variants: input.variants,
        max: input.max,
        withInput: input?.withInput,
        value: data[input.id],
    }

    if (input.addedText) {
        object.addedText = input.addedText
    }

    if (input.isCheckable) {
        object.isCheckable = input.isCheckable
        object.answers = input.answers
    }

    if (input.isCalculable) {
        object.isCalculable = input.isCalculable
    }

    return object
}

const createSelectObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        text: input.text,
        options: input.options,
        value: data[input.id],
    }
}

const createSimpleInputObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        placeholder: input.placeholder,
        value: data[input.id],
    }
}

const createTableObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        text: input.text,
        columns: input.columns,
        value: data[input.id],
    }
}

const createTextareaObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        text: input.text,
        placeholder: input.placeholder,
        value: data[input.id],
    }
}

const createCheckboxAgreementObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        agreements: input.agreements,
        value: data[input.id],
        max: input.max,
    }
}

const createTextParagraphObject = (input) => {
    return {
        id: input.id,
        type: input.type,
        header: input.header,
        text: input.text,
    }
}

const createMotivationObject = (input, data) => {
    return {
        id: input.id,
        type: input.type,
        questions: input.questions,
        value: data[input.id],
    }
}

const createMultiCheckbox = (input, data) => {
    const object = {
        id: input.id,
        type: input.type,
        text: input.text,
        variants: input.variants,
        max: input.max,
        withInput: input?.withInput,
        value: data[input.id],
    }

    if (input.addedText) {
        object.addedText = input.addedText
    }

    return object
}

export const functionsObject = {
    'bottomInput': createBottomInputObject,
    'simpleInput': createSimpleInputObject,
    'select': createSelectObject,
    'textarea': createTextareaObject,
    'checkbox': createCheckboxObject,
    'table': createTableObject,
    'checkboxAgreement': createCheckboxAgreementObject,
    'textParagraph': createTextParagraphObject,
    'motivation': createMotivationObject,
    'multiCheckbox': createMultiCheckbox,
}