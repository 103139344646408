const sumInputValues = (value) => {
    let sum = 0

    for (let i = 0; i < value.length; i++) {
        if (Number(value[i] >= 0)) {
            sum += Number(value[i])
        }
    }

    return sum
}

export const inputsAnalysis = (value) => {
    if (sumInputValues(value) !== 11) {
        return false
    }

    return true
}