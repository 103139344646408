import React from 'react'

const ExperienceAnalysisInformation = () => {
  return (
    <div>
        <h2 className="information-heading text-bold">Пояснение к заданию на анализ деятельности</h2>

        <p className="information-heading text-bold text-italic">Обратите внимание! На выполнение задания дается 40 минут!</p>

        <p className="information-paragraph text-bold text-italic">
            Необходимо проанализировать Вашу деятельность (последний опыт работы, который составляет не менее 3-х месяцев). Отнеситесь, пожалуйста, к заданию ответственно и детально его проработайте.
        </p>

        <p className="information-paragraph text-bold text-italic">
            В случае, если Вы ранее не имели опыт работы, то предлагаем проанализировать дипломную работу, ВКР, научную работу и т.д либо прохождение практики в той или иной организации. Структура анализа уже дана, необходимо ее дополнить содержанием.
        </p>

        <div className="information-list-container">
            <h3>Укажите Ваши ФИО</h3>

            <ol className="information-list">
                <li>
                    Укажите основные задачи, которые Вы выполняете(-ли) на своем рабочем месте <span className="text-bold text-italic">(пожалуйста, не пишите задачи в общем виде, пишите конкретно, чем Вы занимаетесь(-лись)).</span>
                </li>

                <li>
                    Укажите те результаты, которые получаете(-ли) при выполнении вышеуказанных задач <span className="text-bold text-italic">(желательно указать качественные и количественные характеристики результата).</span>
                </li>

                <li>
                    Укажите информацию, которую Вы изучаете(-ли) и на которую опираетесь(-лись) при выполнении этих задач и <span className="text-italic">источники</span> ее получения. Укажите, <span className="text-italic">какой информации Вам не хватает(-ло).</span>
                </li>

                <li>
                    Укажите <span className="text-italic">сильные стороны Вашей деятельности</span> – то, что помогает(-ло) Вам в выполнении задач и достижении результатов. 
                </li>

                <li>
                    Укажите <span className="text-italic">слабые стороны направления</span> – то, что мешает(-ло) Вам выполнять Ваши задачи и достигать результата либо чего не хватало для их достижения.

                    <p className="information-paragraph text-bold text-italic">
                        Обратите внимание! Вы анализируете не себя, а свою деятельность. Поэтому необходимо указать не свои личностные характеристики, а ресурсы или проблемы при организации Вашей деятельности.
                    </p> 
                </li>

                <li>
                    Укажите какие <span className="text-italic">причины</span> имеются(-лись), на Ваш взгляд, у тех слабых сторон, которые Вы описали в предыдущем пункте.
                </li>

                <li>
                    Укажите <span className="text-italic">участников ситуации</span>, т.е. тех, кто кроме Вас влияет(-л) на результат Вашей деятельности (Ваше руководство, клиенты, надзорные органы и проч.). Опишите по каким <span className="text-italic">вопросам</span> Вы взаимодействовали с ними и какой результат их интересует(-вал).
                </li>

                <li>
                    Укажите, как, на Ваш взгляд, дальше будет развиваться ситуация в Вашем направлении деятельности, если ничего не менять <span className="text-bold text-italic">(необходимо описать прогноз в виде логической цепочки: «…это приведет к этому, это к тому, в итоге мы будем иметь вот это и т.д).</span> 
                </li>

                <li>
                    Представьте, что Вас привлекли в качестве эксперта и Вам необходимо предложить <span className="text-itelic">план мероприятий по повышению эффективности</span> Вашей деятельности, с учетом того, что Вы написали выше. Опишите <span className="text-italic">какие еще ресурсы</span> Вам понадобятся для реализации Ваших предложений, где Вы предполагаете их брать, какие могут быть риски при реализации данных предложений и как Вы планируете их устранить. 
                </li>
            </ol>
        </div>

        <p className="information-paragraph text-bold text-italic">
            Обратите внимание! Ваши предложения должны логически вытекать из анализа деятельности, который Вы расписали до этого.
        </p>

        <p className="information-paragraph">
            После заполнения анкеты необходимо нажать кнопку <span className="text-bold text-italic">«отправить»</span> внизу страницы, затем нажать кнопку: <span className="text-bold text-italic">«да»</span>. Должна отобразиться надпись: <span className="text-bold text-italic">«Результат успешно отправлен».</span>
        </p>
    </div>
  )
}

export default ExperienceAnalysisInformation