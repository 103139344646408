import React from 'react'

import Navbar from '../components/Navbar'
import Form from '../components/Form/Form'

import { formData, sendInfo } from '../data/managerFormData'
import { formAnalysis } from '../helpers/analysis'

const ManagerForm = () => {
    return (
        <div className="background">
            <Navbar />
            <Form formData={formData} sendInfo={sendInfo} analysis={formAnalysis} />
        </div>
    )
}

export default ManagerForm