import React from 'react'

import RouteListInformation from './RouteListInformation'
import RouteListPresentation from './RouteListPresentation'

import {routeInformationList} from '../../data/data'

const RouteList = () => {


    return (
        <div className="width-full">
            <RouteListInformation list={routeInformationList} />
            <RouteListPresentation />
        </div>
    )
}

export default RouteList