import React from 'react'

import { MyTableHead, MyTableBody, MyTableDialog } from './components'

import MyPopup from '../MyPopup'
import ButtonsRow from '../ButtonsRow'

import { useInputsValues } from './hooks/useInputsValues'

const MyTable = ({ input, addFunction, deleteFunction }) => {
    const { id, text, columns, value } = input

    const { inputsValues, onChange, addValue, deleteValue, isOpen, open, close } = useInputsValues(id, columns, addFunction, deleteFunction)

    return (

        <div className="my-table-container">

            <table className="my-table">
                <caption className="_question-text">{id + 1}. {text}</caption>

                <MyTableHead columns={columns} />

                <MyTableBody value={value} />
            </table>

            <ButtonsRow 
                firstButton={{
                    buttonText: 'Добавить', 
                    buttonFunction: open,
                }} 
                    
                secondButton={{
                    buttonText: 'Удалить',
                    buttonFunction: deleteValue,
                }} />

            {
                inputsValues ?

                    <MyPopup isOpen={isOpen} children={
                        <MyTableDialog inputsValues={inputsValues} onChange={onChange} addValue={addValue} closeFunction={close} />
                    } />

                :

                    null
            }

        </div>

    )
}

export default React.memo(MyTable)