export const formData = [
    {
        id: 'timer',
        type: 'timer',
        hours: 0,
        minutes: 40,
        seconds: 0,
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'textarea',
        text: 'Какие задачи (основные) Вы решаете/решали в занимаемой должности?',
        placeholder: '',
    },
    {
        id: 2,
        type: 'textarea',
        text: 'Какие результаты Вы получаете при решении этих задач?',
        placeholder: '',
    },
    {
        id: 3,
        type: 'textarea',
        text: 'Какой информацией Вы пользовались для успешного решения этих задач? Где Вы ее брали (источники)? Какой информации Вам не хватало?',
        placeholder: '',
    },
    {
        id: 4,
        type: 'textarea',
        text: 'На какие возможности, сильные стороны Вашего направления Вы опирались при решении задач?',
        placeholder: '',
    },
    {
        id: 5,
        type: 'textarea',
        text: 'Какие препятствия или слабые стороны направления затрудняли для Вас решение задач?',
        placeholder: '',
    },
    {
        id: 6,
        type: 'textarea',
        text: 'Определите причины возникновения слабых сторон Вашего направления?',
        placeholder: '',
    },
    {
        id: 7,
        type: 'table',
        text: 'Заполните таблицу',
        columns: [
            {
                id: 0,
                text: 'Кто является участником ситуации в направлении Вашей деятельности?',
            },
            {
                id: 1,
                text: 'По каким вопросам Вы взаимодействовали с ними? Какой результат интересовал данного участника ситуации?',
            },
        ],
    },
    {
        id: 8,
        type: 'textarea',
        text: 'Как будет развиваться ситуация в Вашем направлении, если в настоящее время ничего не менять? Сделайте выводы и обоснуйте их.',
        placeholder: '',
    },
    {
        id: 9,
        type: 'textarea',
        text: 'Предложите план мероприятий по повышению эффективности работы Вашего направления с учетом имеющихся ресурсов. Укажите, какие ресурсы Вам потребуются для реализации этих предложений. Где   Вы предполагаете их брать? Выявите и укажите риски, способные помешать реализации предлагаемых мероприятий, предложений.',
        placeholder: '',
    },
]

export const sendInfo = {
    serviceId: 'service_f9ib1mo',
    templateId: 'template_ql3nec4',
    publicKey: 'hdbgZAlpG2-yu1luq',
}