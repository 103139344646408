import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { setData } from '../../../store/reducers/FormReducer'

import { returnFunctions } from '../helpers/returnValue'

export const useInitForm = (formData) => {
    const dispatch = useDispatch()

    return useCallback(() => {
        const newData = {}

        for (let inputData of formData) {
            const { id, type } = inputData

            if (type !== 'variant' && type !== 'timer' && type !== 'textParagraph') {
                const returnFunction = returnFunctions[type]
                newData[id] = returnFunction(inputData)
            }
        }

        dispatch(setData(newData))
    }, [dispatch, formData])
}
