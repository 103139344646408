export const cards = [
    {
        id: 1,
        to: 'Милый Сашенька!',
        data: '',
        firstParagraph: 'Сегодня меня положили в больницу на плановое обследование. Из больницы выпишут только в понедельник, 4 октября. Выписка происходит с 9.00 до 14.00. Сегодня навестить меня ты сможешь с 17.00 до 19.00.',
        secondParagraph: 'Расчитай всё, пожалуйста, так, чтобы быть дома в 18.30, так как в это время дети вернутся с занятий, они не взяли с собой ключи.',
        thirdParagraph: 'Мама сегодня прийти не сможет - она зайдёт завтра в 8.30.',
        fourthParagraph: 'На следующую среду, 6 октября, мне наконец-то удалось достать билеты на спектакль, на который мы так давно собирались с тобой сходить. Начало в 19.00. Поэтому, пожалуйста, постарайся быть свободным в это время, тем более, что 8 октября - мой день рождения.',
        fifthParagraph: 'Мила, к сожалению, уволилась. Она собирается прийти за расчетом 5 октября, во вторник, в 14.00. Ей причитается не меньше 1500 рублей. С нетерпением жду встречи с тобой.',
        sixthParagraph: 'Поцелуй за меня детей.',

        afterText: '',
        afterText2: '',

        author: 'Целую. Лида.',
        author2: ''
    },
    {
        id: 2,
        to: 'Уважаемый тов. Петров!',
        data: '24.09.',
        firstParagraph:'Как Вам известно, новая улица будет проходить через наш квартал. Это принесет массу неудобства, и не только потому, что будет уничтожена часть нашего двора, но и из-за шума с проезжей части дороги, которая пройдет под окнами нашего дома.',
        secondParagraph:'Сейчас ещё есть возможность потребовать у властей включения в смету проекта строительство шумозащитной стенки, но необходимо единогласное заявление всех жильцов по этому поводу.',
        thirdParagraph:'Собрание жильцов состоится в среду, 6 октября, в 10.00 в помещении школы №675.',
        fourthParagraph:'Ваше присутствие обязательно.',
        fifthParagraph:'',
        sixthParagraph:'',
        
        afterText: 'С уважением.',
        afterText2: 'Председатель ТСЖ',

        author: '',
        author2: 'Ю. Зайцев'
    },
    {
        id: 3,
        to: 'Г-н Петров А.П.',
        data: '',
        firstParagraph:'Служба работы с клиентами компании "Дельта" уведомляет Вас, что на ваше имя 22 сентября доставлен груз. По условиям договора Вам необходимо забрать его в течение недели. Последний день выдачи груза - 29 сентября. В противном случае по условиям договора он будет отправлен обратно на адрес отправителя. Склад работает ежедневно с 8:30 до 17:30 без обеда, без выходных.',
        secondParagraph:'',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'С уважением, Каримов Е.М.',
        author2: 'служба работы с клиентами компании "Дельта"'
    },
    {
        id: 4,
        to: 'Дорогой папочка!',
        data: '',
        firstParagraph:'В среду (6 октября) в 19.00 состоится родительское собрание. Учительница просила, чтобы ты обязательно пришёл. Не волнуйся, ничего страшного там не будет.',
        secondParagraph:'',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'Целую. Яна.',
        author2: '',
    },
    {
        id: 5,
        to: 'Уважаемые жильцы!',
        data: '',
        firstParagraph:'Сообщаем вам, что 5-6 октября будет проводиться плановая проверка показателей счетчиков. В связи с чем просим обеспечить доступ в ваши квартиры с 9.00 до 16.00.',
        secondParagraph:'',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'УК "Уют"',
        author2: '',
    },
    {
        id: 6,
        to: 'Уважаемый Александр Михайлович!',
        data: '',
        firstParagraph:'В связи с избранием Вас присяжным заседателем районного суда Вы приглашаетесь на очередное заседание суда, которое состоится во вторник, 5 октября, с 15.00 до 18.00 в помещении районного суда, комната 413.',
        secondParagraph:'',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: 'Председатель',
        afterText2: 'районного суда',

        author: '',
        author2: 'Д.Я. Ломанов',
    },
    {
        id: 7,
        to: 'Александр,',
        data: '',
        firstParagraph:`приходил страховой агент, интересовался, на какую сумму ты хочешь застраховать квартиру.`,
        secondParagraph:'Он оставил два варианта договора, один из которых ты должен подписать:',
        thirdParagraph:'1 - частичная страховка, как ты страховался в прошлом году - 5 тыс. руб.;',
        fourthParagraph:'2 - комплексная страховка квартиры и всего имущества, как ты, кажется, хотел - 15 тыс. руб.',
        fifthParagraph:'В любом случае, договор и деньги нужно отдать до 30 сентября, потом будет поздно. Твоя страховка заканчивается 1 октября.',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'Ирина Тимофеевна',
        author2: ''
    },
    {
        id: 8,
        to: 'Товарищ Петров.',
        data: 'Тов. Петрову',
        data2: 'ул. Новая, д.3, кв. 171',
        firstParagraph:'Уведомляем Вас об увеличении сметной стоимости строительства гаражного бокса на 125 тыс. руб. Просим подтвердить Ваше участие до 4 октября сего года. В противном случае мы исключаем Вас из состава участников строительства.',
        secondParagraph:'',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'Администрация ООО "Гаражстрой"',
        author2: ''
    },
    {
        id: 9,
        to: 'Привет папа!',
        data: '',
        firstParagraph:'Мне не очень удобно тебя просить, но срочно нужно 1000 рублей. У мамы, как ты знаешь, скоро день рождения, и я купил ей очень хороший подарок. Но денег у меня было только 500 рублей и я продал Сергею Елисееву свой велосипед за 1000 рублей. Он купил его, а потом выяснилось, что его отец не разрешает ему этого делать. Сегодня его отец принёс мой велосипед назад и потребовал вернуть деньги, 1000 рублей.',
        secondParagraph:'Завтра надо будет отдать деньги, так что я надеюсь, пап ты их оставишь.',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'Твой сын Коля.',
        author2: ''
    },
    {
        id: 10,
        to: 'Саша!',
        data: '',
        firstParagraph:'Ты, наверное, получил уведомление из "Дельты". Мой тебе совет - можешь не спешить с этим грузом. Хоть они говорят, что ты должен забрать груз в течение недели, мне кажется, просто пугают, им не выгодно отправлять его назад. В худшем случае заплатишь штраф за просрочку.',
        secondParagraph:'В общем, не буду ничего советовать, решай сам.',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',
        
        afterText: '',
        afterText2: '',

        author: 'Игорь.',
        author2: ''
    },
    {
        id: 11,
        to: 'Уважаемый Александр Михайлович!',
        data: 'Палкин А.Н',
        data2: 'Директор школы № 654',
        firstParagraph:'Ваш сын Николай и дочь Яна стали пропускать занятия. За последнюю неделю они трижды не явились в школу, и это при том, что они не блещут успеваемостью.',
        secondParagraph:'Когда классный руководитель потребовал объяснений, они предъявили несуразное письмо - якобы от Вас. Позднее Яна призналась, что письмо было подделано. Полагаю, что нам с Вами необходимо многое обсудить при личной встрече. Вопрос стоит очень серьезно - у администрации школы должна быть уверенность в том, что подобное больше не повторится. Если отношение Николая к учёбе и поведению не изменится, то будет поставлен вопрос о его отчислении из школы.',
        thirdParagraph:'Поэтому мы настоятельно просим Вас явиться на родительское собрание, которое состоится 6 октября в 19.00.',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',
        
        afterText: 'С уважением',
        afterText2: '',

        author: 'А.Н.Палкин',
        author2: ''
    },
    {
        id: 12,
        to: 'Александр!',
        data: '',
        firstParagraph:'Приходил Студенкин, наш сосед снизу, приятель председателя ТСЖ. Жаловался на то, что мы залили ему водой квартиру. Он требует, чтобы мы сделали ему ремонт, и хочет с тобой поговорить на счет этого в понедельник, 4 октября в 15 часов, иначе он пойдёт жаловаться и обещает подать в суд.',
        secondParagraph:'В понедельник, 4 октября, в 16.00 придёт новая домработница, чтобы уточнить условия работы.',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',

        author: 'Ирина Тимофеевна',
        author2: ''
    },
    {
        id: 13,
        to: 'Уважаемый Александр Михайлович!',
        data: '',
        firstParagraph:'Налоговая инспекция района извещает Вас, что в присланных Вами документах обнаружена ошибка.',
        secondParagraph:'Вам предлагается до 30 сентября сего года, лично прийти в налоговую инспекцию для уточнения информации. В противном случае Вы будете подвергнуты штрафу.',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: 'Начальник отдела',
        afterText2: '',

        author: 'И. Павлов',
        author2: ''
    },
    {
        id: 14,
        to: 'Александр Михайлович!',
        data: '',
        firstParagraph:'На ваш запрос сообщаю, что 5 октября Вы можете отдохнуть после командировки, а 6 октября директор ждёт Вас к 10.15 с отчётом по командировке.',
        secondParagraph:'',
        thirdParagraph:'',
        fourthParagraph:'',
        fifthParagraph:'',
        sixthParagraph:'',

        afterText: '',
        afterText2: '',
        
        author: 'Секретарь директора.',
        author2: ''
    },
];

export const places = [
    {
        id: 1,
        name: 'дом',
        hours: 0,
        minutes: 5,
        position: 'absolute bottom-0 right-0 w-16',
        
        firstLinePosition: 'bottom-10 right-5 h-36 border-r-2',
        secondLinePosition: 'bottom-5 right-0 w-full border-b-2',
        thirdLinePosition: 'bottom-16 right-10 w-36 border-b-2 rotate-16',
        fourthLinePosition: 'bottom-14 right-10 w-72 border-b-2 rotate-11',

        firstNumber: 10,
        secondNumber: 5,
        thirdNumber: 5,
        fourthNumber: 6,

        listOfPlaces: [
            {
                placeName: 'больница',
                time: 10,
            },

            {
                placeName: 'офис',
                time: 5,
            },

            {
                placeName: 'сервис',
                time: 6,
            },

            {
                placeName: 'банкомат',
                time: 5,
            },
        ],

        firstNumberPosition: 'bottom-24 right-0',
        secondNumberPosition: 'bottom-5 right-72',
        thirdNumberPosition: 'bottom-14 right-20',
        fourthNumberPosition: 'bottom-14 right-40',

        logo: '',
    },
    {
        id: 2,
        name: 'больница',
        hours: 0,
        minutes: 5,
        position: 'absolute top-40 right-0 w-28',
        
        firstLinePosition: 'top-10 right-5 h-36 border-r-2',
        secondLinePosition: 'hidden',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 7,
        secondNumber: 0,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'дом',
                time: 10,
            },

            {
                placeName: 'вокзал',
                time: 7,
            },
        ],

        firstNumberPosition: 'top-24 right-0',
        secondNumberPosition: 'hidden',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 3,
        name: 'офис',
        hours: 0,
        minutes: 3,
        position: 'absolute bottom-20 right-36 w-20',
        
        firstLinePosition: 'bottom-20 right-44 h-48 border-r-2',
        secondLinePosition: 'bottom-24 right-40 w-36 border-b-2',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 5,
        secondNumber: 1,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'дом',
                time: 5,
            },

            {
                placeName: 'сервис',
                time: 1,
            },

            {
                placeName: 'оптика',
                time: 5,
            },
        ],

        firstNumberPosition: 'bottom-44 right-40',
        secondNumberPosition: 'bottom-24 right-60',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 4,
        name: 'сервис',
        hours: 0,
        minutes: 5,
        position: 'absolute bottom-20 left-56 w-24',
        
        firstLinePosition: 'bottom-24 right-60 h-48 border-r-2 rotate-40',
        secondLinePosition: 'bottom-24 left-60 h-36 border-r-2 rotate-170',
        thirdLinePosition: 'bottom-0 left-40 h-36 border-r-2 rotate-64',
        fourthLinePosition: 'hidden',

        firstNumber: 4,
        secondNumber: 4,
        thirdNumber: 2,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'дом',
                time: 6,
            },

            {
                placeName: 'офис',
                time: 1,
            },

            {
                placeName: 'оптика',
                time: 4,
            },

            {
                placeName: 'налоговая',
                time: 4,
            },

            {
                placeName: 'банкомат',
                time: 5,
            },
        ],

        firstNumberPosition: 'bottom-48 right-60',
        secondNumberPosition: 'bottom-40 left-56',
        thirdNumberPosition: 'bottom-20 left-40',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 5,
        name: 'банкомат',
        hours: 0,
        minutes: 5,
        position: 'absolute bottom-0 left-0 w-28',
        
        firstLinePosition: 'bottom-5 left-12 h-36 border-r-2',
        secondLinePosition: 'hidden',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 6,
        secondNumber: 0,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'дом',
                time: 5,
            },

            {
                placeName: 'сервис',
                time: 2,
            },

            {
                placeName: 'дельта',
                time: 6,
            },
        ],

        firstNumberPosition: 'bottom-24 left-8',
        secondNumberPosition: 'hidden',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 6,
        name: 'вокзал',
        hours: 0,
        minutes: '',
        position: 'absolute top-0 right-0 w-24',
        
        firstLinePosition: 'top-6 right-0 w-full border-b-2',
        secondLinePosition: 'top-0 right-32 h-36 border-r-2 rotate-64',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 7,
        secondNumber: 4,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'больница',
                time: 7,
            },

            {
                placeName: 'оптика',
                time: 4,
            },

            {
                placeName: 'магазин',
                time: 7,
            },
        ],

        firstNumberPosition: 'top-0 right-72',
        secondNumberPosition: 'top-16 right-24',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 7,
        name: 'оптика',
        hours: 0,
        minutes: 2,
        position: 'absolute top-20 right-36 w-24',
        
        firstLinePosition: 'top-16 right-48 w-96 border-b-2 rotate-11',
        secondLinePosition: 'hidden',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 4,
        secondNumber: 0,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'вокзал',
                time: 4,
            },

            {
                placeName: 'магазин',
                time: 4,
            },

            {
                placeName: 'офис',
                time: 5,
            },

            {
                placeName: 'сервис',
                time: 4,
            },
        ],

        firstNumberPosition: 'top-12 left-60',
        secondNumberPosition: 'hidden',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 8,
        name: 'налоговая',
        hours: 0,
        minutes: 10,
        position: 'absolute top-24 left-36 w-32 break-all',
        
        firstLinePosition: 'top-20 left-12 w-32 border-b-2 rotate-32',
        secondLinePosition: 'top-28 left-32 h-28 border-r-2 rotate-64',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 8,
        secondNumber: 2,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'магазин',
                time: 8,
            },

            {
                placeName: 'дельта',
                time: 2,
            },

            {
                placeName: 'сервис',
                time: 4,
            },
        ],

        firstNumberPosition: 'top-20 left-24',
        secondNumberPosition: 'top-44 left-32',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 9,
        name: 'дельта',
        hours: 0,
        minutes: 3,
        position: 'absolute top-44 left-0 w-24',
        
        firstLinePosition: 'top-0 left-12 h-44 border-r-2',
        secondLinePosition: 'hidden',
        thirdLinePosition: 'hidden',
        fourthLinePosition: 'hidden',

        firstNumber: 10,
        secondNumber: 0,
        thirdNumber: 0,
        fourthNumber: 0,

        listOfPlaces: [
            {
                placeName: 'банкомат',
                time: 6,
            },

            {
                placeName: 'налоговая',
                time: 2,
            },
            
            {
                placeName: 'магазин',
                time: 10,
            },
        ],

        firstNumberPosition: 'top-24 left-6',
        secondNumberPosition: 'hidden',
        thirdNumberPosition: 'hidden',
        fourthNumberPosition: 'hidden',
    },
    {
        id: 10,
        name: 'магазин',
        hours: 0,
        minutes: 2,
        position: 'absolute top-0 left-0 w-24',
        
        firstLinePosition: '',
        secondLinePosition: '',
        thirdLinePosition: '',
        fourthLinePosition: '',

        listOfPlaces: [
            {
                placeName: 'вокзал',
                time: 7,
            },
            
            {
                placeName: 'оптика',
                time: 4,
            },

            {
                placeName: 'налоговая',
                time: 8,
            },

            {
                placeName: 'дельта',
                time: 10,
            },
        ],

        firstNumberPosition: '',
        secondNumberPosition: '',
        thirdNumberPosition: '',
        fourthNumberPosition: '',
    },
];

export const assessments = [
    {
        placeName: 'офис',
        mark: 10,
    },
    {
        placeName: 'сервис',
        mark: 20,
    },
    {
        placeName: 'банкомат',
        mark: 20,
    },
    {
        placeName: 'налоговая',
        mark: 20,
    },
    {
        placeName: 'магазин',
        mark: 20,
    },
    {
        placeName: 'оптика',
        mark: 20,
    },
    {
        placeName: 'больница',
        mark: 10,
    },
];

export const todos = [
    {
        id: 1,
        name: '4 октября',
        dayOfTheWeek: 'Понедельник',
    },
    {
        id: 2,
        name: '5 октября',
        dayOfTheWeek: 'Вторник',
    },
    {
        id: 3,
        name: '6 октября',
        dayOfTheWeek: 'Среда',
    },
    {
        id: 4,
        name: '7 октября',
        dayOfTheWeek: 'Четверг',
    },
    {
        id: 5,
        name: '8 октября',
        dayOfTheWeek: 'Пятница',
    },
];