import React from 'react'

const UserPopup = ({ isOpen, children }) => {

    return (
        <article className={`${isOpen ? '' : 'element-hidden'} userPopup`}>
            {children}
        </article>
    )
}

export default UserPopup