import { useState } from 'react'

export const usePopup = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [currentCard, setCurrentCard] = useState({})

    const open = (card) => {
        setCurrentCard(card)
        setIsOpen(true)
    }

    const close = () => {
        setCurrentCard({})
        setIsOpen(false)
    }

    return {
        currentCard,
        isOpen,
        open,
        close,
    }
}