import { functionsObject } from './creators'

export const getInputs = (formData, data) => {

    const array = []

    for (let inputData of formData) {
        const current = inputData
        const currentType = current.type

        let object = current

        if (currentType in functionsObject) {
            const createFunction = functionsObject[currentType]
            object = createFunction(current, data)
        }

        array.push(object)
    }

    return array
}
