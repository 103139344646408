import React from 'react'
import { MyBottomInput, MySelect, MyTable, MyTextarea, SendFormButton, TextParagraph } from '../UI'

import CheckboxQuestion from '../CheckboxQuestion'

import { useOnChange } from './hooks/useOnChange'
import CheckboxAgreement from '../UI/CheckboxAgreement'
import Timer from '../UI/Timer/Timer'
import Motivation from '../Motivation/Motivation'
import MultiCheckboxQuestion from '../UI/MultiCheckboxQuestion/MultiCheckboxQuestion'

const FormPresentation = ({ inputs, sendInfo, analysis }) => {
    const { inputOnChange, selectOnChange, checkboxOnChange, multipleCheckboxOnChange, tableAddFunction, tableDeleteFunction, motivationSetFunction, checkboxInputOnChange } = useOnChange()
    
    return (
        <>
            {
                inputs.map((input) => (
                    input.type === 'timer' ?
                        <Timer key={input.id} input={input} inputs={inputs} analysis={analysis} sendInfo={sendInfo} />    

                    :

                    input.type === 'textParagraph' ?
                        <TextParagraph key={input.id} input={input} />

                    :

                    input.type === 'bottomInput' ?
                        <MyBottomInput key={input.id} input={input} onChange={inputOnChange} />
                    :

                    input.type === 'textarea' ?

                        <MyTextarea key={input.id} input={input} onChange={inputOnChange} />

                    :

                    input.type === 'select' ?

                        <MySelect key={input.id} input={input} onChange={selectOnChange} />

                    :

                    input.type === 'checkbox' ?

                        <CheckboxQuestion key={input.id} input={input} checkboxOnChange={checkboxOnChange} inputOnChange={checkboxInputOnChange} />

                    :

                    input.type === 'table' ?

                        <MyTable key={input.id} input={input} addFunction={tableAddFunction} deleteFunction={tableDeleteFunction} />

                    :

                    input.type === 'checkboxAgreement' ?

                        <CheckboxAgreement key={input.id} input={input} onChange={checkboxOnChange} />

                    :

                    input.type === 'motivation' ?

                        <Motivation key={input.id} input={input} onChange={motivationSetFunction} />

                    :

                    input.type === 'multiCheckbox' ?

                        <MultiCheckboxQuestion key={input.id} input={input} checkboxOnChange={multipleCheckboxOnChange} inputOnChange={checkboxInputOnChange} />

                    :

                        null
                ))
            }

            <SendFormButton inputs={inputs} sendInfo={sendInfo} analysis={analysis} />
        </>
    )
}

export default React.memo(FormPresentation)