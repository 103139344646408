import React from 'react'
import Parser from '../excelParser/Parser'

import Navbar from '../components/Navbar'

const ExcelParser = () => {
    return (
        <>
            <Navbar />
            <Parser />
        </>
    )
}

export default ExcelParser