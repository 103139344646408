import React from 'react'

const SliderPresentation = ({active, id, greeting, from, text}) => {
    return (
        <div className="width-full text-justify-full">
            <div className={`${active === id ? '' : 'element-hidden'} width-half margin-inline-auto background-color-white border-main border-radius padding-all-large display-flex flex-direction-column`}>
                <h1 className="font-weight-bold">Информация {id}</h1>

                <div className="margin-top-huge">
                    <p>{greeting}</p>
                </div>

                <div className="margin-top-large">
                    {text.map((paragraph, index) => (
                        <p key={index} className="text-indent">{paragraph}</p>
                    ))}
                </div>

                <div className="margin-top-huge">
                    {from}
                </div>
                
            </div>
        </div>
    )
}

export default SliderPresentation