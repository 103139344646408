export const slider = [
    {
        id: 1,
        greeting: "Здравствуй, отец!",
        text: [
            "Я приезжаю из Рязани 28 апреля на вокзал в 17.15. Не мог бы ты меня встретить? Потом, как ты помнишь, в 20.00 мне нужно выезжать в аэропорт, поэтому у нас с тобой будет немного времени, чтобы поболтать, у меня накопилась масса интересных личных новостей и по работе. Буду ждать встречи.",
            "P. S. У меня к тебе просьба: забери, пожалуйста, пакет документов по одному проекту, я хочу поработать над ним в отпуске. Их передаст тебе Вася, мой друг, он будет в кафе, что неподалеку от нашего дома, 28 апреля в 11:30. Это очень важно для меня. Спасибо.",
        ],
        from: "Твой сын, Андрей, 25 апреля.",
    },
    {
        id: 2,
        greeting: "Уважаемый Петров А.М.!",
        text: [
            "Уведомляю Вас, что через наш квартал в скором времени будет проходить новая улица. Это принесет нам массу неудобств, и не только потому, что будет уничтожена часть нашего двора, но и из-за шума с проезжей части дороги, которая пройдет под окнами нашего дома.",
            "Сейчас еще есть возможность потребовать у властей включения в смету проекта строительство шумозащитной стенки, но необходимо коллективное заявление всех жильцов.",
            "Собрание жильцов состоится 29 апреля в 15.15 в офисе ЖСК.",
            "Ваше присутствие обязательно.",
        ],
        from: "С уважением, Зайцев Ю.К., 26 апреля.",
    },
    {
        id: 3,
        greeting: "Александр Михайлович, добрый день!",
        text: [
            "К сожалению, я не смогла до Вас дозвониться и застать на месте. Хотела Вам напомнить, что я отпросилась у Вас на 29 апреля до 13.00. И ещё! Звонил Олег из техслужбы насчет установки нового программного обеспечения на Ваш компьютер, сказал, что он будет у Вас в пятницу в 12:00.",
        ],
        from: "С уважением, Лариса, 27 апреля.",
    },
    {
        id: 4,
        greeting: "Уважаемый Александр Михайлович!",
        text: [
            "Ваша дочь Елизавета стала пропускать занятия, при этом успеваемость резко снизилась.",
            "Внятных объяснений по этому поводу от самой ученицы я не услышала. Полагаю, что нам с Вами необходимо многое обсудить при личной встрече. В противном случае буду вынуждена поставить в известность администрацию школы.",
            "Буду ждать Вас 28 апреля в 17.00 в каб.213.",
        ],
        from: "С уважением, классный руководитель Андреева Т.Б., 26 апреля.",
    },
    {
        id: 5,
        greeting: "Здравствуйте, Александр Михайлович!",
        text: [
            "Поднимался к Вам неоднократно, но так и не застал Вас дома. Сегодня утром Вы затопили мне квартиру, в результате чего пострадал потолок и обои в коридоре и кухне. Требую, чтобы Вы возместили мне причиненный ущерб. Зайду к Вам для обсуждения возникших проблем 28 апреля в 13.00. В противном случае я буду обращаться в суд.",
        ],
        from: "Голубев М.В., сосед из кв.25, 26 апреля.",
    },
    {
        id: 6,
        greeting: "Саша, здравствуй!",
        text: [
            "У нас все нормально, большую часть времени занимаемся огородом и цветами, по возможности, отдыхаем и даже чуть загорели. Тебе привет от Лизы и мамы.",
            "Напоминаю тебе, что завтра в 16:00 ты записан к стоматологу, еле удалось договориться, так как Геннадий Петрович принимает последний день перед отпуском.",
            "Хотела предупредить, что изменилось расписание нашей электрички, теперь она отбывают со ст. «Новые Дачи» по пн., ср. и пт. в 13.00. Время в пути осталось прежним – 1 час. Целую.",
        ],
        from: "Марина, 27 апреля.",
    },
    {
        id: 7,
        greeting: "Александр Михайлович!",
        text: [
            "Подарок Петру Семеновичу я купила, предупредила всех сотрудников, а также пригласила наших коллег из доп. офиса в пятницу к 17:00, чтобы поздравить юбиляра, как мы с Вами и договаривались.",
        ],
        from: "С уважением, Лариса, 27 апреля.",
    },
    {
        id: 8,
        greeting: "Александр Михайлович!",
        text: [
            "Предлагаю Вам встретиться на неделе и обсудить вопросы, касающиеся нашего с Вами сотрудничества.",
            "Мой секретарь свяжется с Вами и известит о дате и времени встречи. Всего доброго.",
        ],
        from: "С уважением, заместитель Главы Управы И.В. Максимов, 25 апреля.",
    },
    {
        id: 9,
        greeting: "Александр Михайлович!",
        text: [
            "Я давно собираюсь с Вами переговорить, но уже долгое время по тем или иным причинам это не представляется возможным. Меня не устраивает мой объем работ. Недавно я нашла более подходящее место работы, к тому же рядом с домом. Мой последний рабочий день 29 апреля. Я беру три оставшихся отгула  с 27 по 29 апреля.",
            "За расчетом я приду 29 апреля в 13:00, хотела бы встретиться с Вами и переговорить по поводу задолженности по зарплате за прошлый месяц.",
        ],
        from: "И.К. Колосова, 25 апреля.",
    },
    {
        id: 10,
        greeting: "Уважаемый  Александр Михайлович!",
        text: [
            "Игорь Васильевич будет готов принять Вас 29 апреля в 15.00.",
        ],
        from: "С уважением, Ольга, секретарь приемной И.В. Максимова, 26 апреля.",
    },
    {
        id: 11,
        greeting: "Уважаемый Александр Михайлович!",
        text: [
            "Я нашла подходящую кандидатуру на должность, которую сейчас занимает Ирина Константиновна Колосова. Я рассказала о наших условиях. Предложение ее заинтересовало, она подойдет к Вам 29 апреля в 12:00, Вы можете с ней переговорить.",
        ],
        from: "С уважением, Лариса, 27 апреля.",
    },
    {
        id: 12,
        greeting: "Уважаемый Петров А.М.!",
        text: [
            "Завтра с 11:00–11:30 Вы сможете подъехать к нам и забрать Ваш ноутбук. Мастер будет на месте и объяснит Вам причину поломки.",
            "С 30 апреля наш сервисный центр закрывается на 2 недели по техническим причинам.",
        ],
        from: "С уважением, служба гарантийного ремонта ООО «Нет проблем», 28 апреля.",
    },
    {
        id: 13,
        greeting: "Александр Михайлович, добрый день!",
        text: [
            "Недавно Вы связывались с нами относительно покупки цветочной рассады. Мы располагаем большим ассортиментом многолетних цветов и растений, и, полагаю, Вы сможете выбрать то, что Вас заинтересует. Для Вашего удобства мы можем оформить заказ и доставку продукции в этот же день: как правило, все это занимает не более 2-х часов. Предлагаю Вам подъехать к нам 28 апреля в 11.30 для оформления необходимых документов.",
        ],
        from: "С уважением, Наталья, менеджер компании «Феерия цветов», 26 апреля.",
    },
    {
        id: 14,
        greeting: "Александр Михайлович!",
        text: [
            "На днях звонили из компании «Сад и огород», которая поставляет нам инструменты и технику, 29 апреля с 16:00–18:00 они нам должны подвезти садовые инструменты и хотели переговорить с Вами.",
        ],
        from: "С уважением, Лариса, 27 апреля.",
    },
    {
        id: 15,
        greeting: "Александр Михайлович!",
        text: [
            "Завтра в 15:00 к нам придет Санэпидемстанция, кто-то из жильцов дома подал на нас жалобу о нарушении норм СанПина. Они просили Вас обязательно быть на месте к этому времени.",
        ],
        from: "Петр Семенович, 28 апреля.",
    },
    {
        id: 16,
        greeting: "Александр Михайлович, добрый день!",
        text: [
            "Недавно Вы обращались по поводу приобретения саженцев. Предлагаю Вам подъехать к нам на складской комплекс в четверг, 28 апреля в 11.00, выбрать и оформить заказ. Это займет около 1,5–2-х часов. С нашей стороны, мы можем также предложить Вам услуги по доставке.",
        ],
        from: "С уважением, менеджер компании «Зеленый мир» Петр Алексеев, 23 апреля",
    },
    {
        id: 17,
        greeting: "Уважаемый Александр Михайлович!",
        text: [
            "Напоминаю Вам, что 28.04 в городском центре искусств будет проходить конференция «Чистый город». К сожалению, И.В. Максимов не сможет присутствовать на ней и он просил Вас выступить с небольшой речью (около 15 мин.) относительно благоустройства нашего города.",
            "Начало конференции в 15:00, Ваше выступление по программе будет приблизительно в 16:00",
        ],
        from: "С уважением, Ольга, секретарь приемной И.В. Максимова.",
    },
    {
        id: 18,
        greeting: "Александр Михайлович, добрый день!",
        text: [
            "Один из наших клиентов отказался от своего заказа (кустарники). Поскольку Вы хотели купить больше кустарников, чем мы могли Вам на тот момент предложить, я решил поставить Вас в известность. Заказ должны вернуть завтра к 11:00, хотя у нас в офисе нерабочий день, я могу подъехать специально и встретиться с Вами, чтобы переоформить заказ, это займет около 15 минут. Будет замечательно, если Вы подъедете к 11:15.",
        ],
        from: "С уважением, менеджер компании «Зеленый мир» Петр Алексеев, 28 апреля.",
    },
    {
        id: 19,
        greeting: "Привет, Саша!",
        text: [
            "Месяц назад я занимал у тебя некоторую сумму денег. Мне выплатили квартальную премию, и я готов вернуть долг. Буду ждать тебя 29 апреля ровно в 13.30 в парке, что недалеко от твоего дома. Прошу не опаздывать, у меня времени в обрез.",
        ],
        from: "Игорь, 27 апреля.",
    },
]

export const routeInformationList = [
    "- У Татьяны Андреевны, Вашей тещи, протекает кран, Вам необходимо заехать на квартиру и заменить его. Татьяна Андреевна в данный момент находится на даче с Вашей женой и дочерью.",
    "- Вам необходимо заехать в магазин и купить смеситель для протекающего крана.",
    "- Вам нужно забрать результаты исследований Вашей жены в поликлинике, т.к. доктор работает последний день перед отпуском.",
    "- Вам следует забрать вещи из химчистки, которые потом нужно будет отнести домой.",
    "- Вам нужно донести справку специалисту МФЦ, чтобы начали процедуру по оказанию госуслуги.",
    "- Вам необходимо забрать заказное письмо из налоговой на почте.",
]

export const paths = [
    {
        id: 1,
        name: "ОФИС",
        time: "",
        listOfPlaces: [
            {
                name: "ДОМ",
                minutes: 3,
            },
            {
                name: "ПОЛИКЛИНИКА",
                minutes: 2,
            },
        ],
        assessment: 0,

        position: "absolute right-0 bottom-0 w-20",
    },
    {
        id: 2,
        name: "МФЦ",
        time: 2,
        listOfPlaces: [
            {
                name: "ДОМ",
                minutes: 3,
            },
            {
                name: "ХИМЧИСТКА",
                minutes: 3,
            },
            {
                name: "ПОЧТА",
                minutes: 3,
            },
        ],
        assessment: 20,

        position: "absolute right-72 bottom-0 w-20",
    },
    {
        id: 3,
        name: "ПОЧТА",
        time: 2,
        listOfPlaces: [
            {
                name: "МФЦ",
                minutes: 3,
            },
            {
                name: "ПАРК",
                minutes: 3,
            },
        ],
        assessment: 20,

        position: "absolute left-52 bottom-0 w-24",
    },
    {
        id: 4,
        name: "ДОМ",
        time: 2,
        listOfPlaces: [
            {
                name: "ОФИС",
                minutes: 3,
            },
            {
                name: "МФЦ",
                minutes: 3,
            },
            {
                name: "ПОЛИКЛИНИКА",
                minutes: 2,
            },
            {
                name: "МАГАЗИН",
                minutes: 7,
            },
            {
                name: "ХИМЧИСТКА",
                minutes: 2,
            },
        ],
        assessment: 20,

        position: "absolute w-20 right-40 bottom-44",
    },
    {
        id: 5,
        name: "МАГАЗИН",
        time: 3,
        listOfPlaces: [
            {
                name: "КВАРТИРА ТЕЩИ",
                minutes: 5,
            },
            {
                name: "ДОМ",
                minutes: 7,
            },
            {
                name: "ХИМЧИСТКА",
                minutes: 3,
            },
        ],
        assessment: 20,

        position: "absolute w-28 right-72 bottom-48",
    },
    {
        id: 6,
        name: "ХИМЧИСТКА",
        time: 3,
        listOfPlaces: [
            {
                name: "МАГАЗИН",
                minutes: 3,
            },
            {
                name: "ДОМ",
                minutes: 2,
            },
            {
                name: "МФЦ",
                minutes: 3,
            },
        ],
        assessment: 10,

        position: "absolute w-36 left-64 bottom-28",
    },
    {
        id: 7,
        name: "ПОЛИКЛИНИКА",
        time: 2,
        listOfPlaces: [
            {
                name: "КВАРТИРА ТЕЩИ",
                minutes: 2,
            },
            {
                name: "ДОМ",
                minutes: 2,
            },
            {
                name: "ОФИС",
                minutes: 2,
            },
        ],
        assessment: 10,

        position: "absolute w-40 right-0 top-0",
    },
    {
        id: 8,
        name: "КВАРТИРА ТЕЩИ",
        time: 4,
        listOfPlaces: [
            {
                name: "ПОЛИКЛИНИКА",
                minutes: 2,
            },
            {
                name: "МАГАЗИН",
                minutes: 5,
            },
            {
                name: "ПАРК",
                minutes: 5,
            },
        ],
        assessment: 20,
        bonusAssessment: 2,

        position: "absolute w-44 right-60 top-0",
    },
    {
        id: 9,
        name: "ПАРК",
        time: 3,
        listOfPlaces: [
            {
                name: "КВАРТИРА ТЕЩИ",
                minutes: 5,
            },
            {
                name: "ПОЧТА",
                minutes: 3,
            },
        ],
        assessment: 20,
        bonusAssessment: 1,

        position: "absolute left-52 top-0 w-24",
    },
]