import React, { useState } from 'react'
import MyCheckbox from '../MyCheckbox'
import MultipleCheckbox from '../MultipleCheckbox'

const AddedCheckboxes = ({ questionId, variantId, text, max, value, checkboxOnChange }) => {

    const [isOpened, setIsOpened] = useState(false)

    return (
        <div className='multiple-checkbox-container'>
            <label className='my-checkbox-label'>
                <input className='my-checkbox visually-hidden' type='checkbox' checked={isOpened} value={isOpened} onChange={() => setIsOpened(prev => !prev)} />

                <span className="my-checkbox-custom"></span>

                <span className="my-checkbox__text">{text}</span>
            </label>

            <ul className={`${isOpened ? '' : 'visually-hidden'} multiple-checkbox__list`}>
                <MultipleCheckbox questionId={questionId} variantId={variantId}  variantValue={`${variantId}.0`} text={'начальный'} max={max} value={value} onChange={checkboxOnChange} />
                <MultipleCheckbox questionId={questionId} variantId={variantId}  variantValue={`${variantId}.1`} text={'средний'} max={max} value={value} onChange={checkboxOnChange} />
                <MultipleCheckbox questionId={questionId} variantId={variantId}  variantValue={`${variantId}.2`} text={'продвинутый'} max={max} value={value} onChange={checkboxOnChange} />
                <MultipleCheckbox questionId={questionId} variantId={variantId}  variantValue={`${variantId}.3`} text={'родной'} max={max} value={value} onChange={checkboxOnChange} />
            </ul>
        </div>
    )
}

export default AddedCheckboxes