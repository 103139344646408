import React, { useEffect, useRef } from 'react'

const InformationDialog = ({ children, close }) => {
    const dialogRef = useRef(null)

    const closeDialog = () => {
      dialogRef.current.close()
      close()
    }

    useEffect(() => {
        dialogRef.current.showModal()
        dialogRef.current.scrollTop = 0
    }, [])

  return (
    <dialog ref={dialogRef} className="information-dialog">
        <div className="information-dialog-container">
          {children}

          <button className="btn information-dialog__button" onClick={closeDialog}>Закрыть</button>
        </div>
    </dialog>
  )
}

export default InformationDialog