export const rows = [
    {
        id: 0,
        columns: [
            {
                id: 0,
                text: [
                    'Начните с личного обращения',
                ],
            },
            {
                id: 1,
                text: [
                    '- Уважаемый Петр Сергеевич!',
                    '- Добрый день, Екатерина Сергеевна!',
                ],
            },
            {
                id: 2,
                text: [
                    'Так у соискателя появится ощущение, что к нему обратились индивидуально, а не просто скопировали готовый текст либо проговорили шаблонный текст',
                ],
            },
        ]
    },
    {
        id: 1,
        columns: [
            {
                id: 0,
                text: [
                    'Вежливо обоснуйте причину отказа',
                ],
            },
            {
                id: 1,
                text: [
                    '- Мы приняли кандидата с большим опытом',
                    '- На эту вакансию мы взяли кандидата с профильным образованием',
                    '- Вы были невнимательны при выполнении некоторых заданий, а это может негативно повлиять на дальнейшую работу',
                ],
            },
            {
                id: 2,
                text: [
                    'Кандидат, которому отказали в мягкой форме, останется лояльным к несостоявшемуся работодателю и с высокой вероятностью будет о нем положительно отзываться',
                ],
            },
        ]
    },
    {
        id: 2,
        columns: [
            {
                id: 0,
                text: [
                    'Отказывая – подбодрите',
                ],
            },
            {
                id: 1,
                text: [
                    '- Спасибо, что выделили время на выполнение тестового задания. Некоторые ответы поразили нас. Однако на данном этапе мы не можем пригласить вас на работу. Сейчас у нас есть кандидат, чей опыт более точно соответствует нашим требованиям',
                ],
            },
            {
                id: 2,
                text: [
                    'Особенно тех, кто начинает карьеру. Дайте понять соискателю, что вас впечатлили его профессиональные навыки и опыт, потенциал. Пусть у претендента останется уверенность в том, что его ценят',
                ],
            },
        ]
    },
    {
        id: 3,
        columns: [
            {
                id: 0,
                text: [
                    '«Доверительный тон»',
                ],
            },
            {
                id: 1,
                text: [
                    '- Алексей Игоревич, работа будет отнимать всё Ваше время и совмещать с учебой никак не получится',
                ],
            },
            {
                id: 2,
                text: [
                    'К примеру, если это студент',
                ],
            },
        ]
    },
    {
        id: 4,
        columns: [
            {
                id: 0,
                text: [
                    'Сохранить контакты',
                ],
            },
            {
                id: 1,
                text: [
                    '- Можно сохранить Ваши контактные данные?',
                    '- Нам хотелось бы оставаться с Вами на связи. Возможно, в ближайшее время появятся новые вакансии, которые Вас заинтересуют.'
                ],
            },
            {
                id: 2,
                text: [
                    'Это на руку и вам, если появится вакансия, на которую он подходит, и соискателю будет приятно'
                ],
            },
        ]
    },
    {
        id: 5,
        columns: [
            {
                id: 0,
                text: [
                    'Передать в другие структуры',
                ],
            },
            {
                id: 1,
                text: [
                    '- Не будете ли против, если Ваши данные передадим в другие структуры Исполнительного комитета? Возможно, Ваша кандидатура их заинтересует',
                ],
            },
            {
                id: 2,
                text: [
                    'Спросите его разрешения на передачу данных в другие структуры',
                ],
            },
        ]
    },
]

export const headers = [
    {
        id: 0,
        text: 'Действия',
    },
    {
        id: 1,
        text: 'Примеры',
    },
    {
        id: 2,
        text: 'Примечание',
    },
]