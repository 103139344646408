import React, { useState } from 'react'

const MySelect = ({ input, onChange }) => {
    const { id, text, options } = input

    const [isActiveInput, setIsActiveInput] = useState(false)

    return (
        <label className="my-select-label">
            <span className="_question-text _no-wrap">{id + 1}. {text}:</span>

            {
                isActiveInput ?

                    <input className="my-bottom-input" onChange={(event) => onChange(id, event, setIsActiveInput)} placeholder='введите свой вариант' />

                :
                    <select className="my-select" onChange={(event) => onChange(id, event, setIsActiveInput)}>
                        {options.map(((option) => (
                            <option key={option.id} className="my-select__option" value={option.text}>{option.text}</option>
                        )))}
                    </select>
            }
        </label>

    )
}

export default React.memo(MySelect)