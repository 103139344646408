import React from 'react'

import ButtonsRow from '../../ButtonsRow'
import MySimpleInput from '../../MySimpleInput'

const MyTableDialog = ({ inputsValues, onChange, addValue, closeFunction }) => {
    return (
        <div className="my-dialog _container">
            {
                inputsValues.map((input) => (
                    <MySimpleInput key={input.id} id={input.id} value={input.value} onChange={onChange} placeholder={input.placeholder} />
                ))
            }

            <ButtonsRow
                firstButton={{
                    buttonText: 'Сохранить', 
                    buttonFunction: addValue,
                }} 
                
                secondButton={{
                    buttonText: 'Закрыть',
                    buttonFunction: closeFunction,
                }} />
        </div>
    )
}

export default React.memo(MyTableDialog)