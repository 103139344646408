import { React, useState } from 'react'
import { todos } from '../features/counter/data'
import { FiPlus, FiX, FiEdit2 } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'
import { addToFirstArray, addToFirstArrayResultString, deleteFromFirstArray, deleteFromFirstArrayResultString, editFirstArray, editFirstArrayResultString, addToSecondArray, addToSecondArrayResultString, deleteFromSecondArray, deleteFromSecondArrayResultString, editSecondArray, editSecondArrayResultString, addToThirdArray, addToThirdArrayResultString, deleteFromThirdArray, deleteFromThirdArrayResultString, editThirdArray, editThirdArrayResultString, addToFourthArray, addToFourthArrayResultString, deleteFromFourthArray, deleteFromFourthArrayResultString, editFourthArray, editFourthArrayResultString, addToFifthArray, addToFifthArrayResultString, deleteFromFifthArray, deleteFromFifthArrayResultString, editFifthArray, editFifthArrayResultString } from '../features/counter/TaskThreeReducer'


const TaskThree = () => {
    const dispatch = useDispatch()

    const [ activeId, setActiveId ] = useState(0)
    const [ edit, setEdit ] = useState(0)
    const [ activeEdit, setActiveEdit ] = useState()

    const [ text, setText ] = useState()
    const [ from, setFrom ] = useState()
    const [ to, setTo ] = useState()
    const [ decision, setDecision ] = useState()

    const [ firstIndex, incrementFirstIndex ] = useState(1)
    const [ secondIndex, incrementSecondIndex ] = useState(1)
    const [ thirdIndex, incrementThirdIndex ] = useState(1)
    const [ fourthIndex, incrementFourthIndex ] = useState(1)
    const [ fifthIndex, incrementFifthIndex ] = useState(1)

    const firstArray = useSelector((state) => state.taskThree.firstArray)
    const secondArray = useSelector((state) => state.taskThree.secondArray)
    const thirdArray = useSelector((state) => state.taskThree.thirdArray)
    const fourthArray = useSelector((state) => state.taskThree.fourthArray)
    const fifthArray = useSelector((state) => state.taskThree.fifthArray)

    const activateId = (id) => {
        setActiveId(id)
    }

    const openEdit = (id, dealId) => {
        setEdit(id)
        setActiveEdit(dealId)

        if (id === 1) {
            let find = firstArray.find(f => f.dealId === dealId)

            setText(find.deal)
            setFrom(find.from)
            setTo(find.to)
            setDecision(find.decision)
        }

        if (id === 2) {
            let find = secondArray.find(f => f.dealId === dealId)

            setText(find.deal)
            setFrom(find.from)
            setTo(find.to)
            setDecision(find.decision)
        }

        if (id === 3) {
            let find = thirdArray.find(f => f.dealId === dealId)

            setText(find.deal)
            setFrom(find.from)
            setTo(find.to)
            setDecision(find.decision)
        }

        if (id === 4) {
            let find = fourthArray.find(f => f.dealId === dealId)

            setText(find.deal)
            setFrom(find.from)
            setTo(find.to)
            setDecision(find.decision)
        }

        if (id === 5) {
            let find = fifthArray.find(f => f.dealId === dealId)

            setText(find.deal)
            setFrom(find.from)
            setTo(find.to)
            setDecision(find.decision)
        }
    }

    const addDeal = (id, from, to, text, decision) => {
        if (id === 1) {

            dispatch(addToFirstArray(
                {
                    dealId: firstIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(addToFirstArrayResultString(
                {
                    dealId: firstIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            incrementFirstIndex(firstIndex + 1)
        }

        if (id === 2) {
            dispatch(addToSecondArray(
                {
                    dealId: secondIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(addToSecondArrayResultString(
                {
                    dealId: secondIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            incrementSecondIndex(secondIndex + 1)
        }
        if (id === 3) {
            dispatch(addToThirdArray(
                {
                    dealId: thirdIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(addToThirdArrayResultString(
                {
                    dealId: thirdIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            incrementThirdIndex(thirdIndex + 1)
        }
        if (id === 4) {
            dispatch(addToFourthArray(
                {
                    dealId: fourthIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(addToFourthArrayResultString(
                {
                    dealId: thirdIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            incrementFourthIndex(fourthIndex + 1)
        }
        if (id === 5) {
            dispatch(addToFifthArray(
                {
                    dealId: fifthIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(addToFifthArrayResultString(
                {
                    dealId: thirdIndex,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            incrementFifthIndex(fifthIndex + 1)
        }

        activateId(0)
        clean()
    }

    const deleteDeal = (id, dealId) => {
        if (id === 1) {
            dispatch(deleteFromFirstArrayResultString(dealId))
            dispatch(deleteFromFirstArray(dealId))
        }
        if (id === 2) {
            dispatch(deleteFromSecondArrayResultString(dealId))
            dispatch(deleteFromSecondArray(dealId))
        }
        if (id === 3) {
            dispatch(deleteFromThirdArrayResultString(dealId))
            dispatch(deleteFromThirdArray(dealId))
        }
        if (id === 4) {
            dispatch(deleteFromFourthArrayResultString(dealId))
            dispatch(deleteFromFourthArray(dealId))
        }
        if (id ===5) {
            dispatch(deleteFromFifthArrayResultString(dealId))
            dispatch(deleteFromFifthArray(dealId))
        }
    }

    const textChange = (event) => {
        setText(event.target.value)
    }

    const fromChange = (event) => {
        setFrom(event.target.value)
    }

    const toChange = (event) => {
        setTo(event.target.value)
    }

    const decisionChange = (event) => {
        setDecision(event.target.value)
    }

    const editDeal = (id) => {
        if (id === 1) {
            let find = firstArray.find(f => f.dealId === activeEdit)

            dispatch(editFirstArrayResultString(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(editFirstArray(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))
        }

        if (id === 2) {
            let find = secondArray.find(f => f.dealId === activeEdit)

            dispatch(editSecondArrayResultString(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(editSecondArray(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))
        }

        if (id === 3) {
            let find = thirdArray.find(f => f.dealId === activeEdit)
            dispatch(editThirdArrayResultString(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(editThirdArray(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))
        }

        if (id === 4) {
            let find = fourthArray.find(f => f.dealId === activeEdit)

            dispatch(editFourthArrayResultString(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(editFourthArray(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))
        }

        if (id === 5) {
            let find = fifthArray.find(f => f.dealId === activeEdit)

            dispatch(editFifthArrayResultString(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))

            dispatch(editFifthArray(
                {
                    dealId: find.dealId,
                    from: from,
                    to: to,
                    deal: text,
                    decision: decision
                }
            ))
        }

        closeEdit()
    }

    const clean = () => {
        setText('')
        setFrom('')
        setTo('')
        setDecision('')
        setActiveEdit('')
    }

    const closeEdit = () => {
        setEdit(0)
        clean()
    }

    return (
        <div className="width-full margin-top-huge">
            <div className="width-full background-color-white display-flex height-3-rem">
                <h1 className="margin-inline-auto fontColor font-weight-bold largeText">ЕЖЕДНЕВНИК</h1>
            </div>

            <div className="position-relative display-flex display-flex-wrap width-half justify-content-center margin-inline-auto">
                {todos.map(todo => (
                    <div key={todo.id} className={`background-color-white margin-top-huge width-24-rem display-flex flex-direction-column border-main border-radius padding-all ${todo.id === 2 ? 'secondLeftMargin' : todo.id === 3 ? 'thirdLeftMargin' : todo.id === 4 ? 'fourthLeftMargin' : todo.id === 5 ? 'fifthLeftMargin' : ''}`}>
                        <div className="width-full display-flex flex-direction-row justify-content-between">
                            <h1 className="font-weight-bold">{todo.name}</h1>
                            <h2 className="font-weight-bold">{todo.dayOfTheWeek}</h2>
                        </div>

                        <div className="width-full display-flex flex-direction-column margin-top">
                            <div className="width-full display-flex flex-direction-row justify-content-between">
                                <h2 className="font-weight-bold">Список дел</h2>
                                <button className="iconButton" onClick={() => activateId(todo.id)}>
                                    <FiPlus className='largeText'/>
                                </button>
                            </div>
                            <div className="margin-top">
                                {
                                    todo.id === 1 && firstArray.length !== 0 ?
                                        firstArray.map((d, index) => (
                                            <div key={index} className="display-flex flex-direction-row align-center">
                                                <div className="width-one-sixth">
                                                    {d.from}-{d.to}
                                                </div>
                                                <div className="width-one-third margin-right">
                                                    {d.deal}
                                                </div>
                                                <div className="width-one-third">
                                                    {d.decision}
                                                </div>
                                                <button className="width-one-twelfth iconButton" onClick={() => openEdit(todo.id, d.dealId)}>
                                                    <FiEdit2 className='bigText'/>
                                                </button>
                                                <button className="width-one-twelfth iconButton" onClick={() => deleteDeal(todo.id, d.dealId)}>
                                                    <FiX className='largeText'/>
                                                </button>
                                            </div>
                                        ))
                                    :
                                    todo.id === 2 && secondArray.length !== 0 ?
                                        secondArray.map((d, index) => (
                                            <div key={index} className="display-flex flex-direction-row align-center">
                                                <div className="width-one-sixth">
                                                    {d.from}-{d.to}
                                                </div>
                                                <div className="width-one-third margin-right">
                                                    {d.deal}
                                                </div>
                                                <div className="width-one-third">
                                                    {d.decision}
                                                </div>
                                                <button className="width-one-twelfth iconButton" onClick={() => openEdit(todo.id, d.dealId)}>
                                                    <FiEdit2 className='bigText'/>
                                                </button>
                                                <button className="width-one-twelfth iconButton" onClick={() => deleteDeal(todo.id, d.dealId)}>
                                                    <FiX className='largeText'/>
                                                </button>
                                            </div>
                                        ))
                                    :
                                    todo.id === 3 && thirdArray.length !== 0 ?
                                        thirdArray.map((d, index) => (
                                            <div key={index} className="display-flex flex-direction-row align-center">
                                                <div className="width-one-sixth">
                                                    {d.from}-{d.to}
                                                </div>
                                                <div className="width-one-third margin-right">
                                                    {d.deal}
                                                </div>
                                                <div className="width-one-third">
                                                    {d.decision}
                                                </div>
                                                <button className="width-one-twelfth iconButton" onClick={() => openEdit(todo.id, d.dealId)}>
                                                    <FiEdit2 className='bigText'/>
                                                </button>
                                                <button className="width-one-twelfth iconButton" onClick={() => deleteDeal(todo.id, d.dealId)}>
                                                    <FiX className='largeText'/>
                                                </button>
                                            </div>
                                        ))
                                    :
                                    todo.id === 4 && fourthArray.length !== 0 ?
                                        fourthArray.map((d, index) => (
                                            <div key={index} className="display-flex flex-direction-row align-center">
                                                <div className="width-one-sixth">
                                                    {d.from}-{d.to}
                                                </div>
                                                <div className="width-one-third margin-right">
                                                    {d.deal}
                                                </div>
                                                <div className="width-one-third">
                                                    {d.decision}
                                                </div>
                                                <button className="width-one-twelfth iconButton" onClick={() => openEdit(todo.id, d.dealId)}>
                                                    <FiEdit2 className='bigText'/>
                                                </button>
                                                <button className="width-one-twelfth iconButton" onClick={() => deleteDeal(todo.id, d.dealId)}>
                                                    <FiX className='largeText'/>
                                                </button>
                                            </div>
                                        ))
                                    :
                                    todo.id === 5 && fifthArray.length !== 0 ?
                                        fifthArray.map((d, index) => (
                                            <div key={index} className="display-flex flex-direction-row align-center">
                                                <div className="width-one-sixth">
                                                    {d.from}-{d.to}
                                                </div>
                                                <div className="width-one-third margin-right">
                                                    {d.deal}
                                                </div>
                                                <div className="width-one-third">
                                                    {d.decision}
                                                </div>
                                                <button className="width-one-twelfth iconButton" onClick={() => openEdit(todo.id, d.dealId)}>
                                                    <FiEdit2 className='bigText'/>
                                                </button>
                                                <button className="width-one-twelfth iconButton" onClick={() => deleteDeal(todo.id, d.dealId)}>
                                                    <FiX className='largeText'/>
                                                </button>
                                            </div>
                                        ))
                                    :
                                    ''
                                }
                            </div>
                        </div>

                        <div className={`position-absolute position-top-14 position-left-0 width-full height-13-rem z-index-10 ${activeId === todo.id ? 'display-block' : 'element-hidden'}`}>
                            <div className="display-flex flex-col justify-content-center width-half padding-all background-color-white border-main border-radius text-justify-center margin-inline-auto">

                                <div className="display-flex flex-direction-row justify-content-center margin-top-large margin-bottom">

                                    <textarea value={from} onChange={fromChange} rows="1" className="width-half margin-right border-main border-radius-full input-outline-none input-resize-none padding-all" placeholder="Время начала" />


                                    <textarea value={to} onChange={toChange} rows="1" className="width-half border-main border-radius-full input-outline-none input-resize-none padding-all" placeholder="Время окончания" />

                                </div>


                                <textarea value={text} onChange={textChange} rows="1" className="margin-bottom border-main width-two-thirds margin-inline-auto border-radius-full input-outline-none input-resize-none padding-all" placeholder="Задание" />

                                <textarea value={decision} onChange={decisionChange} rows="1" className="border-main width-two-thirds margin-inline-auto border-radius-full input-outline-none input-resize-none padding-all" placeholder="Решение" />

                                <div className="margin-top margin-bottom margin-inline-auto display-flex flex-direction-row justify-content-center">
                                    <button onClick={() => addDeal(activeId, from, to, text, decision)} className="buttonStyle margin-right">
                                        Добавить
                                    </button>

                                    <button className="buttonStyle" onClick={() => activateId(0)}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div className={`absolute top-14 left-0 w-full h-52 z-10 ${edit === todo.id ? 'block' : 'hidden'}`}>

                            <div className={`display-flex flex-direction-column justify-content-center width-half background-color-white border-main border-radius text-justify-center margin-inline-auto`}>

                                <div className="display-flex flex-direction-row justify-content-center margin-top-large margin-bottom">

                                    <textarea value={from} onChange={fromChange} rows="1" className="margin-right border-main border-radius-full input-outline-none input-resize-none padding-all" />

                                    <textarea value={to} onChange={toChange} rows="1" className="border-main border-radius-full input-outline-none input-resize-none padding-all" />

                                </div>

                                <textarea value={text} onChange={textChange} rows="1" className="margin-bottom border-main width-two-thirds margin-inline-auto border-radius-full input-outline-none input-resize-none padding-all" />

                                <textarea value={decision} onChange={decisionChange} rows="1" className="border-main width-two-thirds margin-inline-auto border-radius-full input-outline-none input-resize-none padding-all" />

                                <div className="margin-top margin-bottom margin-inline-auto display-flex flex-direction-row justify-content-center">
                                    <button onClick={() => editDeal(edit)} className="buttonStyle margin-right">
                                        Сохранить
                                    </button>

                                    <button className="buttonStyle" onClick={() => closeEdit()}>
                                        Закрыть
                                    </button>
                                </div>

                            </div>
                        </div>


                    </div>
                ))}
            </div>
        </div>
    )
}

export default TaskThree
