import React from 'react'

const RouteListInformation = ({list}) => {
    return (
        <div className="width-full margin-top-huge text-justify-full">

            <div className="width-full display-flex background-color-white fontColor font-weight-bold bigText height-3-rem">
                <h1 className="m-auto">МАРШРУТНЫЙ ЛИСТ</h1>
            </div>

            <p className="width-three-fifths margin-inline-auto padding-all-large text-indent-large">
                Перед Вами схема. На ней указаны пункты, в которых Вам необходимо побывать в обед с 13:00–14:00 в пятницу 29 апреля. Цифры внутри прямоугольников – время (мин), которое Вы затратите на пребывание в указанных пунктах. Линии, соединяющие прямоугольники, показывают возможные пути передвижения. Цифры рядом с линиями – время, необходимое на дорогу.
            </p>

            <p className="width-three-fifths margin-inline-auto padding-all-large text-indent-large">
                Текущее время 13:00. В 14:00 Вам необходимо быть на работе в офисе. Маршрут начинается из Вашего дома.
            </p>

            <div className="width-three-fifths margin-inline-auto padding-all-large background-color-white border-main border-radius">
                <h1 className="font-bold mb-2">Дополнительная информация:</h1>
                <ul>
                    {
                        list.map((point, index) => (
                            <li key={index} className="text-indent-large">{point}</li>
                        ))
                    }
                </ul>
            </div>

            <p className="width-three-fifths margin-inline-auto padding-all-large text-indent-large">
                Попытайтесь посетить все пункты этого маршрута. За каждую минуту пребывания на квартире у тещи и в парке (сверх указанных на схеме) начисляются дополнительные премиальные очки: соответственно – 2 балла и 1 балл.
            </p>

            <p className="width-three-fifths margin-inline-auto padding-all-large text-indent-large">
                Разработанный Вами маршрут опишите в бланке планирования времени.
            </p>
        </div>
    )
}

export default RouteListInformation