import * as XLSX from 'xlsx'

import Papa from 'papaparse'

export const excelParser = (file) => {
    const reader = new FileReader()

    reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, {type: 'array'})
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 'A'})

        jsonData.forEach(row => {
            console.log(row)
        })
    }

    reader.readAsArrayBuffer(file)
}


export const csvParser = (file) => {
    return new Promise(resolve=> {
        Papa.parse(file, {
            complete: function(results) {
                // console.log(results)

                resolve(results)
            }
        });
    })
}