import React from 'react'

import { FiX } from 'react-icons/fi'
import UserFullCardList from './UserFullCardList'

const UserFullCard = ({ currentCard, close }) => {
    return (
        <article className='userFullCardContainer'>

            <section className='userFullCard'>
                <h4 className='font-weight-bold margin-bottom-huge'>Status: <span className=''></span>{currentCard?.answerInfo?.answerStatus}</h4>

                <UserFullCardList currentCard={currentCard} />
            </section>

            <div className='userFullCardButtonContainer'>
                <button className='userFullCardButton' onClick={close}><FiX /></button>
            </div>

        </article>
    )
}

export default UserFullCard