import React from 'react'

const SendButtonPopup = ({ sendingForm, formInputs, send, close }) => {
    return (
        <div className="my-dialog _small-dialog">
            <p className="send-button__text">Подтвердить отправку?</p>

            <div className="send-button-popup">
                <form ref={sendingForm} onSubmit={(e) => send(e)}>
                    {
                        formInputs.map((input) => (
                            <input key={input.id} type="hidden" name={input.name} value={input.value} />
                        ))
                    }

                    <input type="submit" value="Да" className="my-button" />
                </form>

                <button className="my-button" onClick={close}>Нет</button>
            </div>
            
        </div>
    )
}

export default React.memo(SendButtonPopup)