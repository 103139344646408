import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { setCheckbox, setString, addTable, deleteTable, setMotivationObject, setCheckboxInput, setMultipleCheckbox } from '../../../store/reducers/FormReducer'

export const useOnChange = () => {
    const dispatch = useDispatch()

    const checkboxOnChange = useCallback((questionId, variantId, max) => {
        dispatch(setCheckbox({
            id: questionId,
            value: variantId,
            max: max,
        }))
    }, [dispatch])

    const multipleCheckboxOnChange = useCallback((questionId, variantId, variantValue, max) => {
        dispatch(setMultipleCheckbox({
            id: questionId,
            variantId,
            variantValue,
            max,
        }))
    }, [dispatch])

    const inputOnChange = useCallback((id, event) => {
        dispatch(setString(
            {
                id,
                value: event.target.value,
            }
        ))
    }, [dispatch])

    const selectOnChange = useCallback((id, event, setIsActiveInput) => {
        const value = event.target.value

        if (value === 'другое') setIsActiveInput(true)

        dispatch(setString({
            id,
            value,
        }))
    }, [dispatch])

    const tableAddFunction = useCallback((tableId, value) => {
        dispatch(addTable({
            id: tableId,
            value: value,
        }))
    }, [dispatch])

    const tableDeleteFunction = useCallback((tableId) => {
        dispatch(deleteTable(tableId))
    }, [dispatch])

    const motivationSetFunction = useCallback((motivationId, questionId, value) => {
        dispatch(setMotivationObject({
            motivationId,
            questionId,
            value,
        }))
    }, [dispatch])

    const checkboxInputOnChange = useCallback((questionId, variantId, value) => {
        dispatch(setCheckboxInput({
            questionId,
            variantId,
            value,
        }))
    }, [dispatch])

    return {
        inputOnChange,
        selectOnChange,
        checkboxOnChange,
        multipleCheckboxOnChange,
        tableAddFunction,
        tableDeleteFunction,
        motivationSetFunction,
        checkboxInputOnChange,
    }
}