import React from 'react'
import { useMotivation } from './MotivationQuestion/hooks/useMotivation'
import MotivationQuestion from './MotivationQuestion/MotivationQuestion'

const Motivation = ({ input, onChange }) => {
    const { id, questions } = input
    const { currentQuestion, next } = useMotivation(questions, onChange)

    return (
        <div className='motivation'>
            {
                currentQuestion ? 

                    <MotivationQuestion motivationId={id} length={questions.length} question={currentQuestion} next={next} />

                : 

                    null
            }
        </div>
    )
}

export default React.memo(Motivation)