import React from 'react'

const MyBottomInput = ({ input, onChange }) => {
    const { id, text, placeholder, value, withoutId } = input

    return (
        <label className="my-bottom-input__label">
            <span className="_question-text">{withoutId ? '' : `${id + 1}.`} {text}:</span>
            
            <input className="my-bottom-input" type="text" value={value} onChange={(event) => onChange(id, event)} placeholder={placeholder} />
        </label>
    )
}

export default React.memo(MyBottomInput)