import React from 'react'
import AddedCheckboxes from './AddedCheckboxes'

const MultiCheckboxQuestion = ({ input, checkboxOnChange, inputOnChange }) => {
    const { id, text, addedText, variants, max, value, withInput } = input

    return (
        <div className='checkbox-container'>
            <p className='_question-text'>{id + 1}. {text}</p>

            <p className=''>{addedText ? addedText : ''}</p>

            <ul className='checkbox-question__list'>
                {
                    variants.map(variant => (
                        <AddedCheckboxes key={variant.id} questionId={id} variantId={variant.id} text={variant.text} max={max} value={value} checkboxOnChange={checkboxOnChange} />
                    ))
                }
            </ul>

            {
                withInput ?

                    <input className='my-bottom-input my-bottom-input_without-margin' type="text" placeholder='Свой вариант' value={value[variants.length]} onChange={(evt) => inputOnChange(id, variants.length, evt.target.value)} />

                :

                    ''
            }
        </div>
    )
}

export default MultiCheckboxQuestion