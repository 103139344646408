import React from 'react'

const MainInfo = () => {
    return (
        <div className="width-full">
            <div className="margin-inline-auto mainInfo orgtestBackground border-main">
                <h1>Условия задачи</h1>
            </div>

            <div className="mainInfoSecondSector">
                <div className="width-full display-flex flex-direction-row">
                    <div className="width-one-third padding-all-large">
                        <h1 className="font-weight-bold margin-bottom">Участники ситуации:</h1>
                        <p className="text-indent margin-bottom-large">
                            Вы, Петров Александр Михайлович, отец семейства и начальник организации по благоустройству территории.
                        </p>

                        <p></p>

                        <p>
                            - Марина – Ваша жена<br />
                            - Андрей – сын<br />
                            - Лиза – дочь<br />
                            - Татьяна Андреевна – Ваша тёща<br />
                            - Лариса – Ваш секретарь<br />
                            - Пётр Семенович – рабочий<br />
                            - Ирина Константиновна Колосова - дворник
                        </p>
                    </div>

                    <div className="width-two-thirds padding-all-large formLeftBorder">
                        <h1 className="font-weight-bold margin-bottom">Описание</h1>
                        <p className="margin-bottom text-indent-large">
                            Сегодня 28 апреля, четверг, Вы получили поручение от администрации города по озеленению территории Вашего города на ближайшем субботнике 30 апреля, где предполагается благоустройство нового микрорайона. К субботнику Вам необходимо закупить саженцы деревьев и кустарников, а также цветочную рассаду. На закупку саженцев и рассады у Вас есть 2 дня (сегодня и завтра).
                        </p>
                        <p className="margin-bottom text-indent-large">
                            С 25 по 27 апреля Вы отсутствовали в городе по семейным обстоятельствам. Сегодня с утра Вы решили просмотреть корреспонденцию, которая пришла на Ваше имя, и составить план работы на эти 2 дня (28 и 29 апреля). Также Вы планировали разобрать и структурировать рабочую документацию, накопившуюся за долгое время, Вы предполагаете, что в среднем, это займет около 2-х часов.
                        </p>
                        <p className="margin-bottom text-indent-large">
                            Сегодня Вам также нужно встретить Вашего сына на вокзале, возвращающегося из командировки, и у Вас с ним будет некоторое время, чтобы переговорить, так как вечером сегодняшнего дня он уезжает в отпуск на море на 2 недели. К сожалению, никто другой не может встретить сына: жена, тёща и дочь находятся на даче, и предупредить сына тоже невозможно – у него 2 дня отключен мобильный телефон.
                        </p>
                        <p className="margin-bottom text-indent-large">
                            Ваш рабочий день начинается в 9.00 и заканчивается в 18.00, в перерыв с 13.00–14.00 Вы, как правило, отсутствуете в офисе и обедаете дома, который расположен рядом с Вашей работой. Еще одна причина, по которой Вам необходимо появиться дома в обед – покормить домашних животных, обычно их кормит жена или дочь, но в данное время они находятся на даче.
                        </p>
                        <p className="margin-bottom text-indent-large">
                            Просмотрите поступившую информацию и запишите в ежедневнике Ваш план действий в ответ на данную информацию: <span className="font-weight-bold">напишете ли Вы ответную записку, назначите ли срок, решите ли задачу тотчас или после, или вообще ничего не предпримете – все зависит только от Вас.</span><br />
                            У Вас есть 1 час на то, чтобы составить план.<br />
                            Сегодня: 28 апреля, четверг 9:00 ч.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainInfo