import React, { useEffect, useState } from 'react'
import { useMotivationInputs } from './hooks/useMotivationInputs'
import MotivationInputs from './MotivationInputs/MotivationInputs'

const MotivationQuestion = ({ motivationId, question, next }) => {
    const { id, text, variants } = question
    
    const { value, inputs, onChange, clear } = useMotivationInputs(variants)

    return (
        <div className='motivation-question'>
            <p className='motivation-question__text'>{id + 1}. {text}</p>

            <ol className='motivation-question__list'>
                {
                    variants.map(variant => (
                        <li key={variant.id} className='motivation-question__list-item'>
                            <p>{variant.id + 1}. {variant.text}</p>
                        </li>
                    ))
                }
            </ol>

            <MotivationInputs inputs={inputs} onChange={onChange} />

            <button className='my-button' onClick={() => next(motivationId, id, value, clear)}>Далее</button>
        </div>
    )
}

export default React.memo(MotivationQuestion)