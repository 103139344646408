import { config } from './config'
import { checkStatus } from './helpers/helpers'

export const login = async (username, password) => {
    return fetch(`${config.baseUrl}/auth/login?username=${username}&password=${password}`, {
        headers: config.headers
    })
        .then(result => {
            return checkStatus(result)
        })
}