import {React, useRef, useEffect} from 'react'

import {setChecked, setMissing, setReasons} from '../../features/counter/RouteReducer'

import {useDispatch, useSelector} from 'react-redux'

const RouteAfterTable = () => {
    const dispatch = useDispatch()

    const checked = useSelector((state) => state.route.checked)
    const missing = useSelector((state) => state.route.missing)
    const reasons = useSelector((state) => state.route.reasons)

    const checkedOnChange = () => {
        dispatch(setChecked(!checked))
    }

    const missingOnChange = (event) => {
        dispatch(setMissing(event.target.value))
    }

    const reasonsOnChange = (event) => {
        dispatch(setReasons(event.target.value))
    }

    const missingTextareaRef = useRef(null)
    const reasonsTextareaRef = useRef(null)

    useEffect(() => {
        if (missingTextareaRef && missingTextareaRef.current) {
            missingTextareaRef.current.style.height = '0px'
            const scrollHeight = missingTextareaRef.current.scrollHeight
            missingTextareaRef.current.style.height = scrollHeight + 'px'
        }
    }, [missing])

    useEffect(() => {
        if (reasonsTextareaRef && reasonsTextareaRef.current) {
            reasonsTextareaRef.current.style.height = '0px'
            const scrollHeight = reasonsTextareaRef.current.scrollHeight
            reasonsTextareaRef.current.style.height = scrollHeight + 'px'
        }
    }, [reasons])

    return (
        <div className="width-two-thirds margin-inline-auto display-flex flex-direction-column margin-top-huge">

            <label className="orgLabel">
                Все ли пункты посещены:
                <input type="checkbox" onChange={checkedOnChange} className="orgInput margin-left" />
            </label>

            <div className="display-flex flex-direction-row justify-content-between margin-top-large">
                <label className="orgLabel width-half margin-right">
                    Отсутствуют:
                    <textarea ref={missingTextareaRef} className="orgTextarea width-full input-resize-none input-overflow-hidden" value={missing} onChange={missingOnChange} />
                </label>

                <label className="orgLabel width-half">
                    Опишите причины непосещения (кратко): 
                    <textarea ref={reasonsTextareaRef} className="orgTextarea width-full input-resize-none input-overflow-hidden" value={reasons} onChange={reasonsOnChange} />
                </label>
            </div>
        </div>
    )
}

export default RouteAfterTable