import {React, useState, useRef, useEffect} from 'react'

import {addToFirstArray, deleteFromFirstArray, editFirstArray, addToSecondArray, deleteFromSecondArray, editSecondArray} from '../../features/counter/PlannerReducer'

import PlannerPopup from './PlannerPopup'

import {FiPlus, FiX, FiEdit2} from 'react-icons/fi'

import {useDispatch, useSelector} from 'react-redux'

const Planner = () => {

    const dispatch = useDispatch()

    const firstArray = useSelector((state) => state.planner.firstArray)
    const secondArray = useSelector((state) => state.planner.secondArray)

    const [active, setActive] = useState(false)
    const [activeEdit, setActiveEdit] = useState(false)
    const [activeArray, setActiveArray] = useState(0)

    let popupRef = useRef()
    let popupEditRef = useRef()

    useEffect(() => {
        let handler = (e) => {
            if (!popupRef.current.contains(e.target)) {
                setActive(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return() => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        let handler = (e) => {
            if (!popupEditRef.current.contains(e.target)) {
                setActiveEdit(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return() => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const [activeObject, setActiveObject] = useState({})

    const [firstIndex, incrementFirstIndex] = useState(0)
    const [secondIndex, incrementSecondIndex] = useState(0)

    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [note, setNote] = useState("")

    const open = (id) => {
        setActiveArray(id)
        setActive(true)
    }

    const close = () => {
        setActiveArray(0)
        setActive(false)
    }

    const openEdit = (object, id) => {

        setActiveObject(object)

        if (id === 1) {
            let find = firstArray.find(f => f.id === object.id)

            setStart(find.start)
            setEnd(find.end)
            setNote(find.note)
        } else if (id === 2) {
            let find = secondArray.find(f => f.id === object.id)

            setStart(find.start)
            setEnd(find.end)
            setNote(find.note)
        }


        setActiveArray(id)

        setActiveEdit(true)
    }

    const closeEdit = () => {
        setActiveObject({})
        setActiveArray(0)
        setActiveEdit(false)
    }

    const clean = () => {
        setStart("")
        setEnd("")
        setNote("")
    }

    const startOnChange = (event) => {
        setStart(event.target.value)
    }

    const endOnChange = (event) => {
        setEnd(event.target.value)
    }

    const noteOnChange = (event) => {
        setNote(event.target.value)
    }

    const add = () => {
        if (activeArray === 1) {
            dispatch(addToFirstArray(
                {  
                    id: firstIndex,
                    start: start,
                    end: end,
                    note: note,
                },
            ))
            incrementFirstIndex(firstIndex + 1)
        } else if (activeArray === 2) {
            dispatch(addToSecondArray(
                {
                    id: secondIndex,
                    start: start,
                    end: end,
                    note: note,
                },
            ))
            incrementSecondIndex(secondIndex + 1)
        }

        clean()
        close()
    }

    const deleteObject = (objectId, arrayId) => {
        if (arrayId === 1) {
            dispatch(deleteFromFirstArray(objectId))
        } else if (arrayId === 2) {
            dispatch(deleteFromSecondArray(objectId))
        }
    }

    const edit = () => {

        if (activeArray === 1) {

            dispatch(editFirstArray(
                {
                    id: activeObject.id,
                    start: start,
                    end: end,
                    note: note,
                }
            ))
        } else if (activeArray === 2) {
            dispatch(editSecondArray(
                {
                    id: activeObject.id,
                    start: start,
                    end: end,
                    note: note,
                }
            ))
        }

        setActiveObject({})
        clean()
        closeEdit()
    }

    return (
        <div className="width-full margin-top-huge margin-bottom-huge">
            <div className="width-full display-flex height-3-rem background-color-white fontColor">
                <h1 className="margin-inline-auto font-weight-bold">ЕЖЕДНЕВНИК</h1>
            </div>
            <div className="width-half margin-top-huge margin-inline-auto display-flex flex-direction-row justify-content-center position-relative z-index-10 ">
                <div className="width-half display-flex flex-direction-column border-main border-radius padding-all-large margin-right background-color-white">
                    <div className="display-flex flex-direction-row width-full justify-content-between margin-bottom-huge">
                        <h1 className="font-weight-bold">28 апреля, четверг</h1>
                        <button onClick={() => open(1)} className="iconButton"><FiPlus /></button>
                    </div>

                    <div className="display-flex flex-direction-row width-full justify-content-between margin-bottom-large font-weight-bold">
                        <p className="width-one-sixth">ВРЕМЯ</p>
                        <p className="width-one-sixth">ВРЕМЯ</p>
                        <p className="width-seven-twelfth">ПРИМЕЧАНИЯ</p>
                    </div>

                    {
                        firstArray.map((object, index) => (

                            <div key={index} className="flex flex-row mb-2 justify-between">
                                <p className="width-one-sixth">{object.start}</p>
                                <p className="width-one-sixth">{object.end}</p>
                                <p className="width-one-third break-word">{object.note}</p>
                                <div className="width-one-sixth display-flex flex-direction-row">
                                    <button className="margin-right iconButton" onClick={() => openEdit(object, 1)}><FiEdit2 /></button>
                                    <button className="iconButton" onClick={() => deleteObject(object.id, 1)}><FiX /></button>
                                </div>
                            </div>
                            ))
                    }
                </div>

                <div className="width-half display-flex flex-direction-column border-main border-radius padding-all-large background-color-white">
                    <div className="display-flex flex-direction-row width-full justify-content-between margin-bottom-large">
                        <h1 className="font-weight-bold">29 апреля, пятница</h1>
                        <button onClick={() => open(2)} className="iconButton"><FiPlus /></button>
                    </div>

                    <div className="display-flex flex-direction-row width-full justify-content-between margin-bottom-large font-weight-bold">
                        <p className="width-one-sixth">ВРЕМЯ</p>
                        <p className="width-one-sixth">ВРЕМЯ</p>
                        <p className="width-seven-twelfth">ПРИМЕЧАНИЯ</p>
                    </div>

                    {
                        secondArray.map((object, index) => (

                        <div key={index} className="display-flex flex-direction-row margin-bottom justify-content-between">
                            <p className="width-one-sixth">{object.start}</p>
                            <p className="width-one-sixth">{object.end}</p>
                            <p className="width-one-third break-word">{object.note}</p>
                            <div className="width-one-sixth display-flex flex-direction-row">
                                <button className="margin-right iconButton" onClick={() => openEdit(object, 2)}><FiEdit2 /></button>
                                <button className="iconButton" onClick={() => deleteObject(object.id, 2)}><FiX /></button>
                            </div>
                        </div>
                        ))
                    }

                </div>

                <div ref={popupRef} className={`${active ? 'active' : 'inactive'} position-absolute display-flex z-index-20`}><PlannerPopup addFunction={add} closeFunction={close} start={start} startOnChange={startOnChange} end={end} endOnChange={endOnChange} note={note} noteOnChange={noteOnChange} /></div>

                <div ref={popupEditRef} className={`${activeEdit ? 'active' : 'inactive'} position-absolute display-flex z-index-20`}><PlannerPopup addFunction={edit} closeFunction={closeEdit} start={start} startOnChange={startOnChange} end={end} endOnChange={endOnChange} note={note} noteOnChange={noteOnChange}/></div>
            </div>

        </div>
    )
}

export default Planner