import React from 'react'

const TaskOne = () => {
  return (

    <div className="width-full">

        <div className="taskOne orgtestBackground">
            <h1 className="margin-top">Условия задачи</h1>
        </div>

        <div className="width-five-sixths background-color-white border-main display-flex flex-direction-column margin-bottom-large text-justify-full border-bottom-radius margin-inline-auto">

            <div className="width-full display-flex flex-direction-row">
                <div className="width-one-third">
                    <div className="padding-all-large">
                        <h1 className="margin-bottom font-weight-bold">Участники ситуации:</h1>

                        <p className='text-indent margin-bottom-large'>Вы, Петров Александр Михайлович, отец семейства и руководитель отдела крупного предприятия, по роду деятельности часто выезжаете в командировки.</p>

                        <p>- Лида - Ваша жена;</p>
                        <p>- Николай - сын (12 лет);</p>
                        <p>- Яна - дочь (8 лет);</p>
                        <p>- Ирина Тимофеевна - теща (имеет собственную квартиру, но много времени проводит в Вашем доме);</p>
                        <p>- Мила - домработница (присматривает за детьми до возвращения жены с работы). В последнее время у неё испортились отношения с Вашей женой;</p>
                        <p>- Игорь - Ваш знакомый.</p>

                    </div>
                    
                </div>

                <div className="width-two-thirds padding-all-large orgtestLeftBorder">

                    <h1 className="margin-bottom font-weight-bold">Описание</h1>

                    <p className='text-indent margin-bottom-large'>Сегодня, в среду, 29 сентября, у Вас очень ответственный и напряжённый день. Вы только что вернулись из длительной поездки, во время которой накопилось много важных дел. Их осуществление осложняется тем, что завтра в 8.00 Вы уезжаете в очередную командировку, из которой вернётесь только в понедельник, 4 октября, в 19.00.</p>

                    <p className='text-indent margin-bottom-large'>Во время отсутствия у Вас сломался телефон, и Вы не могли прочитать все сообщений, которые приходили на него в ближайшее время. Сейчас Вы находитесь дома и у Вас есть доступ к другим гаджетам. Необходимо просмотреть 14 сообщений в течение одного часа и принять решения по содержащимся в них вопросам. Текущее время 16.00. До 17.00 Вам необходимо составить план действий и с 17.00 приступать к его осуществлению.</p>

                    <p className='text-indent margin-bottom-large'>По условиям задания <span className="font-bold">сегодня 29 сентября</span>, Вы не можете обращаться за помощью и совершать звонки. В остальные дни Вы действуете по своему усмотрению.</p>

                    <p className='text-indent margin-bottom-large'>В Вашем распоряжении 1800 рублей (наличные) и банковская карта (сумма на карте достаточная для решения задач, при этом нет возможности осуществить онлайн перевод).</p>

                    <p className='text-indent'>Итак, Вам предстоит в течение 1 часа составить план решения проблем, возникших за время Вашего отсутствия. О них Вы узнаете из поступивших сообщений. Просмотрите информацию и запишите в <span className="text-underline">"ЕЖЕДНЕВНИК"</span> Ваши решения для каждого случая. Вы совершенно один, никто не может Вам помочь. Взять что-либо с собой в командировку или решить по дороге нельзя. <span className="font-weight-bold">Напишете ли Вы ответную записку, назначите ли срок, решите ли задачу сейчас или после или вообще ничего не предпримете - все зависит только от Вас.</span> Все запланированные дела занесите в <span className="text-underline">"ЕЖЕДНЕВНИК"</span> и опишите в <span className="text-underline">"МАРШРУТНОМ ЛИСТЕ"</span> на 29.09.</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default TaskOne