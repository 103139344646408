export const formData = [
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'bottomInput',
        text: 'Дата рождения',
        placeholder: '',
    },
    {
        id: 2,
        type: 'bottomInput',
        text: 'Место рождения',
        placeholder: '',
    },
    {
        id: 3,
        type: 'bottomInput',
        text: 'Семейное положение',
        placeholder: '',
    },
    {
        id: 4,
        type: 'bottomInput',
        text: 'Дети',
        placeholder: '(количество, возраст)',
    },
    {
        id: 5,
        type: 'bottomInput',
        text: 'Контактный телефон',
        placeholder: '',
    },
    {
        id: 6,
        type: 'bottomInput',
        text: 'Социальные сети',
        placeholder: '(ссылки)',
    },
    {
        id: 7,
        type: 'table',
        text: 'Образование (учебные заведения)',
        columns: [
            {
                id: 0,
                text: 'Год поступления и окончания',
            },
            {
                id: 1,
                text: 'Учебное заведение',
            },
            {
                id: 2,
                text: 'Факультет, специальность',
            },
            {
                id: 3,
                text: 'Уровень образования',
            },
        ],
    },
    {
        id: 8,
        type: 'table',
        text: 'Опыт работы (за последние 5 лет)',
        columns: [
            {
                id: 0,
                text: 'Период работы (м.г/м.г)',
            },
            {
                id: 1,
                text: 'Должность, место работы',
            },
            {
                id: 2,
                text: 'Количество подчиненных',
            },
        ],
    },
    {
        id: 9,
        type: 'textarea',
        text: 'Какими ключевыми профессиональными достижениями Вы гордитесь?',
        placeholder: '',
    },
    {
        id: 10,
        type: 'textarea',
        text: 'В подготовке и реализации каких городских или республиканских проектов Вы принимали участие?',
        placeholder: '',
    },
    {
        id: 11,
        type: 'textarea',
        text: 'Зависели ли от Вас положительные изменения в работе организации?',
        placeholder: 'опишите какие',
    },
    {
        id: 12,
        type: 'textarea',
        text: 'Опишите свой опыт общественной деятельности',
        placeholder: '',
    },
    {
        id: 13,
        type: 'textarea',
        text: 'Что Вы ожидаете от включения в резерв управленческих кадров?',
        placeholder: '',
    },
    {
        id: 14,
        type: 'textarea',
        text: 'Вы чаще отстаиваете свою точку зрения или склонны соглашаться?',
        placeholder: '',
    },
    {
        id: 15,
        type: 'textarea',
        text: 'Увлечения, хобби',
        placeholder: '',
    },
    {
        id: 16,
        type: 'table',
        text: 'Должностные лица, которые могут представить отзыв на Вашу профессиональную деятельность и проявление личностных качеств:',
        columns: [
            {
                id: 0,
                text: 'Фамилия, имя, отчество',
            },
            {
                id: 1,
                text: 'Название организации и должность',
            },
            {
                id: 2,
                text: 'Контактные телефоны',
            },
        ],
    },
    {
        id: 17,
        type: 'checkboxAgreement',
        agreements: [
            {
                id: 0,
                text: 'Я подтверждаю достоверность всего изложенного выше и не возражаю против соответствующей проверки. Я ознакомлен с Положением Отбора и согласен (-на) пройти оценку компетенций и не претендую на получение материалов и результатов оценки.',
            },
            {
                id: 1,
                text: 'Я согласен (-на) на обработку моих персональных данных Мэрией г.Казани (Исполнительным комитетом г.Казани и его структурными подразделениями) в рамках отбора «Конкурс Драйверы города».',
            },
            {
                id: 2,
                text: 'Я ознакомлен (-а), что согласие на обработку персональных данных действует с даты подписания настоящего согласия и может быть отозвано мною на основании письменного заявления.',
            },
        ],
        max: 0,
    },
]

export const sendInfo = {
    serviceId: 'service_yac9btm',
    templateId: 'template_ict4la8',
    publicKey: 'l3N84Q6pFw94Cyq64',
}