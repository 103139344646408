import React from 'react'
import Table from '../table/components/Table'

import { rows, headers } from '../table/data/data'

import Navbar from '../components/Navbar'

const TablePage = () => {

    const name = 'Как отказать кандидату вежливо:'

    return (
        <div className='width-full background'>
            <Navbar />
            <Table name={name} headers={headers} rows={rows} />
        </div>
    )
}

export default TablePage