import {React, useState, useRef} from 'react'

import emailjs from '@emailjs/browser'

import {useDispatch, useSelector} from 'react-redux'

import {setEndTime, setFinish} from '../features/counter/PersonReducer'

import {analysis} from '../features/counter/RouteReducer'
import {plannerAnalysis} from '../features/counter/PlannerReducer'


const Confirm = () => {

    const dispatch = useDispatch()

    const startTime = useSelector((state) => state.person.startTime)
    const endTime = useSelector((state) => state.person.endTime)

    const name = useSelector((state) => state.person.name)
    const surname = useSelector((state) => state.person.surname)
    const middleName = useSelector((state) => state.person.middleName)

    const route = useSelector((state) => state.route.result)
    const routeArray = useSelector((state) => state.route.routes)

    const planner = useSelector((state) => state.planner.result)
    const missing = useSelector((state) => state.route.missing)
    const reasons = useSelector((state) => state.route.reasons)

    const form = useRef()

    const [isOpen, setIsOpen] = useState(false)

    const analysisInfo = () => {
        dispatch(analysis())
        dispatch(plannerAnalysis())
        dispatch(setEndTime(new Date().toString()))
    }

    const open = () => {
        if (name !== "" && surname !== "" && middleName !== "" && routeArray.length !== 1 && missing !== "" && reasons !== "") {
            analysisInfo()
            setIsOpen(true)
        } else {
            alert("Заполните все поля")
        }
    }

    const close = () => {
        setIsOpen(false)
    }

    const send = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_4q4oj5d',
            'template_jo2880j',
            form.current,
            'E6gJDoXUyJA_nu6Gt')
            .then((result) => {
                console.log(result.text)
            }, (error) => {
                console.log(error.text)
            })


        close()
        dispatch(setFinish(true))
    }

    return (
        <div className="width-full margin-top-large margin-bottom-large">
            <div className="width-half margin-inline-auto display-flex justify-content-center">
                <button className={`${isOpen === false ? '' : 'element-hidden'} buttonStyle`} onClick={open}>Отправить</button>
                <div className={`${isOpen === true ? '' : 'element-hidden'} width-full display-flex flex-direction-column text-justify-center justify-content-center`}>
                    <div className="display-flex flex-direction-row justify-content-center">
                        <form className="display-flex flex-direction-row justify-content-center margin-right" ref={form} onSubmit={send}>
                            <input name="name" type="hidden" value={`${surname} ${name} ${middleName}`} />
                            <input name="planner" type="hidden" value={planner} />
                            <input name="route" type="hidden" value={route} />
                            <input name="missing" type="hidden" value={missing} />
                            <input name="reasons" type="hidden" value={reasons} />
                            <input name="start" type="hidden" value={startTime} />
                            <input name="end" type="hidden" value={endTime} />
                            <input type="submit" value="Да" className="pointer margin-right buttonStyle"/>
                        </form>
                        <button className="buttonStyle" onClick={close}>Нет</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Confirm