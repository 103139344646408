import React from 'react'

import Form from '../components/Form/Form'

import { formData, sendInfo } from '../data/activityAnalyticsData'
import { formAnalysis } from '../helpers/analysis'

const ActivityAnalytics = () => {
  return (
    <div className="margin-top-large">
      <Form formData={formData} sendInfo={sendInfo} analysis={formAnalysis} />
    </div>
  )
}

export default React.memo(ActivityAnalytics)