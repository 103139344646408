import React from 'react'

const TableRow = ({ columns }) => {
    return (
        <tr>

            {
                columns.map((column) => (

                    column.id === 0 ?
                        <td className='td blueBg whiteText' key={column.id}>
                            {
                                column.text.map((par, index) => (
                                    <p key={index}>{[par]}</p>
                                ))
                            }
                        </td>
                    :
                    <td className='td whiteBg' key={column.id}>
                        {
                            column.text.map((par, index) => (
                                <p key={index}>{[par]}</p>
                            ))
                        }
                    </td>
                ))
            }

        </tr>
    )
}

export default TableRow