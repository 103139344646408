import * as XLSX from 'xlsx'

export const generateDocument = (usersCards) => {
    const wb = XLSX.utils.book_new()

    const excelArray = [
        ['ФИО', 'Статус', 'Ответ 1', 'Ответ 2', 'Ответ 3', 'Ответ 4', 'Ответ 5']
    ]

    for (let userCard of usersCards) {
        const array = [userCard.userInfo.userName, userCard.answerInfo.answerStatus]

        const answerList = userCard.answerInfo.answerList

        for (let answer of answerList) {
            array.push(answer.questionAnswer)
        }

        excelArray.push(array)
    }

    const ws = XLSX.utils.aoa_to_sheet(excelArray)

    XLSX.utils.book_append_sheet(wb, ws, 'Ответы')
    XLSX.writeFile(wb, "Ответы.xlsx", {compression: true});
}