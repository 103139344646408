export const formData = [
    {
        id: 'motParagraph',
        type: 'textParagraph',
        header: '',
        text: 'Вам нужно распределить 11 баллов между четырьмя вариантами каждого из утверждений, обозначенными буквами (а), (б), (в) и (г).',
    },
    {
        id: 'motParagraph2',
        type: 'textParagraph',
        header: '',
        text: 'Всего утверждений 33, следовательно, получается в общей сложности 363 балла.',
    },
    {
        id: 'motParagraph3',
        type: 'textParagraph',
        header: '',
        text: 'Если вам кажется, что один из факторов наиболее важен для вас, оцените его в 11 баллов, если вы полагаете его вовсе не существенным, не присуждайте ему ни одного балла, в остальных случаях постарайтесь по собственному усмотрению распределить все 11 баллов между четырьмя предложенными в каждом утверждении факторами. Следите за тем, чтобы были присуждены все 11 баллов.',
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
        withoutId: true,
    },
    {
        id: 1,
        type: 'motivation',
        questions: [
            {
                id: 0,
                text: 'Я полагаю, что мог бы внести большой вклад на такой работе, где…',
                variants: [
                    {
                        id: 0,
                        text: 'хорошая заработная плата и прочие виды вознаграждений',
                    },
                    {
                        id: 1,
                        text: 'имеется возможность установить хорошие взаимоотношения с коллегами по работе',
                    },
                    {
                        id: 2,
                        text: 'я мог бы влиять на принятие решений и демонстрировать свои достоинства как работника',
                    },
                    {
                        id: 3,
                        text: 'у меня есть возможность совершенствоваться и расти как личность',
                    },
                ]
            },
            {
                id: 1,
                text: 'Я не хотел бы работать там, где',
                variants: [
                    {
                        id: 0,
                        text: 'отсутствуют четкие указания, что от меня требуется',
                    },
                    {
                        id: 1,
                        text: 'практически отсутствует обратная связь и оценка эффективности моей работы',
                    },
                    {
                        id: 2,
                        text: 'то, чем я занимаюсь выглядит малополезным и малоценным',
                    },
                    {
                        id: 3,
                        text: 'плохие условия работы, слишком шумно или грязно',
                    },
                ]
            },
            {
                id: 2,
                text: 'Для меня важно, чтоб моя работа',
                variants: [
                    {
                        id: 0,
                        text: 'была связана со значительными переменами и разнообразием',
                    },
                    {
                        id: 1,
                        text: 'давала мне возможность работать с широким кругом людей',
                    },
                    {
                        id: 2,
                        text: 'обеспечивала мне четкие указания, чтобы я знал, что от меня требуется',
                    },
                    {
                        id: 3,
                        text: 'позволяла мне хорошо узнать тех людей, с кем я работаю',
                    },
                ]
            },
            {
                id: 3,
                text: 'Я полагаю, что я не был бы очень заинтересован работой, которая',
                variants: [
                    {
                        id: 0,
                        text: 'обеспечивала мне мало контактов с другими людьми',
                    },
                    {
                        id: 1,
                        text: 'едва ли была замечена другими людьми',
                    },
                    {
                        id: 2,
                        text: 'не имела бы конкретных очертаний, так, что я не был бы уверен, что от меня требуется',
                    },
                    {
                        id: 3,
                        text: 'была бы сопряжена с определенным объемом рутинных операций',
                    },
                ]
            },
            {
                id: 4,
                text: 'Работа мне нравится, если',
                variants: [
                    {
                        id: 0,
                        text: 'я четко представляю себе, что от меня требуется',
                    },
                    {
                        id: 1,
                        text: 'у меня удобное рабочее место и меня мало отвлекают',
                    },
                    {
                        id: 2,
                        text: 'у меня хорошее вознаграждение и заработная плата',
                    },
                    {
                        id: 3,
                        text: 'позволяет мне совершенствовать свои профессиональные качества',
                    },
                ]
            },
            {
                id: 5,
                text: 'Полагаю, что мне бы понравилось, если',
                variants: [
                    {
                        id: 0,
                        text: 'были бы хорошие условия работы и отсутствовало бы давление на меня',
                    },
                    {
                        id: 1,
                        text: 'у меня был бы очень хороший оклад',
                    },
                    {
                        id: 2,
                        text: 'работа в действительности была бы полезной и приносила мне удовлетворение',
                    },
                    {
                        id: 3,
                        text: 'мои достижения и работа оценивались бы по достоинству',
                    },
                ]
            },
            {
                id: 6,
                text: 'Я не считаю, что работа должна',
                variants: [
                    {
                        id: 0,
                        text: 'быть слабо структурированной, так что не понятно, что же следует делать',
                    },
                    {
                        id: 1,
                        text: 'предоставлять слишком мало возможностей хорошо узнать других людей',
                    },
                    {
                        id: 2,
                        text: 'быть мало значимой и мало полезной для общества или неинтересной для выполнения',
                    },
                    {
                        id: 3,
                        text: 'оставаться непризнанной, или ее выполнение должно восприниматься как само собой разумеющееся',
                    },
                ]
            },
            {
                id: 7,
                text: 'Работа, приносящая удовлетворение',
                variants: [
                    {
                        id: 0,
                        text: 'связана со значительным разнообразием, переменами и стимуляцией энтузиазма',
                    },
                    {
                        id: 1,
                        text: 'дает возможность совершенствовать свои профессиональные качества и развиваться как личность',
                    },
                    {
                        id: 2,
                        text: 'является полезной и значимой для общества',
                    },
                    {
                        id: 3,
                        text: 'позволяет мне быть креативным (проявлять творческий подход) и экспериментировать с новыми идеями',
                    },
                ]
            },
            {
                id: 8,
                text: 'Важно, чтобы работа',
                variants: [
                    {
                        id: 0,
                        text: 'признавалась и ценилась организацией, в которой я работаю',
                    },
                    {
                        id: 1,
                        text: 'давала бы возможности для персонального роста и совершенствования',
                    },
                    {
                        id: 2,
                        text: 'была сопряжена с большим разнообразием и переменами',
                    },
                    {
                        id: 3,
                        text: 'позволяла бы работнику оказывать влияние на других',
                    },
                ]
            },
            {
                id: 9,
                text: 'Я не считаю, что работа будет приносить удовлетворение, если',
                variants: [
                    {
                        id: 0,
                        text: 'в процессе ее выполнения мало возможностей осуществлять контакты с разными людьми',
                    },
                    {
                        id: 1,
                        text: 'оклад и вознаграждение не очень хороши',
                    },
                    {
                        id: 2,
                        text: 'я не могу установить и поддерживать добрые отношения с коллегами по работе',
                    },
                    {
                        id: 3,
                        text: 'у меня очень мало самостоятельности или возможности для проявления гибкости',
                    },
                ]
            },
            {
                id: 10,
                text: 'Самой хорошей является такая работа, которая',
                variants: [
                    {
                        id: 0,
                        text: 'обеспечивает хорошие рабочие условия',
                    },
                    {
                        id: 1,
                        text: 'дает четкие инструкции и разъяснения по поводу содержания работы',
                    },
                    {
                        id: 2,
                        text: 'предполагает выполнение интересных и полезных заданий',
                    },
                    {
                        id: 3,
                        text: 'позволяет получить признание личных достижений и качества работы',
                    },
                ]
            },
            {
                id: 11,
                text: 'Вероятно, я не буду хорошо работать, если',
                variants: [
                    {
                        id: 0,
                        text: 'имеется мало возможностей ставить перед собой цели и достигать их',
                    },
                    {
                        id: 1,
                        text: 'я не имею возможностей совершенствовать свои личные качества',
                    },
                    {
                        id: 2,
                        text: 'тяжелая работа не получает признание и соответствующего вознаграждения',
                    },
                    {
                        id: 3,
                        text: 'на рабочем месте пыльно, грязно, шумно',
                    },
                ]
            },
            {
                id: 12,
                text: 'При определении служебных обязанностей важно',
                variants: [
                    {
                        id: 0,
                        text: 'дать людям возможность лучше узнать друг друга',
                    },
                    {
                        id: 1,
                        text: 'предоставить возможность работнику ставить цели и достигать их',
                    },
                    {
                        id: 2,
                        text: 'обеспечить условия для проявления работниками творческого начала',
                    },
                    {
                        id: 3,
                        text: 'обеспечить комфортность и чистоту места работы',
                    },
                ]
            },
            {
                id: 13,
                text: 'Вероятно, я не захочу работать там, где',
                variants: [
                    {
                        id: 0,
                        text: 'у меня будет мало самостоятельности и возможности для совершенствования своей личности',
                    },
                    {
                        id: 1,
                        text: 'не поощряются исследования и проявление научного любопытства',
                    },
                    {
                        id: 2,
                        text: 'очень мало контактов с широким кругом людей',
                    },
                    {
                        id: 3,
                        text: 'отсутствуют достойные надбавки и дополнительные льготы',
                    },
                ]
            },
            {
                id: 14,
                text: 'Я был бы удовлетворен, если',
                variants: [
                    {
                        id: 0,
                        text: 'была бы возможность оказывать влияние на принятие решений другими работниками',
                    },
                    {
                        id: 1,
                        text: 'работа предоставляла бы широкое разнообразие и перемены',
                    },
                    {
                        id: 2,
                        text: 'мои достижения были бы оценены другими людьми',
                    },
                    {
                        id: 3,
                        text: 'я точно знал бы, что от меня требуется, и как я должен это выполнять',
                    },
                ]
            },
            {
                id: 15,
                text: 'Работа меньше удовлетворяла бы меня, если',
                variants: [
                    {
                        id: 0,
                        text: 'не позволяла бы ставить и добиваться сложных целей',
                    },
                    {
                        id: 1,
                        text: 'четко не знал бы правил и процедур выполнения работы',
                    },
                    {
                        id: 2,
                        text: 'уровень оплаты моего труда не соответствовал бы уровню сложности выполняемой работы',
                    },
                    {
                        id: 3,
                        text: 'я практически не мог бы влиять на принимаемые решения и на то, что делают другие',
                    },
                ]
            },
            {
                id: 16,
                text: 'Я полагаю, что должность должна предоставлять',
                variants: [
                    {
                        id: 0,
                        text: 'четкие должностные инструкции и указания на то, что от меня требуется',
                    },
                    {
                        id: 1,
                        text: 'возможность лучше узнать своих коллег по работе',
                    },
                    {
                        id: 2,
                        text: 'возможности выполнять сложные производственные задания, требующие напряжения всех сил',
                    },
                    {
                        id: 3,
                        text: 'разнообразие, перемены и поощрения',
                    },
                ]
            },
            {
                id: 17,
                text: 'Работа приносила бы меньше удовлетворения, если',
                variants: [
                    {
                        id: 0,
                        text: 'не допускала бы возможности хотя бы небольшого творческого вклада',
                    },
                    {
                        id: 1,
                        text: 'осуществлялась бы изолировано, т.е. работник должен был бы работать в одиночестве',
                    },
                    {
                        id: 2,
                        text: 'отсутствовал бы благоприятный внутренний климат, в котором работник мог бы профессионально расти',
                    },
                    {
                        id: 3,
                        text: 'не давала бы возможности оказывать влияние на принятие решений',
                    },
                ]
            },
            {
                id: 18,
                text: 'Я хотел бы работать там, где',
                variants: [
                    {
                        id: 0,
                        text: 'другие люди признают и ценят выполняемую мной работу',
                    },
                    {
                        id: 1,
                        text: 'у меня будет возможность оказывать влияние на то, что делают другие',
                    },
                    {
                        id: 2,
                        text: 'имеется достойная система надбавок и дополнительных льгот',
                    },
                    {
                        id: 3,
                        text: 'можно выдвигать и апробировать новые идеи и проявлять креативность',
                    },
                ]
            },
            {
                id: 19,
                text: 'Вряд ли я захотел работать там, где',
                variants: [
                    {
                        id: 0,
                        text: 'не существует разнообразий или перемен в работе',
                    },
                    {
                        id: 1,
                        text: 'у меня будет мало возможностей влиять на принимаемое решения',
                    },
                    {
                        id: 2,
                        text: 'заработная плата не слишком высока',
                    },
                    {
                        id: 3,
                        text: 'условия работы недостаточно хорошие',
                    },
                ]
            },
            {
                id: 20,
                text: 'Я полагаю, что приносящая удовлетворение работа должна предусматривать',
                variants: [
                    {
                        id: 0,
                        text: 'наличие четких указаний, чтобы работники знали, что от них требуется',
                    },
                    {
                        id: 1,
                        text: 'возможность проявлять креативность',
                    },
                    {
                        id: 2,
                        text: 'возможность встречаться с интересными людьми',
                    },
                    {
                        id: 3,
                        text: 'чувство удовлетворения и действительно интересные задания',
                    },
                ]
            },
            {
                id: 21,
                text: 'Работа не будет доставлять удовольствие, если',
                variants: [
                    {
                        id: 0,
                        text: 'предусмотрены незначительные надбавки и дополнительные льготы',
                    },
                    {
                        id: 1,
                        text: 'условия работы некомфортны или в помещении очень шумно',
                    },
                    {
                        id: 2,
                        text: 'работник не имеет возможности сравнивать свою работу с работой других',
                    },
                    {
                        id: 3,
                        text: 'не поощряются исследования, творческий подход и новые идеи',
                    },
                ]
            },
            {
                id: 22,
                text: 'Я считаю важным, чтобы работа обеспечивала мне',
                variants: [
                    {
                        id: 0,
                        text: 'множество контактов с широким кругом интересных людей',
                    },
                    {
                        id: 1,
                        text: 'возможность установления и достижения целей',
                    },
                    {
                        id: 2,
                        text: 'возможность влиять на принятие решений',
                    },
                    {
                        id: 3,
                        text: 'высокий уровень заработной платы. Я считаю важным, чтобы работа обеспечивала мне',
                    },
                ]
            },
            {
                id: 23,
                text: 'Я не думаю, что мне нравилась бы работа, если',
                variants: [
                    {
                        id: 0,
                        text: 'условия работы некомфортны, на рабочем месте грязно и шумно',
                    },
                    {
                        id: 1,
                        text: 'мало шансов влиять на других людей',
                    },
                    {
                        id: 2,
                        text: 'мало возможностей для достижения поставленных целей',
                    },
                    {
                        id: 3,
                        text: 'я не мог бы проявлять креативность и предлагать новые идеи',
                    },
                ]
            },
            {
                id: 24,
                text: 'В процессе организации работы важно',
                variants: [
                    {
                        id: 0,
                        text: 'обеспечить чистоту и комфортность рабочего места',
                    },
                    {
                        id: 1,
                        text: 'создать условия для проявления работником самостоятельности',
                    },
                    {
                        id: 2,
                        text: 'предусмотреть возможность разнообразия и перемен',
                    },
                    {
                        id: 3,
                        text: 'обеспечить человеку широкие возможности контактов с другими людьми',
                    },
                ]
            },
            {
                id: 25,
                text: 'Скорее всего я не захотел бы работать там, где',
                variants: [
                    {
                        id: 0,
                        text: 'условия работы некомфортны, т.е. шумно или грязно и.т.д.',
                    },
                    {
                        id: 1,
                        text: 'мало возможностей осуществлять контакты с другими людьми',
                    },
                    {
                        id: 2,
                        text: 'работа не является интересной или полезной',
                    },
                    {
                        id: 3,
                        text: 'работа рутинная и задания редко меняются',
                    },
                ]
            },
            {
                id: 26,
                text: 'Работа приносит удовлетворение, вероятно, когда',
                variants: [
                    {
                        id: 0,
                        text: 'люди признают и ценят хорошо выполненную работу',
                    },
                    {
                        id: 1,
                        text: 'существуют широкие возможности для маневра и проявление гибкости',
                    },
                    {
                        id: 2,
                        text: 'можно ставить перед собой сложные и смелые цели',
                    },
                    {
                        id: 3,
                        text: 'существует возможность лучше узнать своих коллег',
                    },
                ]
            },
            {
                id: 27,
                text: 'Мне бы не понравилась работа, которая',
                variants: [
                    {
                        id: 0,
                        text: 'не была бы полезной и не приносила бы чувства удовлетворения',
                    },
                    {
                        id: 1,
                        text: 'не содержала бы в себе стимула к переменам',
                    },
                    {
                        id: 2,
                        text: 'не позволяла бы мне устанавливать дружеские отношения с другими',
                    },
                    {
                        id: 3,
                        text: 'была бы неконкретной и не ставила бы сложных задач',
                    },
                ]
            },
            {
                id: 28,
                text: 'Я бы проявил стремление работать там, где',
                variants: [
                    {
                        id: 0,
                        text: 'работа интересная и полезная',
                    },
                    {
                        id: 1,
                        text: 'люди могут устанавливать длительные дружеские взаимоотношения',
                    },
                    {
                        id: 2,
                        text: 'меня окружали бы интересные люди',
                    },
                    {
                        id: 3,
                        text: 'я мог бы оказывать влияние на принятие решений',
                    },
                ]
            },
            {
                id: 29,
                text: 'Я не считаю, что работа должна',
                variants: [
                    {
                        id: 0,
                        text: 'предусматривать, что человек большую часть времени работал в одиночку',
                    },
                    {
                        id: 1,
                        text: 'давать мало шансов на признание личных достижений работника',
                    },
                    {
                        id: 2,
                        text: 'препятствовать установлению взаимоотношений с коллегами',
                    },
                    {
                        id: 3,
                        text: 'состоять в основном из рутинных обязанностей',
                    },
                ]
            },
            {
                id: 30,
                text: 'Хорошо спланированная работа обязательно',
                variants: [
                    {
                        id: 0,
                        text: 'предусматривает достаточный набор льгот и множество надбавок',
                    },
                    {
                        id: 1,
                        text: 'имеет четкие рекомендации по выполнению и должностные обязанности',
                    },
                    {
                        id: 2,
                        text: 'предусматривает возможность ставить цели и достигать их',
                    },
                    {
                        id: 3,
                        text: 'стимулирует и поощряет выдвижение новых идей',
                    },
                ]
            },
            {
                id: 31,
                text: 'Я считал бы, что работа не приносит удовлетворения, если',
                variants: [
                    {
                        id: 0,
                        text: 'не мог бы выполнять сложную перспективную работу',
                    },
                    {
                        id: 1,
                        text: 'было бы мало возможностей для проявления креативности',
                    },
                    {
                        id: 2,
                        text: 'допускалась бы лишь малая доля самостоятельности',
                    },
                    {
                        id: 3,
                        text: 'сама суть работы не представлялась бы полезной или нужной',
                    },
                ]
            },
            {
                id: 32,
                text: 'Наиболее важными характеристиками должности являются',
                variants: [
                    {
                        id: 0,
                        text: 'возможность для творческого подхода и оригинального нестандартного мышления',
                    },
                    {
                        id: 1,
                        text: 'важные обязанности, выполнение которых приносит удовлетворение',
                    },
                    {
                        id: 2,
                        text: 'возможность устанавливать хорошие взаимоотношения с коллегами',
                    },
                    {
                        id: 3,
                        text: 'наличие значимых целей, которых призван достичь работник',
                    },
                ]
            },
        ],
    }
]

export const sendInfo = {
    serviceId: 'service_ungvjm6',
    templateId: 'template_ysxhu7f',
    publicKey: '4fcmw4Dq2gLGqc3VF',
}

export const motivationProfileKeys = [
    [1, 5, 8, 11],
    [3, 6, 12, 2],
    [9, 4, 3, 5],
    [4, 6, 3, 9],
    [3, 2, 1, 11],
    [2, 1, 12, 6],
    [3, 5, 12, 6],
    [9, 11, 12, 10],
    [6, 11, 9, 8],
    [4, 1, 5, 11],
    [2, 3, 12, 6],
    [7, 11, 6, 2],
    [5, 7, 10, 2],
    [11, 10, 4, 1],
    [8, 9, 6, 3],
    [7, 3, 1, 8],
    [3, 5, 7, 9],
    [10, 4, 11, 8],
    [6, 8, 1, 10],
    [9, 8, 1, 2],
    [3, 10, 4, 12],
    [1, 2, 8, 10],
    [4, 7, 8, 1],
    [2, 8, 7, 10],
    [2, 11, 9, 4],
    [2, 4, 12, 9],
    [6, 11, 7, 5],
    [12, 9, 5, 7],
    [12, 5, 4, 8],
    [4, 6, 5, 9],
    [1, 3, 7, 10],
    [7, 10, 11, 12],
    [10, 12, 5, 7],
]