import Form from "../components/Form/Form"

import { formData, sendInfo } from "../data/questionnaireBelgorodData"

import { formAnalysis } from "../helpers/analysis"

const QuestionnaireBelgorodPage = () => {
    return (
        <div className="margin-top-large">
            <Form formData={formData} sendInfo={sendInfo} analysis={formAnalysis} />
        </div>
    )
}

export default QuestionnaireBelgorodPage