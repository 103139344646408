import { React, useState, useRef, useEffect } from 'react'
import { places } from '../features/counter/data'

import { useSelector, useDispatch } from 'react-redux'
import { addToArrays, deleteFromArrays } from '../features/counter/PathsReducer'

import { orgpaths } from '../../images/orgpaths.js'

const Paths = () => {

    const dispatch = useDispatch()

    const [ active, setActive ] = useState(false)

    let popupRef = useRef()

    useEffect(() => {
        let handler = (e) => {
            if (!popupRef.current.contains(e.target)) {
                setActive(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return() => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const defaultExitPlaceValue = 'дом'
    const defaultExitPlaceTime = '17.00'

    let [ exitPlace, setExitPlace ] = useState('')
    const [ exitTime, setExitTime ] = useState('')

    let [ entrancePlace, setEntrancePlace ] = useState('')
    const [ entranceTime, setEntranceTime ] = useState('')

    const array = useSelector((state) => state.paths.pathsArray)

    const openMenu = () => {
        setActive(true)
    }

    const closeMenu = () => {
        setActive(false)
    }

    const exitPlaceChange = (event) => {
        setExitPlace(event.target.value)
    }

    const exitTimeChange = (event) => {
        setExitTime(event.target.value)
    }

    const entrancePlaceChange = (event) => {
        setEntrancePlace(event.target.value)
    }

    const entranceTimeChange = (event) => {
        setEntranceTime(event.target.value)
    }

    const addPath = () => {
        let exitCheck = ''
        let exitTimeCheck = []
        let entranceTimeCheck = []

        if (array.length === 0) {
            exitTimeCheck = '17.00'.split('.')
        } else {
            exitTimeCheck = exitTime.split('.')
        }

        entranceTimeCheck = entranceTime.split('.')
        exitPlace = exitPlace.toLowerCase()
        entrancePlace = entrancePlace.toLowerCase()

        if (array.length === 0) {
            exitCheck = places.find(f => f.name === 'дом')
        } else {
            exitCheck = places.find(f => f.name === exitPlace)
        }

        let entranceCheck = places.find(f => f.name === entrancePlace)

        if (typeof exitCheck !== 'undefined' && typeof entranceCheck !== 'undefined') {
            if (exitTimeCheck.length === 2 && entranceTimeCheck.length === 2) {
                if (array.length === 0) {
                    dispatch(addToArrays(
                        {
                            exitPlace: 'дом',
                            exitTime: '17.00',
                            entrancePlace: entrancePlace,
                            entranceTime: entranceTime
                        }
                    ))

                    clean()

                } else {
                    dispatch(addToArrays(
                        {
                            exitPlace: exitPlace,
                            exitTime: exitTime,
                            entrancePlace: entrancePlace,
                            entranceTime: entranceTime
                        }
                    ))

                    clean()
                }
            } else {
                alert('Неправильный формат времени')
            }
        } else {
            alert('Такого места нет')
        }
    }

    const deletePath = () => {
        if (array.length !== 0) {
            dispatch(deleteFromArrays())
        }

        if (array.length === 1) {
            dispatch(deleteFromArrays())
            setExitPlace(defaultExitPlaceValue)
            setExitTime(defaultExitPlaceTime)
        }
    }

    const clean = () => {
        setExitPlace('')
        setExitTime('')
        setEntrancePlace('')
        setEntranceTime('')
        setActive(false)
    }

    return (
        <div className="pathComponent margin-top-huge padding-all display-flex flex-direction-row margin-inline-auto">

            <div className='width-half'>
                {orgpaths}
            </div>

            <div className="display-flex flex-direction-column width-half margin-left-large align-center text-justify-center position-relative">

                <table className='text-justify-center width-full table-border-collapse background-color-white'>

                    <thead className='width-full'>
                        <tr className='width-full'>
                            <th className='width-half padding-all border-main' colSpan={2}>Пункт назначения</th>
                            <th className='width-half padding-all border-main' colSpan={2}>Время выхода</th>
                        </tr>
                    </thead>

                    
                    <tbody className='width-full'>
                        
                        <tr className='width-full'>
                            <th className='width-quarter padding-all border-main'>откуда - 1 пункт</th>
                            <th className='width-quarter padding-all border-main'>куда - 2 пункт</th>
                            <th className='width-quarter padding-all border-main'>из 1 пункта</th>
                            <th className='width-quarter padding-all border-main'>во 2 пункт</th>
                        </tr>

                        {
                            array.map((object, index) => (
                                <tr key={index} className='w-full'>
                                    <td className='width-quarter padding-all border-main'>{object.exitPlace}</td>
                                    <td className='width-quarter padding-all border-main'>{object.entrancePlace}</td>
                                    <td className='width-quarter padding-all border-main'>{object.exitTime}</td>
                                    <td className='width-quarter padding-all border-main'>{object.entranceTime}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="margin-top-large display-flex flex-direction-row justify-content-center">
                    <button onClick={() => openMenu()} className="buttonStyle margin-right">
                        Добавить
                    </button>

                    <button onClick={() => deletePath()} className="buttonStyle">
                        Удалить
                    </button>
                </div>

                <div ref={popupRef} className={`${active === true ? 'active' : 'inactive'} padding-all orgtestPathsContainer margin-inline-auto display-flex flex-direction-column background-color-white width-two-thirds align-center border-main border-radius`}>

                    <div className="width-full display-flex flex-direction-row margin-top">
                        <div className="width-half display-flex flex-direction-column">
                            <textarea type="text" value={array.length === 0 ? defaultExitPlaceValue : exitPlace} rows={1} onChange={exitPlaceChange} className="border-main border-radius input-resize-none margin-right input-outline-none padding-left" placeholder="Откуда"/>
                        </div>

                        <div className="width-half display-flex flex-direction-column">
                            <textarea value={array.length === 0 ? defaultExitPlaceTime : exitTime} rows={1} onChange={exitTimeChange} className="border-main border-radius input-resize-none input-outline-none padding-left" placeholder="Время выхода"/>
                        </div>
                    </div>

                    <div className="width-full display-flex flex-direction-row margin-top">
                        <div className="width-half display-flex flex-direction-column">
                            <textarea value={entrancePlace} onChange={entrancePlaceChange} rows={1} className="border-main border-radius input-resize-none margin-right input-outline-none padding-left" placeholder="Куда"/>
                        </div>

                        <div className="width-half display-flex flex-direction-column">
                            <textarea value={entranceTime} onChange={entranceTimeChange} rows={1} className="border-main border-radius input-resize-none input-outline-none padding-left" placeholder="Время прибытия"/>
                        </div>
                    </div>

                    <div className="margin-top margin-bottom display-flex flex-direction-row justify-content-center">
                        <button onClick={() => addPath()} className="buttonStyle margin-right">
                            Сохранить
                        </button>

                        <button onClick={() => closeMenu()} className="buttonStyle">
                            Закрыть
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Paths
