import React from 'react'

import MyPopup from '../MyPopup'

import { useSendButton } from './hooks/useSendButton'
import SendButtonPopup from './components/SendButtonPopup'

const SendFormButton = ({ inputs, sendInfo, analysis }) => {
    const { isOpen, open, send, close, sendingForm, formInputs } = useSendButton(inputs, sendInfo, analysis)

    return (
        <div className="send-button-container">
            <button className="my-button" onClick={open}>Отправить</button>

            <MyPopup isOpen={isOpen} children={
                <SendButtonPopup sendingForm={sendingForm} formInputs={formInputs} send={send} close={close} />
            } />
        </div>
    )
}

export default React.memo(SendFormButton)