export const formData = [
    {
        id: 0,
        type: 'bottomInput',
        text: 'Претендент на должность',
        placeholder: '',
    },
    {
        id: 1,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 2,
        type: 'bottomInput',
        text: 'Дата рождения',
        placeholder: '(число, месяц, год)',
    },
    {
        id: 3,
        type: 'bottomInput',
        text: 'Место рождения',
        placeholder: '',
    },
    {
        id: 4,
        type: 'bottomInput',
        text: 'Семейное положение',
        placeholder: '',
    },
    {
        id: 5,
        type: 'bottomInput',
        text: 'Дети',
        placeholder: '(количество, возраст)',
    },
    {
        id: 6,
        type: 'bottomInput',
        text: 'Адрес регистрации',
        placeholder: '',
    },
    {
        id: 7,
        type: 'bottomInput',
        text: 'Адрес проживания',
        placeholder: '',
    },
    {
        id: 8,
        type: 'bottomInput',
        text: 'Контактный телефон',
        placeholder: '',
    },
    {
        id: 9,
        type: 'bottomInput',
        text: 'Адрес электронной почты',
        placeholder: '',
    },
    {
        id: 10,
        type: 'bottomInput',
        text: 'Судимость',
        placeholder: '(есть/нет)',
    },
    {
        id: 11,
        type: 'bottomInput',
        text: 'Минимальный уровень заработной платы',
        placeholder: '',
    },
    {
        id: 12,
        type: 'table',
        text: 'Образование (учебные заведения, курсы)',
        columns: [
            {
                id: 0,
                text: 'Год поступления и окончания',
            },
            {
                id: 1,
                text: 'Учебное заведение',
            },
            {
                id: 2,
                text: 'Факультет, специальность',
            },
        ],
    },
    {
        id: 13,
        type: 'table',
        text: 'Опыт работы',
        columns: [
            {
                id: 0,
                text: 'Период работы (м.г/м.г)',
            },
            {
                id: 1,
                text: 'Должность, место работы',
            },
            {
                id: 2,
                text: 'Причина поиска (смены) работы',
            },
        ],
    },
    {
        id: 14,
        type: 'textarea',
        text: 'Должностные обязанности по основному месту работы',
        placeholder: '',
    },
    {
        id: 15,
        type: 'table',
        text: 'Близкие родственники (жена/муж, отец, мать, братья, сестры)',
        columns: [
            {
                id: 0,
                text: 'Степень родства',
            },
            {
                id: 1,
                text: 'Ф.И.О.',
            },
            {
                id: 2,
                text: 'Дата рождения',
            },
            {
                id: 3,
                text: 'Место работы, должность',
            },
            {
                id: 4,
                text: 'Место жительства',
            },
        ],
    },
    {
        id: 16,
        type: 'textarea',
        text: 'Перечислите три главных личных результата, достижением которых Вы гордитесь.',
        placeholder: '',
    },
    {
        id: 17,
        type: 'textarea',
        text: 'Принимали ли Вы участие в каких-либо проектах (районных, городских, республиканских)? Если да, опишите Ваш личный вклад в их реализацию.',
        placeholder: '',
    },
    {
        id: 18,
        type: 'textarea',
        text: 'Какими ключевыми профессиональными навыками Вы обладаете?',
        placeholder: '',
    },
    {
        id: 19,
        type: 'textarea',
        text: 'Что, на Ваш взгляд, стимулирует людей наиболее эффективно?',
        placeholder: '',
    },
    {
        id: 20,
        type: 'textarea',
        text: 'Чем привлекает должность руководителя?',
        placeholder: '',
    },
    {
        id: 21,
        type: 'textarea',
        text: 'Что для Вас значат семейные ценности?',
        placeholder: '',
    },
    {
        id: 22,
        type: 'textarea',
        text: 'Ваши планы на ближайший год (3 года)',
        placeholder: '',
    },
    {
        id: 23,
        type: 'textarea',
        text: 'Увлечения, хобби',
        placeholder: '',
    },
]

export const sendInfo = {
    serviceId: 'service_1q8mrap',
    templateId: 'template_b79wx4e',
    publicKey: 'XAAQQj3rKFWNSxu5v',
}