import React from 'react'

import Form from '../components/Form/Form'

import { formData, sendInfo } from '../data/zagsData'
import { formAnalysis } from '../helpers/analysis'
import Navbar from '../components/Navbar'

const Zags = () => {
    return (
        <div className="background">
            <Navbar />
            <Form formData={formData} sendInfo={sendInfo} analysis={formAnalysis} />
        </div>
    )
}

export default Zags