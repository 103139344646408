import React from 'react'
import { isSuccess } from './helpers/isSuccess'

const UserCard = ({ card, open }) => {

    return (
        <div className={`${isSuccess(card?.answerInfo?.answerStatus) ? 'successCard' : 'progressCard'} userCard`}>
            
            <div className='userCardInfo'>
                <h3 className='font-weight-bold'>{card?.userInfo?.userName}</h3>
            </div>

            <div className='userCardButtons'>
                <button className='userCardButton' onClick={() => open(card)}>Открыть</button>
            </div>

        </div>
    )
}

export default UserCard