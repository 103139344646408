import React from 'react'

const PlannerPopup = ({addFunction, closeFunction, start, startOnChange, end, endOnChange, note, noteOnChange}) => {
    return (
        <div className="background-color-white border-main border-radius">
        
            <div className="margin-top-large margin-bottom-large margin-right-large margin-left-large display-flex flex-direction-column">
                <div className="display-flex justify-content-between">
                    <input className="orgInput margin-right width-quarter" placeholder="Начало" value={start} onChange={startOnChange} />
                    <input className="orgInput margin-right width-quarter" placeholder="Конец" value={end} onChange={endOnChange} />
                    <input className="orgInput width-half" placeholder="Примечание" value={note} onChange={noteOnChange} />
                </div>

                <div className="display-flex flex-direction-row justify-content-center margin-top-large">
                    <button className="buttonStyle margin-right" onClick={addFunction}>Сохранить</button>
                    <button className="buttonStyle" onClick={closeFunction}>Закрыть</button>
                </div>
                
            </div>
        </div>
    )
}

export default PlannerPopup