import React from 'react'

const Information = () => {
  return (
    <div className="width-full display-flex flex-direction-column margin-top-huge">

      <div className="width-three-fifths margin-inline-auto padding-all-large">
        <p className='text-indent margin-bottom-large'>Перед Вами схема. На ней указаны места, где Вам необходимо побывать. Цифры внутри прямоугольников - время в минутах, которое Вы затратите на пребывание в указанных местах. Линии, соединяющие прямоугольники, показывают возможные пути.</p>
        <p className='text-indent margin-bottom-large'>Текущее время - 17.00.</p>
        <p className='text-indent margin-bottom-large'>Указанные ниже заведения Вам необходимо посетить до 19.00 часов.</p>
        <p className='text-indent text-underline'>Постарайтесь посетить все пункты этого маршрута.</p>
      </div>

      <div className="background-color-white width-full font-weight-bold largeText fontColor height-4-rem margin-top-large margin-bottom-large display-flex">
        <h1 className="margin-inline-auto">МАРШРУТНЫЙ ЛИСТ</h1>
      </div>

      <div className="background-color-white width-half margin-inline-auto border-main border-radius padding-all-large text-justify-full">
        <h1 className="font=weight-bold text-indent margin-bottom-large">Дополнительная информация:</h1>
          
        <p className='text-indent-large'>- до отъезда в командировку Вам нужно забрать из салона "Оптика" очки;</p>
        <p className='text-indent-large'>- у Вас сломался мобильный телефон, и поэтому придётся зайти в сервис и выяснить, насколько это серьёзно;</p>
        <p className='text-indent-large'>- Вам необходимо зайти в офис и забрать документы по предстоящей командировке в период с 17.00 до 19.00;</p>
        <p className='text-indent-large margin-bottom-large'>- у Вашей сестры завтра день рождения. Она будет сегодня проездом на вокзале.</p>

        <p className='text-indent margin-bottom-large'>Стоянка поезда с 17.57 до 18.03. Вы сообщили СЕСТРЕ, что встретитесь с ней на вокзале, и у Вас для неё приготовлен подарок. Выбранный подарок оставлен для Вас в магазине.</p>

        <p className='text-indent margin-bottom-large'>За каждую минуту пребывания в больнице и офисе (сверх указанных на схеме) начисляются дополнительные <span className="text-underline">премиальные очки</span>: 3 балла и 2 балла.</p>

        <p className='text-indent'>Разработанный маршрут опишите в маршрутном листе.</p>

      </div>
    </div>
  )
}

export default Information