import {React, useState, useRef, useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {addRoute, deleteRoute} from '../../features/counter/RouteReducer'

import {paths} from '../../data/data'

import RoutePopup from './RoutePopup'

const RouteTable = () => {
    const dispatch = useDispatch()

    const routes = useSelector((state) => state.route.routes)

    const [active, setActive] = useState(false)

    let popupRef = useRef()

    useEffect(() => {
        let handler = (e) => {
            if (!popupRef.current.contains(e.target)) {
                setActive(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return() => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const [firstValue, setFirstValue] = useState("")
    const [secondValue, setSecondValue] = useState("")
    const [thirdValue, setThirdValue] = useState("")

    const firstValueOnChange = (event) => {
        setFirstValue(event.target.value)
    }

    const secondValueOnChange = (event) => {
        setSecondValue(event.target.value)
    }

    const thirdValueOnChange = (event) => {
        setThirdValue(event.target.value)
    }

    const open = () => {
        setActive(true)
    }

    const close = () => {
        setActive(false)
    }

    const clean = () => {
        setFirstValue("")
        setSecondValue("")
        setThirdValue("")
    }

    const add = () => {

        let find = paths.find(f => f.name === firstValue.toUpperCase())

        let entranceTimeArray = secondValue.split(".")
        let exitTimeArray = thirdValue.split(".")

        if (entranceTimeArray.length === 2 && exitTimeArray.length === 2) {
            if (typeof find !== 'undefined') {
                dispatch(addRoute(
                    {
                        firstColumn: firstValue.toUpperCase(),
                        secondColumn: secondValue,
                        thirdColumn: thirdValue,
                    }
                ))
        
                clean()
                close()
            } else {
                alert("Неправильное место")
            }
        } else {
            alert("Неверный формат")
        }
    }

    const deleteLast = () => {
        dispatch(deleteRoute())
    }

    return (
        <div className="width-half display-flex flex-direction-column margin-left-large align-center text-justify-center">
            <div className="margin-bottom-large position-relative">
                <table className="orgTable">
                    <tbody>
                        <tr>
                            <th className="orgTh">Пункт посещения</th>
                            <th className="orgTh">Время прибытия</th>
                            <th className="orgTh">Время убытия</th>
                        </tr>

                        {
                            routes.map((route, index) => (
                                <tr key={index}>
                                    <td className="orgTd">
                                        {route.firstColumn}
                                    </td>
                                    <td className="orgTd">
                                        {route.secondColumn}
                                    </td>
                                    <td className="orgTd">
                                        {route.thirdColumn}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div ref={popupRef} className={`${active === true ? 'active' : 'inactive'} orgtestPopup`}>
                    <RoutePopup closeFunction={close} addFunction={add} firstValue={firstValue} firstValueOnChange={firstValueOnChange} secondValue={secondValue} secondValueOnChange={secondValueOnChange} thirdValue={thirdValue} thirdValueOnChange={thirdValueOnChange} />
                </div>
            </div>

            <div className="display-flex flex-direction-row">
                <button className="buttonStyle margin-right" onClick={open}>Добавить</button>
                <button className="buttonStyle" onClick={deleteLast}>Удалить</button>
            </div>
        </div>
    )
}

export default RouteTable