export const firstVariant = [
    {
        id: 'variant',
        type: 'variant',
        variant: 1,
    },
    {
        id: 'timer',
        type: 'timer',
        hours: 0,
        minutes: 10,
        seconds: 0,
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'checkbox',
        text: 'Перчатки надевают или одевают?',
        variants: [
            {
                id: 0,
                text: 'Надевают',
            },
            {
                id: 1,
                text: 'Одевают',
            },
            {
                id: 2,
                text: 'Оба варианта верны',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 2,
        type: 'checkbox',
        text: 'В каком случае ударения поставлены верно?',
        variants: [
            {
                id: 0,
                text: 'кУхонный, деньгАми, творОг',
            },
            {
                id: 1,
                text: 'кухОнный, деньгАми, твОрог',
            },
            {
                id: 2,
                text: 'кУхонный, дЕньгами, твОрог',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 3,
        type: 'checkbox',
        text: 'В каком случае сочетания слов употреблены верно?',
        variants: [
            {
                id: 0,
                text: 'Мой день рождения',
            },
            {
                id: 1,
                text: 'Мой день рождение',
            },
            {
                id: 2,
                text: 'Оба варианта',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 4,
        type: 'checkbox',
        text: 'В каком предложении вместо слова ПРЕДСТАВИТЬ нужно употребить ПРЕДОСТАВИТЬ?',
        variants: [
            {
                id: 0,
                text: 'Журналистам ПРЕДСТАВИЛИ нового кандидата в мэры',
            },
            {
                id: 1,
                text: 'Адвокат будет ПРЕДСТАВЛЯТЬ Ваши интересы в суде',
            },
            {
                id: 2,
                text: 'Книга ПРЕДСТАВЛЯЕТ собой практическое руководство для специалистов, работающих в области юриспруденции и права',
            },
            {
                id: 3,
                text: 'Гражданам ПРЕДСТАВЛЯЕТСЯ право выбора формы подачи документов',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [3],
    },
    {
        id: 5,
        type: 'checkbox',
        text: 'Отметьте предложения, в которых нет речевых ошибок:',
        variants: [
            {
                id: 0,
                text: 'Депутаты Государственной Думы стали в курсе этого решения лишь сегодня',
            },
            {
                id: 1,
                text: 'У американцев не принято спрашивать, какие доходы они зарабатывают',
            },
            {
                id: 2,
                text: 'Вопреки нашим ожиданиям утром пошел дождь',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 6,
        type: 'checkbox',
        text: 'В каком варианте ответа правильно указаны цифры, на месте которых в предложении должны стоять запятые?',
        addedText: 'Гражданский служащий (1) получивший поручение (2) направленное (3) по его мнению (4) на совершение коррупционных действий (5) должен представить в письменной форме обоснование неправомерности (6) данного поручения',
        variants: [
            {
                id: 0,
                text: '1, 2, 4, 5',
            },
            {
                id: 1,
                text: '1, 2, 3, 4, 5',
            },
            {
                id: 2,
                text: '1, 2, 5, 6',
            },
            {
                id: 3,
                text: '1, 2, 3, 5, 6',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 7,
        type: 'checkbox',
        text: 'Укажите словосочетания, в которых нарушены нормы сочетаемости слов',
        variants: [
            {
                id: 0,
                text: 'предоставлять возможность',
            },
            {
                id: 1,
                text: 'надеемся на сотрудничество',
            },
            {
                id: 2,
                text: 'сказать радушные слова',
            },
            {
                id: 3,
                text: 'уделить серьезное значение',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [3],
    },
    {
        id: 8,
        type: 'checkbox',
        text: 'В каком варианте ответа нет ошибок в употреблении предлогов?',
        variants: [
            {
                id: 0,
                text: 'доложить по приезду',
            },
            {
                id: 1,
                text: 'согласно договора',
            },
            {
                id: 2,
                text: 'благодаря содействию',
            },
            {
                id: 3,
                text: 'вопреки прогноза',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 9,
        type: 'checkbox',
        text: 'В каком варианте ответа верно употреблены прописные и строчные буквы?',
        variants: [
            {
                id: 0,
                text: 'московская школа, Московский Кремль',
            },
            {
                id: 1,
                text: 'краевой бюджет, Магаданская Область',
            },
            {
                id: 2,
                text: 'поволжские немцы, поволжский регион',
            },
            {
                id: 3,
                text: 'государственное учреждение, Европейские страны',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 10,
        type: 'checkbox',
        text: 'В каком варианте ответа все слова употреблены правильно?',
        variants: [
            {
                id: 0,
                text: 'принимать к сведению',
            },
            {
                id: 1,
                text: 'неразрешимый тупик',
            },
            {
                id: 2,
                text: 'подвести результаты встречи',
            },
            {
                id: 3,
                text: 'оказать пользу',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 11,
        type: 'checkbox',
        text: 'В каком случае все слова пишутся с буквой А',
        variants: [
            {
                id: 0,
                text: 'заг_релый,  р_сти',
            },
            {
                id: 1,
                text: 'р_сток, р_сти',
            },
            {
                id: 2,
                text: 'оз_рение, р_сти',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 12,
        type: 'checkbox',
        text: 'Где необходимо раздельное написание НЕ- со словами?',
        variants: [
            {
                id: 0,
                text: 'Не_догадывался, вовсе не _интересный',
            },
            {
                id: 1,
                text: 'Не_запланированные расходы; не_подготовленный вовремя отчет',
            },
            {
                id: 2,
                text: 'вовсе не _интересный, не_запланированные расходы',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 13,
        type: 'checkbox',
        text: 'В каких словах необходимо вставить -НН-?',
        variants: [
            {
                id: 0,
                text: 'Стекля_ ый, реализова_ый  проект',
            },
            {
                id: 1,
                text: 'Стари_ый, плавле_ый  сырок',
            },
            {
                id: 2,
                text: 'изране_ый, управление образова_о  в 2007 году',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'Отметьте предложение без тавтологии (повтора)',
        variants: [
            {
                id: 0,
                text: 'Сегодня у нас в гостях друг из Свердловска',
            },
            {
                id: 1,
                text: 'Ресторан предлагает посетителям изысканные деликатесы из свежей рыбы',
            },
            {
                id: 2,
                text: 'Он рассказал нам о своих планах на будущее',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'Домой мы купили пять килограммов ................',
        variants: [
            {
                id: 0,
                text: 'апельсинов',
            },
            {
                id: 1,
                text: 'апельсин',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
]

export const secondVariant = [
    {
        id: 'variant',
        type: 'variant',
        variant: 2,
    },
    {
        id: 'timer',
        type: 'timer',
        hours: 0,
        minutes: 10,
        seconds: 0,
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'checkbox',
        text: 'Руководитель поставил свою ________________',
        variants: [
            {
                id: 0,
                text: 'Роспись',
            },
            {
                id: 1,
                text: 'Подпись',
            },
            {
                id: 2,
                text: 'Оба варианта верны',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 2,
        type: 'checkbox',
        text: 'В каком случае ударения поставлены верно?',
        variants: [
            {
                id: 0,
                text: 'сирОты, шАрфы, средствА',
            },
            {
                id: 1,
                text: 'сирОты, шАрфы, срЕдства',
            },
            {
                id: 2,
                text: 'сИроты, шАрфы, срЕдства',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 3,
        type: 'checkbox',
        text: 'В каком случае сочетания слов употреблены верно?',
        variants: [
            {
                id: 0,
                text: 'Мой день рождение',
            },
            {
                id: 1,
                text: 'Мой день рождения',
            },
            {
                id: 2,
                text: 'Оба варианта',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 4,
        type: 'checkbox',
        text: 'В каком варианте ответа правильно указаны цифры, на месте которых в предложении должны стоять запятые?',
        addedText: 'Трудности, которые испытывает семья (1) в том числе (2) связанные с экономическими проблемами (3) нередко (4) приводят к внутрисемейным конфликтам (5) в результате которых могут оказаться нарушенными права и законные интересы детей.',
        variants: [
            {
                id: 0,
                text: '1, 2, 3, 5',
            },
            {
                id: 1,
                text: '1, 3, 4, 5',
            },
            {
                id: 2,
                text: '1, 3, 5',
            },
            {
                id: 3,
                text: '1, 2, 5',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 5,
        type: 'checkbox',
        text: 'Отметьте предложение, в котором нет речевых ошибок:',
        variants: [
            {
                id: 0,
                text: 'Читая письмо, обратило на себя внимание большое количество стилистических ошибок',
            },
            {
                id: 1,
                text: 'Планируется пригласить двадцать один спортсмен',
            },
            {
                id: 2,
                text: 'Возникли определенные трудности, связанные  с доставкой стройматериалов',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 6,
        type: 'checkbox',
        text: 'В каком варианте ответа нет ошибок в употреблении предлогов?',
        variants: [
            {
                id: 0,
                text: 'благодаря трудолюбия',
            },
            {
                id: 1,
                text: 'согласно приказу',
            },
            {
                id: 2,
                text: 'по прибытию на место',
            },
            {
                id: 3,
                text: 'наперекор установленных требований',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 7,
        type: 'checkbox',
        text: 'В каком варианте ответа все слова употреблены правильно?',
        variants: [
            {
                id: 0,
                text: 'предпринимать усилия',
            },
            {
                id: 1,
                text: 'при нашем соучастии',
            },
            {
                id: 2,
                text: 'придавать глубокое значение',
            },
            {
                id: 3,
                text: 'аргументировать мнение',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [3],
    },
    {
        id: 8,
        type: 'checkbox',
        text: 'В каком варианте ответа верно употреблены прописные и строчные буквы?',
        variants: [
            {
                id: 0,
                text: 'органы государственной власти субъектов Российской Федерации',
            },
            {
                id: 1,
                text: 'высшие должностные лица Субъектов Российской Федерации',
            },
            {
                id: 2,
                text: 'Центральная избирательная Комиссия Российской Федерации',
            },
            {
                id: 3,
                text: 'руководители Фракций в Государственной Думе Федерального Собрания Российской',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 9,
        type: 'checkbox',
        text: 'Выберите вариант ответа, в котором двоеточие поставлено верно:',
        variants: [
            {
                id: 0,
                text: 'Мировая практика показывает: вокруг мегаполисов складываются районы малоэтажной застройки',
            },
            {
                id: 1,
                text: 'Не успеешь оглянуться: наступает день первой школьной линейки',
            },
            {
                id: 2,
                text: 'Считаю: что лучших управленцев должна знать вся страна',
            },
            {
                id: 3,
                text: 'У нас есть самое главное, в чём нуждается экономика: это профессиональные кадры и огромный рынок',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 10,
        type: 'checkbox',
        text: 'Выберите вариант ответа, в котором предложение построено без ошибок:',
        variants: [
            {
                id: 0,
                text: 'Уполномоченные мэром государственные органы самостоятельно образуют внебюджетные фонды и распоряжаются ими',
            },
            {
                id: 1,
                text: 'Подготовка охотников для истребления волков и лиц, ответственных за проведение этого мероприятия',
            },
            {
                id: 2,
                text: 'Глава местной администрации распределяет и управляет имуществом и финансами',
            },
            {
                id: 3,
                text: 'Реализация федеральной программы затрагивает как интересы Росстата, так и других министерств и ведомств',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 11,
        type: 'checkbox',
        text: 'В каком случае все слова пишутся с буквой И',
        variants: [
            {
                id: 0,
                text: 'он слыш_т,  пр_поднять',
            },
            {
                id: 1,
                text: 'пр_одолеть,  пр_зидент',
            },
            {
                id: 2,
                text: 'раз_грать,  пр_поднять',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 12,
        type: 'checkbox',
        text: 'Где необходимо раздельное написание НЕ- со словами?',
        variants: [
            {
                id: 0,
                text: 'не_смотря на трудности, никому не_интересно',
            },
            {
                id: 1,
                text: 'не_задумываясь о последствиях, не_исследованная область знаний',
            },
            {
                id: 2,
                text: 'отнюдь не_дорогой костюм, документ не_подписан',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 13,
        type: 'checkbox',
        text: 'В каких словах необходимо вставить -НН-?',
        variants: [
            {
                id: 0,
                text: 'чугу_ый, перевяза_ая рука',
            },
            {
                id: 1,
                text: 'осозна_ый  поступок, соловьи_ый',
            },
            {
                id: 2,
                text: 'соловьи_ый деревя_ый',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'Отметьте предложение без тавтологии (повтора)',
        variants: [
            {
                id: 0,
                text: 'Впервые мы познакомились на новогоднем вечере',
            },
            {
                id: 1,
                text: 'В  автобиографии вы не указали последнее место работы',
            },
            {
                id: 2,
                text: 'Сегодня получено информационное сообщение  о  приближающемся  урагане',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'Домой мы купили пять килограммов ................',
        variants: [
            {
                id: 0,
                text: 'помидоров',
            },
            {
                id: 1,
                text: 'помидор',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
]

export const thirdVariant = [
    {
        id: 'variant',
        type: 'variant',
        variant: 3,
    },
    {
        id: 'timer',
        type: 'timer',
        hours: 0,
        minutes: 10,
        seconds: 0,
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'checkbox',
        text: 'Отметьте правильный вариант написания:',
        addedText: 'Прошу … к награде',
        variants: [
            {
                id: 0,
                text: 'Представить',
            },
            {
                id: 1,
                text: 'Предоставить',
            },
            {
                id: 2,
                text: 'Оба варианта верны',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 2,
        type: 'checkbox',
        text: 'В каком случае ударения поставлены верно?',
        variants: [
            {
                id: 0,
                text: 'срЕдства, сливОвый, звонИт',
            },
            {
                id: 1,
                text: 'срЕдства, слИвовый, звонИт',
            },
            {
                id: 2,
                text: 'средствА, слИвовый, звОни',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 3,
        type: 'checkbox',
        text: 'В каком случае сочетания слов употреблены верно?',
        variants: [
            {
                id: 0,
                text: 'С днем рожденья',
            },
            {
                id: 1,
                text: 'С днем рождения',
            },
            {
                id: 2,
                text: 'Оба варианта',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 4,
        type: 'checkbox',
        text: 'В каком варианте ответа верно употреблены прописные и строчные буквы?',
        variants: [
            {
                id: 0,
                text: 'дирекция Мясокомбината, казанская школа',
            },
            {
                id: 1,
                text: 'Центральная Избирательная Комиссия Российской Федерации, Московский Кремль',
            },
            {
                id: 2,
                text: 'Великая Отечественная война, Московский Кремль',
            },
            {
                id: 3,
                text: 'казанская школа, президент России',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 5,
        type: 'checkbox',
        text: 'Отметьте предложение, в котором нет речевых ошибок:',
        variants: [
            {
                id: 0,
                text: 'Тридцать один специалист участвовал в этом совещании',
            },
            {
                id: 1,
                text: 'Текучесть кадров порождает недостаточное внимание администрации',
            },
            {
                id: 2,
                text: 'Принимая этот препарат, ваш вес уменьшится',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 6,
        type: 'checkbox',
        text: 'В каком варианте ответа правильно указаны цифры, на месте которых в предложении должны стоять запятые?',
        addedText: 'Валовой (1) внутренний продукт является тем показателем (2) на основании (3) которого производится (4) разделение стран на развитые и развивающиеся.',
        variants: [
            {
                id: 0,
                text: '1, 2',
            },
            {
                id: 1,
                text: '2',
            },
            {
                id: 2,
                text: '3, 4',
            },
            {
                id: 3,
                text: '3',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 7,
        type: 'checkbox',
        text: 'В каком варианте ответа нет ошибок в употреблении предлогов?',
        variants: [
            {
                id: 0,
                text: 'благодаря содействию',
            },
            {
                id: 1,
                text: 'доложить по приезду',
            },
            {
                id: 2,
                text: 'согласно договора',
            },
            {
                id: 3,
                text: 'вопреки прогноза',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 8,
        type: 'checkbox',
        text: 'В каком варианте ответа все слова употреблены правильно?',
        variants: [
            {
                id: 0,
                text: 'разъяснения о допущенных ошибках',
            },
            {
                id: 1,
                text: 'сдать вовремя благодаря четкой работы',
            },
            {
                id: 2,
                text: 'оперировать с точными данными',
            },
            {
                id: 3,
                text: 'подчеркнуть необходимость строительства',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [3],
    },
    {
        id: 9,
        type: 'checkbox',
        text: 'Выберите вариант ответа, в котором предложение построено без ошибок:',
        variants: [
            {
                id: 0,
                text: 'Сохраняя традиции нашей культуры, государство должно позаботиться и о тех, кто ищет новые пути в художественном творчестве',
            },
            {
                id: 1,
                text: 'Изучая проблемы городского транспорта, учеными были получены интересные результаты',
            },
            {
                id: 2,
                text: 'Ремонтные работы завершены досрочно, понимая важность объекта',
            },
            {
                id: 3,
                text: 'Иск удовлетворен, обязав ответчика заключить договор на условиях истца',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 10,
        type: 'checkbox',
        text: 'Выберите неправильный вариант:',
        variants: [
            {
                id: 0,
                text: 'у сотрудника Василия Мицкевича',
            },
            {
                id: 1,
                text: 'у сотрудницы Алины Мицкевич',
            },
            {
                id: 2,
                text: 'у сотрудника Василия Мицкевич',
            },
            {
                id: 3,
                text: 'разговор с Иваном Седых',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 11,
        type: 'checkbox',
        text: 'В каком случае все слова пишутся с буквой Е',
        variants: [
            {
                id: 0,
                text: 'ш_рты, пр_емник (последователь)',
            },
            {
                id: 1,
                text: 'стаж_р, небез_вестный',
            },
            {
                id: 2,
                text: 'она дремл_т, дел_гировать',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 12,
        type: 'checkbox',
        text: 'Где необходимо раздельное написание НЕ- со словами?',
        variants: [
            {
                id: 0,
                text: 'задача не_решена; виделись не_давно',
            },
            {
                id: 1,
                text: 'не_яркие, а блеклые краски; не_прочитанная мною книга',
            },
            {
                id: 2,
                text: 'не_регулируемый перекресток; задача не_решена',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 13,
        type: 'checkbox',
        text: 'В каких словах необходимо вставить -НН-?',
        variants: [
            {
                id: 0,
                text: 'Кожа_ый, ране_ый в руку',
            },
            {
                id: 1,
                text: 'Це_ый, перекраше_ый',
            },
            {
                id: 2,
                text: 'Жаре_ый картофель оловя_ый',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'Какое слово является антонимом к слову ЭФФЕКТИВНЫЙ:',
        variants: [
            {
                id: 0,
                text: 'безрезультатный',
            },
            {
                id: 1,
                text: 'плодотворный',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'Бабушка передала нам пять …………………… яблок',
        variants: [
            {
                id: 0,
                text: 'килограмм',
            },
            {
                id: 1,
                text: 'килограммов',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
]

export const fourthVariant = [
    {
        id: 'variant',
        type: 'variant',
        variant: 4,
    },
    {
        id: 'timer',
        type: 'timer',
        hours: 0,
        minutes: 10,
        seconds: 0,
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'checkbox',
        text: 'Отметьте правильный вариант написания:',
        addedText: 'Всем сотрудникам необходимо ____________  на медицинский осмотр',
        variants: [
            {
                id: 0,
                text: 'Прийти',
            },
            {
                id: 1,
                text: 'Придти',
            },
            {
                id: 2,
                text: 'Оба варианта верны',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 2,
        type: 'checkbox',
        text: 'В каком случае ударения поставлены верно?',
        variants: [
            {
                id: 0,
                text: 'тОрты, щАвель, латтЕ',
            },
            {
                id: 1,
                text: 'тОрты, щавЕль, лАтте',
            },
            {
                id: 2,
                text: 'тортЫ, щавЕль, латтЕ',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 3,
        type: 'checkbox',
        text: 'В каком случае сочетания слов употреблены верно?',
        variants: [
            {
                id: 0,
                text: 'Окончить школу',
            },
            {
                id: 1,
                text: 'Закончить школу',
            },
            {
                id: 2,
                text: 'Оба варианта',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 4,
        type: 'checkbox',
        text: 'В каком варианте ответа правильно указаны цифры, на месте которых в предложении должны стоять запятые?',
        addedText: 'Налоговая декларация представляется каждым налогоплательщиком (1) по каждому налогу (2) подлежащему уплате (3) этим налогоплательщиком (4) если иное не предусмотрено законодательством о налогах и сборах.',
        variants: [
            {
                id: 0,
                text: '2, 4',
            },
            {
                id: 1,
                text: '1, 2, 4',
            },
            {
                id: 2,
                text: '2, 3, 4',
            },
            {
                id: 3,
                text: '1, 2, 3, 4',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 5,
        type: 'checkbox',
        text: 'Отметьте предложение, в котором нет речевых ошибок:',
        variants: [
            {
                id: 0,
                text: 'Директор свое главное внимание уделял воспитательной работе',
            },
            {
                id: 1,
                text: 'Разрушения произошли за счет плохой работы системы водоснабжения',
            },
            {
                id: 2,
                text: 'Особое внимание на совещании было уделено проблемам молодежи',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 6,
        type: 'checkbox',
        text: 'В каком варианте ответа допущена ошибка в употреблении предлога?',
        variants: [
            {
                id: 0,
                text: 'действовать согласно указа',
            },
            {
                id: 1,
                text: 'действовать согласно указаниям',
            },
            {
                id: 2,
                text: 'прийти вопреки желанию',
            },
            {
                id: 3,
                text: 'одержать победу благодаря совету',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 7,
        type: 'checkbox',
        text: 'Выберите неправильный вариант:',
        variants: [
            {
                id: 0,
                text: 'у сотрудника Василия Мицкевича',
            },
            {
                id: 1,
                text: 'у сотрудника Алины Мицкевич',
            },
            {
                id: 2,
                text: 'у сотрудника Василия Мицкевич',
            },
            {
                id: 3,
                text: 'разговор с Иваном Седых',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 8,
        type: 'checkbox',
        text: 'В каком предложении вместо слова ПРЕДСТАВИТЬ нужно употребить ПРЕДОСТАВИТЬ?',
        variants: [
            {
                id: 0,
                text: 'Налоговая должна ПРЕДСТАВИТЬ вычет',
            },
            {
                id: 1,
                text: 'Адвокат будет ПРЕДСТАВЛЯТЬ Ваши интересы в суде',
            },
            {
                id: 2,
                text: 'Учебник ПРЕДСТАВЛЯЕТ собой комплексную подборку статей',
            },
            {
                id: 3,
                text: 'Гражданам ПРЕДСТАВИЛИ нового судью соревнований',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 9,
        type: 'checkbox',
        text: 'Выберите вариант ответа, в котором двоеточие поставлено верно:',
        variants: [
            {
                id: 0,
                text: 'Мировая практика показывает: вокруг мегаполисов складываются районы малоэтажной застройки',
            },
            {
                id: 1,
                text: 'Не успеешь оглянуться: наступает день первой школьной линейки',
            },
            {
                id: 2,
                text: 'Считаю: что лучших управленцев должна знать вся страна',
            },
            {
                id: 3,
                text: 'У нас есть самое главное, в чём нуждается экономика: это профессиональные кадры и огромный рынок',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 10,
        type: 'checkbox',
        text: 'В каком варианте ответа верно употреблены прописные и строчные буквы?',
        variants: [
            {
                id: 0,
                text: 'Французская Республика, Президент России',
            },
            {
                id: 1,
                text: 'Тульская область, Поволжские немцы',
            },
            {
                id: 2,
                text: 'Московский Театр оперетты, греческие города',
            },
            {
                id: 3,
                text: 'Красивый Шпиц, Великая отечественная война',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 11,
        type: 'checkbox',
        text: 'В каком случае все слова пишутся с буквой И',
        variants: [
            {
                id: 0,
                text: 'пр_одолеть, слово пиш_тся  по правилу',
            },
            {
                id: 1,
                text: 'пр_поднять, он слыш_т',
            },
            {
                id: 2,
                text: 'раз_грать, пр_болел',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 12,
        type: 'checkbox',
        text: 'Где необходимо раздельное написание НЕ- со словами?',
        variants: [
            {
                id: 0,
                text: 'не дорогая, а дешевая книга; совершенно неподготовленный доклад',
            },
            {
                id: 1,
                text: 'ушел, не купив ничего; никому не известно',
            },
            {
                id: 2,
                text: 'не разрезанный на части пирог; нек  онтролируемый  участок',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 13,
        type: 'checkbox',
        text: 'В каких словах необходимо вставить -НН-?',
        variants: [
            {
                id: 0,
                text: 'серебря_ый, сваре_ое мясо',
            },
            {
                id: 1,
                text: 'смороди_ый, стра_ый',
            },
            {
                id: 2,
                text: 'задание выполне_о, варе_ое  мясо',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'Какое слово является антонимом к слову СОВОКУПНОСТЬ:',
        variants: [
            {
                id: 0,
                text: 'разрозненность',
            },
            {
                id: 1,
                text: 'комплекс',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'В поездку пришлось взять с собой 2 пары …………………',
        variants: [
            {
                id: 0,
                text: 'чулок',
            },
            {
                id: 1,
                text: 'чулков',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
]

export const fifthVariant = [
    {
        id: 'variant',
        type: 'variant',
        variant: 5,
    },
    {
        id: 'timer',
        type: 'timer',
        hours: 0,
        minutes: 10,
        seconds: 0,
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'checkbox',
        text: 'Отметьте правильный вариант написания:',
        addedText: 'Вам необходимо ______________ справку',
        variants: [
            {
                id: 0,
                text: 'Представить',
            },
            {
                id: 1,
                text: 'Предоставить',
            },
            {
                id: 2,
                text: 'Оба варианта верны',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 2,
        type: 'checkbox',
        text: 'В каком случае ударения поставлены верно:',
        variants: [
            {
                id: 0,
                text: 'договОр, йОгурт, катАлог',
            },
            {
                id: 1,
                text: 'дОговор, йогУрт, катАлог',
            },
            {
                id: 2,
                text: 'договОр, йОгурт, каталОг',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 3,
        type: 'checkbox',
        text: 'В каком случае сочетания слов употреблены верно:',
        variants: [
            {
                id: 0,
                text: 'Мы не обращали внимание на людей',
            },
            {
                id: 1,
                text: 'Мы не обращали внимания на людей',
            },
            {
                id: 2,
                text: 'Оба варианта',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 4,
        type: 'checkbox',
        text: 'Отметьте предложение, в котором нет речевых ошибок:',
        variants: [
            {
                id: 0,
                text: 'Для участия в соревнованиях предполагается пригласить тридцать одного спортсмена',
            },
            {
                id: 1,
                text: 'Благодаря недостаточности освещения, многие растения гибнут',
            },
            {
                id: 2,
                text: 'По окончанию обучения  слушателям выдаются удостоверения',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 5,
        type: 'checkbox',
        text: 'В каком варианте ответа правильно указаны цифры, на месте которых в предложении должны стоять запятые?',
        addedText: "Государственные служащие (1) сознавая ответственность перед государством, обществом и гражданами (2) призваны исходить из того (3) что признание, соблюдение и защита (4) прав и свобод человека и гражданина определяют основной смысл и содержание деятельности (5) как государственных органов, так и государственных служащих",
        variants: [
            {
                id: 0,
                text: '1, 2, 3',
            },
            {
                id: 1,
                text: '1, 3, 4, 5',
            },
            {
                id: 2,
                text: '1, 3, 5',
            },
            {
                id: 3,
                text: '1, 2, 3, 5',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 6,
        type: 'checkbox',
        text: 'Выберите неправильный вариант:',
        variants: [
            {
                id: 0,
                text: 'отдал начальнику Антону Паламарчуку',
            },
            {
                id: 1,
                text: 'отдал специалисту Анне Паламарчук',
            },
            {
                id: 2,
                text: 'отдал специалисту Антону Паламарчук',
            },
            {
                id: 3,
                text: 'разговор с Василием Белых',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 7,
        type: 'checkbox',
        text: 'В каком варианте ответа допущена ошибка в употреблении предлога?',
        variants: [
            {
                id: 0,
                text: 'благодаря трудолюбию',
            },
            {
                id: 1,
                text: 'согласно приказу',
            },
            {
                id: 2,
                text: 'по прибытию на место',
            },
            {
                id: 3,
                text: 'наперекор установленным требованиям',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 8,
        type: 'checkbox',
        text: 'В каком варианте ответа верно употреблены прописные и строчные буквы?',
        variants: [
            {
                id: 0,
                text: 'дирекция мясокомбината, президент России',
            },
            {
                id: 1,
                text: 'Центральная Избирательная Комиссия Российской Федерации, дирекция мясокомбината',
            },
            {
                id: 2,
                text: 'Великая отечественная война, Московский Кремль',
            },
            {
                id: 3,
                text: 'казанская школа, Президент России',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [3],
    },
    {
        id: 9,
        type: 'checkbox',
        text: 'Выберите вариант ответа, в котором все слова пишутся слитно:',
        variants: [
            {
                id: 0,
                text: '(юго) западный, (причинно) следственный',
            },
            {
                id: 1,
                text: '(Санкт) Петербург, (железно) дорожный',
            },
            {
                id: 2,
                text: '(высоко) продуктивный, (военно) обязанный',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [2],
    },
    {
        id: 10,
        type: 'checkbox',
        text: 'Укажите словосочетания, в которых нарушены нормы сочетаемости слов:',
        variants: [
            {
                id: 0,
                text: 'предоставлять возможность',
            },
            {
                id: 1,
                text: 'уделить серьезное значение',
            },
            {
                id: 2,
                text: 'надеемся на сотрудничество',
            },
            {
                id: 3,
                text: 'сказать радушные слова',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 11,
        type: 'checkbox',
        text: 'В каком случае все слова пишутся с буквой Ы',
        variants: [
            {
                id: 0,
                text: 'дез_нформация, от_грать',
            },
            {
                id: 1,
                text: 'без_скусственный, под_скать',
            },
            {
                id: 2,
                text: 'меж_нститутский, вз_мать',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 12,
        type: 'checkbox',
        text: 'Где необходимо раздельное написание НЕ- со словами?',
        variants: [
            {
                id: 0,
                text: 'контрольная карточка не_закрыта; никем не_узнанный',
            },
            {
                id: 1,
                text: 'не_готов к сдаче в эксплуатацию; не_замеченная ошибка',
            },
            {
                id: 2,
                text: 'работа  не_завершена, не_глубокий овраг',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 13,
        type: 'checkbox',
        text: 'В каких словах необходимо вставить -НН-?',
        variants: [
            {
                id: 0,
                text: 'ответстве_ость; бракова_ый товар',
            },
            {
                id: 1,
                text: 'баклажа_ая; работа провере_а',
            },
            {
                id: 2,
                text: 'перереза_ый; черне_ое серебро',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'Какое слово является антонимом к слову ГЛУБОКИЙ?',
        variants: [
            {
                id: 0,
                text: 'основательный',
            },
            {
                id: 1,
                text: 'поверхностный',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [1],
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'В поездку пришлось взять с собой 2 пары теплых ………………',
        variants: [
            {
                id: 0,
                text: 'носков',
            },
            {
                id: 1,
                text: 'носок',
            },
        ],
        max: 1,
        isCheckable: true,
        answers: [0],
    },
]

export const sendInfo = {
    serviceId: 'service_2vgkcmo',
    templateId: 'template_rp0830p',
    publicKey: '5PaUlcmEnvZf61a3b',
}