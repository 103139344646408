import { useState, useCallback } from 'react'
import { inputsAnalysis } from '../helpers/inputsAnalysis'

export const useMotivation = (questions, onChange) => {
    let index = 0
    const [currentQuestion, setCurrentQuestion] = useState(questions[0])

    const next = useCallback((motivationId, questionId, value, clear) => {
        if (inputsAnalysis(value)) {
            onChange(motivationId, questionId, value)
            clear()
            index += 1
            setCurrentQuestion(questions[index])
        } else {
            alert('Корректно распределите 11 баллов')
        }
    }, [])

    return {
        currentQuestion,
        next,
    }
}