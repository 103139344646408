import { createSlice } from '@reduxjs/toolkit'

export const PathsAnalysisReducer = createSlice({
    name: 'pathsAnalysis',
    initialState: {
        pathsResult: [],
        stringResult: '',
    },
    reducers: {
        addPathResult: (state, action) => {
            state.pathsResult.push(action.payload)
        },
        cleanAll: (state) => {
            state.pathsResult = []
        },
        addToStringResult: (state, action) => {
            state.stringResult += action.payload
        },
    },
})

export const { addPathResult, cleanAll, addToStringResult } = PathsAnalysisReducer.actions

export default PathsAnalysisReducer.reducer