import React from 'react'

import TableHeader from './TableHeader'
import TableRow from './TableRow'

const Table = ({ name, headers, rows }) => {

    return (
        <table className='width-two-thirds margin-inline-auto table'>
            <caption className='margin-bottom font-weight-bold'>{name}</caption>
            <thead>
                <TableHeader headers={headers} />
            </thead>
            <tbody>
                {
                    rows.map((row) => (
                        <TableRow key={row.id} columns={row.columns} />
                    ))
                }
            </tbody>
        </table>
    )
}

export default Table