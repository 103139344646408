import React from 'react'
import { excelParser, csvParser } from '../helpers/parsers'
import { getExtension } from '../helpers/getExtension'
import { splitAnswersArray } from '../helpers/splitUsersArray'

const AddFile = ({ isEmptyObject, setObject }) => {

    const uploadFileHandler = (event) => {
        const file = event.target.files[0]

        const fileName = file.name

        if (getExtension(fileName) === 'csv') {
            csvParser(file)
                .then(value => splitAnswersArray(value.data))
                .then(result => setObject(result))
        } else if (getExtension(fileName) === 'xls' || getExtension(fileName) === 'xlsx') {
            excelParser(file)
        }
        
    }

    return (
        <section className={`${isEmptyObject ? '' : 'element-hidden'} width-full display-flex margin-top-huge align-center justify-content-center`}>
            <form className='width-one-fifths display-flex justify-content-center'>
	            <label className='excelInputLabel'>
                    <input className='excelInput' type='file' accept=".xls,.xlsx,.csv" onChange={uploadFileHandler} />	
	   	            <span className='excelInputSpan'>Выберите файл</span>
 	            </label>
            </form>
        </section>
    )
}

export default AddFile