import Navbar from '../components/Navbar'
import Form from '../components/Form/Form'

import { getRandomRussianTestVariant } from '../helpers/getRandomVariant'
import { sendInfo } from '../data/russianTestData'

import { russianTestAnalysis } from '../helpers/analysis'

const RussianTest = () => {
  const formData = getRandomRussianTestVariant()

  return (
    <div className="background">
      <Navbar />

      <Form formData={formData} sendInfo={sendInfo} analysis={russianTestAnalysis} />

    </div>
  );
}

export default RussianTest;