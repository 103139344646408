import React from 'react'

const MyCheckbox = ({ questionId, variantId, text, max, value, onChange }) => {
    return (
        <label className="my-checkbox-label">
            <input className="my-checkbox visually-hidden" type="checkbox" checked={value.includes(variantId) ? true : false} onChange={() => onChange(questionId, variantId, max)} />

            <span className="my-checkbox-custom"></span>

            <span className="my-checkbox__text">{text}</span>
        </label>
    )
}

export default React.memo(MyCheckbox)