import React from 'react'

import { formAnalysis } from '../helpers/analysis'

import { formData, sendInfo } from '../data/experienceAnalysisData'
import ExperienceAnalysisInformation from '../components/UI/InformationComponents/ExperienceAnalysisInformation'
import StartComponent from '../components/UI/StartComponent'

const ExperienceAnalysisPage = () => {
  return (
    <div className="margin-top-large">
      <StartComponent information={ <ExperienceAnalysisInformation /> } formData={formData} sendInfo={sendInfo} analysis={formAnalysis} />
    </div>
  )
}

export default React.memo(ExperienceAnalysisPage)