import {useDispatch, useSelector} from 'react-redux'

import {setStartTime} from '../orgtestruk/features/counter/PersonReducer'

import {Person, MainInfo, Slider, RouteList, Planner, Confirm, Finish} from '../orgtestruk/components'

import MediaQuery from 'react-responsive'

import Navbar from '../components/Navbar'

const OrgTestRuk = () => {

  const dispatch = useDispatch()

  dispatch(setStartTime(new Date().toString()))

  const isFinished = useSelector((state) => state.person.isFinished)

  return (

    <div className="background">
        <MediaQuery minWidth={1640}>
          <div className={`${isFinished === false ? '' : 'element-hidden'}`}>
            <Navbar />
            <Person />
            <MainInfo />
            <Slider />
            <Planner />
            <RouteList />
            <Confirm />
          </div>

          <div className={`${isFinished === true ? '' : 'element-hidden'}`}>
            <Finish />
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1639}>
          <Navbar />
          <p className="width-one-third margin-inline-auto text-justify-center padding-all border-main border-radius background-color-white">Мобильная версия не поддерживается</p>
        </MediaQuery>
    </div>
  );
}

export default OrgTestRuk;