import {configureStore} from '@reduxjs/toolkit'

import PersonReducer from '../features/counter/PersonReducer'
import RouteReducer from '../features/counter/RouteReducer'
import PlannerReducer from '../features/counter/PlannerReducer'

export default configureStore({
    reducer: {
        person: PersonReducer,
        route: RouteReducer,
        planner: PlannerReducer,
    }
})