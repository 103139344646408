import React from 'react'

const TableHeader = ({ headers }) => {
    return (
        <tr>
            {
                headers.map((header) => (
                    <th className='td blueBg whiteText' key={header.id}>{header.text}</th>
                ))
            }
        </tr>
    )
}

export default TableHeader