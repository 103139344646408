import { createSlice } from '@reduxjs/toolkit'

export const AfterTaskOneReducer = createSlice({
    name: 'afterTaskOne',
    initialState: {
        checkedValue: false,
        checkedValueString: '',
        missingValue: '',
        descriptionValue: '',
    },
    reducers: {
        changeCheckedValue: (state) => {
            state.checkedValue = !state.checkedValue
        },
        changeCheckedValueString: (state) => {
            if (state.checkedValue === true) {
                state.checkedValueString = 'Все пункты посещены'
            } else {
                state.checkedValueString = 'Не все пункты посещены'
            }
        },
        setMissingValue: (state, action) => {
            state.missingValue = action.payload
        },
        setDescriptionValue: (state, action) => {
            state.descriptionValue = action.payload
        },
    }
})

export const { changeCheckedValue, changeCheckedValueString, setMissingValue, setDescriptionValue} = AfterTaskOneReducer.actions

export default AfterTaskOneReducer.reducer