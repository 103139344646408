import {React, useState} from 'react'

import {FiArrowRight, FiArrowLeft} from 'react-icons/fi'

import SliderPresentation from './SliderPresentation'

import {slider} from '../../data/data'

const Slider = () => {

    const length = slider.length

    const [active, setActive] = useState(1)

    const next = () => {
        if (active === length) {
            setActive(1)
        } else if (active < length) {
            setActive(active + 1)
        } else {
            setActive(length - 1)
        }
    }

    const prev = () => {
        if (active === 1) {
            setActive(length)
        } else if (active > 1) {
            setActive(active - 1)
        } else {
            setActive(length - 1)
        }
    }

    return (
        <div className="width-full margin-top-huge">
            {
                slider.map((slide) => (
                    <SliderPresentation key={slide.id} active={active} id={slide.id} greeting={slide.greeting} text={slide.text}from={slide.from} />
                ))
            }

            <div className="margin-top-large justify-content-center display-flex flex-direction-row hugeText">
                <button onClick={prev} className="margin-right arrowButton"><FiArrowLeft /></button>
                <button onClick={next} className="arrowButton"><FiArrowRight /></button>
            </div>
        </div>
    )
}

export default Slider