import { createAgreement, createCheckboxParagraph, createDateParagraph, createMotTable, createRussianTestTable, createStringParagraph, createTable, createMotivationProfileTable, createMultipleCheckboxParagraph } from '../helpers/creators'

import { motKeysArray } from '../data/motData'
import { motivationProfileKeys } from '../data/motivationProfileData'
import { cleanCheckboxArray } from './checkboxArray'

export const formAnalysis = (inputs) => {

    let resultString = '<div>'

    for (let i = 0; i < inputs.length; i++) {
        const current = inputs[i]
        const currentType = current.type

        if (currentType === 'bottomInput' || currentType === 'simpleInput' || currentType === 'textarea' || currentType === 'select') {
            resultString += createStringParagraph(current)
        }

        if (currentType === 'checkbox') {
            resultString += createCheckboxParagraph(current)
        }

        if (currentType === 'multiCheckbox') {
            resultString += createMultipleCheckboxParagraph(current)
        }

        if (currentType === 'table') {
            resultString += createTable(current)
        }

        if (currentType === 'checkboxAgreement') {
            resultString += createAgreement(current)
        }
    }

    const date = createDateParagraph()

    resultString += `${date}</div>`

    const nameInput = inputs.find(input => input.id === 0)

    return {
        name: nameInput.value,
        result: resultString,
    }
}

const filterCheckboxInputs = (inputs) => {
    return inputs.filter(input => input.type === 'checkbox')
}

const getNameValue = (inputs) => {
    const nameInput = inputs.filter(input => input.id === 0)

    return nameInput[0].value
}

export const getVariant = (inputs) => {
    const filtered = inputs.filter(input => input.id === 'variant')

    return filtered[0].variant
}

export const createMark = (rightAnswers, filtered) => {
    const percentage = rightAnswers / filtered.length

    if (percentage === 1) {
        return 10
    } else if (percentage >= 0.81 && percentage <= 0.9) {
        return 9
    } else if (percentage === 0.8) {
        return 8
    } else if (percentage >= 0.7 && percentage <= 0.79) {
        return 7
    } else if (percentage >= 0.61 && percentage <= 0.69) {
        return 6
    } else if (percentage === 0.6) {
        return 5
    } else if (percentage >= 0.5 && percentage <= 0.59) {
        return 4
    } else if (percentage >= 0.41 && percentage <= 0.49) {
        return 3
    } else if (percentage <= 0.40) {
        return 2
    }
}

export const motAnalysis = (inputs) => {
    const filtered = filterCheckboxInputs(inputs)
    const name = getNameValue(inputs)
    let result = [0, 0, 0, 0, 0]

    for (let input of filtered) {
        if (input?.isCalculable) {
            let answers = cleanCheckboxArray(input.value)
            let keys = motKeysArray[input.id]

            for (let a = 0; a < answers.length; a++) {
                let answer = answers[a]

                for (let k = 0; k < keys.length; k++) {
                    if (keys[k].includes(answer + 1)) {
                        result[k] = result[k] + 1
                    }
                }
            }
        }
    }

    const resultString = createMotTable(result)

    return {
        name,
        result: resultString,
    }
}

export const russianTestAnalysis = (inputs) => {
    const filtered = filterCheckboxInputs(inputs)
    const name = getNameValue(inputs)
    const results = {}

    let rightAnswers = filtered.length

    for (let input of filtered) {
        if (input?.isCheckable) {
            let object
            const value = cleanCheckboxArray(input.value)
            if (value.length !== 0) {
                const usersAnswerId = value[0]

                const usersAnswerText = input.variants[usersAnswerId].text

                object = {
                    variant: usersAnswerText,
                    isRight: true,
                }

                if (!input.answers.includes(usersAnswerId)) {
                    object.isRight = false
                    rightAnswers--
                }
            } else {
                object = {
                    variant: '',
                    isRight: false,
                }

                rightAnswers--
            }

            results[input.id] = object
        }

    }

    const variant = getVariant(inputs)
    const mark = createMark(rightAnswers, filtered)

    const resultString = createRussianTestTable(variant, results, rightAnswers, mark)

    return {
        name,
        result: resultString,
    }
}

const getMotivationAnswers = (inputs) => {
    const filtered = inputs.filter(input => input.type === 'motivation')

    return filtered[0].value
}

export const motivationProfileAnalysis = (inputs) => {
    const name = getNameValue(inputs)
    const answers = getMotivationAnswers(inputs)
    const result = {}

    for (let i = 0; i < Object.keys(answers).length; i++) {
        const currentAnswers = answers[i]
        const currentKeys = motivationProfileKeys[i]

        for (let k = 0; k < currentKeys.length; k++) {
            const currentAnswer = currentAnswers[k]
            const currentKey = currentKeys[k]

            if (result[currentKey] === undefined) {
                result[currentKey] = 0
            }

            result[currentKey] += Number(currentAnswer)
        }
    }

    const resultString = createMotivationProfileTable(result)

    return {
        name,
        result: resultString,
    }
}