const getHeaders = (allHeaders) => {
    const usersHeaders = [
        allHeaders[0],
        allHeaders[2],
    ]

    return {
        usersHeaders,
    }
}

const getUserArray = (user) => {

    const userArray = user.split(';')

    return userArray
}

const getCards = (answersArray) => {

    const usersCards = []

    for (let i = 2; i < answersArray.length - 1; i++) {

        const currentAnswer = answersArray[i]

        const userInfo = {
            userId: currentAnswer[0],
            userName: currentAnswer[2],
        }

        const firstQuestion = {
            questionId: 1,
            questionText: replaceAllInText(currentAnswer[14]),
            questionAnswer: replaceAllInText(currentAnswer[16]),
            questionStatus: currentAnswer[18],
        }

        const secondQuestion = {
            questionId: 2,
            questionText: replaceAllInText(currentAnswer[20]),
            questionAnswer: replaceAllInText(currentAnswer[22]),
            questionStatus: currentAnswer[24],
        }

        const thirdQuestion = {
            questionId: 3,
            questionText: replaceAllInText(currentAnswer[26]),
            questionAnswer: replaceAllInText(currentAnswer[28]),
            questionStatus: currentAnswer[30],
        }

        const fourthQuestion = {
            questionId: 4,
            questionText: replaceAllInText(currentAnswer[32]),
            questionAnswer: replaceAllInText(currentAnswer[34]),
            questionStatus: currentAnswer[36],
        }

        const fifthQuestion = {
            questionId: 5,
            questionText: replaceAllInText(currentAnswer[38]),
            questionAnswer: replaceAllInText(currentAnswer[40]),
            questionStatus: currentAnswer[42],
        }

        const answerList = [firstQuestion, secondQuestion, thirdQuestion, fourthQuestion, fifthQuestion]

        const answerInfo = {
            answerStatus: currentAnswer[11],
            answerList: answerList,
        }


        const card = {
            cardId: i,
            userInfo: userInfo,
            answerInfo: answerInfo,
        }

        usersCards.push(card)

    }

    return usersCards
}

const replaceAllInText = (text) => {
    return text ? text.replaceAll('SUCCESS', 'ЗАВЕРШЕН').replaceAll('IN_PROGRESS', 'В ПРОЦЕССЕ').replaceAll('#', '').replaceAll('Текст:', '') : ''
}

export const splitAnswersArray = (answersArray) => {
    const mainHeader = answersArray[0]

    const answersHeaders = getHeaders(answersArray[1])

    const usersCards = getCards(answersArray)

    return {
        mainHeader,
        answersHeaders,
        usersCards,
    }
}