import Form from '../components/Form/Form'

import { formData, sendInfo } from '../data/motivationProfileData'
import { motivationProfileAnalysis } from '../helpers/analysis'

const MotivationPage = () => {

  return (
    <div className="motivation-container">
      <Form formData={formData} sendInfo={sendInfo} analysis={motivationProfileAnalysis} />
    </div>
  )
}

export default MotivationPage