export const formData = [
    {
        id: 'motParagraph',
        type: 'textParagraph',
        header: 'Информация',
        text: 'Внимательно прочитайте вопрос и все варианты ответов, которые для него приведены. В каждом вопросе есть указание, сколько вариантов ответа Вы можете дать. Большинство вопросов требует дать только один или два варианта ответа.',
    },
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'checkbox',
        text: 'Что Вы больше всего цените в своей работе? Можете дать один или два варианта ответа:',
        variants: [
            {
                id: 0,
                text: 'то, что я в основном сам решаю, что и как мне делать',
            },
            {
                id: 1,
                text: 'то, что она дает мне возможность проявить то, что я знаю и умею',
            },
            {
                id: 2,
                text: 'то, что я чувствую себя полезным и нужным',
            },
            {
                id: 3,
                text: 'то, что мне за неё относительно неплохо платят',
            },
            {
                id: 4,
                text: 'особенно ничего не ценю, но эта работа мне хорошо знакома и привычна',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 2,
        type: 'checkbox',
        text: 'Какое выражение из перечисленных ниже Вам подходит более всего? Дайте только один ответ',
        variants: [
            {
                id: 0,
                text: 'я хочу обеспечить своим трудом себе и своей семье приличный доход',
            },
            {
                id: 1,
                text: 'в своей работе я полный хозяин',
            },
            {
                id: 2,
                text: 'у меня достаточно знаний и опыта, чтобы справиться с любыми трудностями в моей работе',
            },
            {
                id: 3,
                text: 'я ценный, незаменимый для организации работник',
            },
            {
                id: 4,
                text: 'я всегда выполняю то, что от меня требуют',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 3,
        type: 'checkbox',
        text: 'Как Вы предпочитаете работать? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'предпочитаю делать то, что знакомо, привычно',
            },
            {
                id: 1,
                text: 'нужно, чтобы в работе постоянно появлялось что-то новое, чтобы не стоять на месте',
            },
            {
                id: 2,
                text: 'чтобы было точно известно, что нужно сделать и что я за это получу',
            },
            {
                id: 3,
                text: 'предпочитаю работать под полную личную ответственность',
            },
            {
                id: 4,
                text: 'готов(а) делать все, что нужно для организации',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 4,
        type: 'checkbox',
        text: 'Допустим, Вам предлагают другую работу в Вашей организации. При каких условиях Вы бы на это согласились? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'если предложат зарплату выше',
            },
            {
                id: 1,
                text: 'если другая работа будет более творческой и интересной, чем нынешняя',
            },
            {
                id: 2,
                text: 'если новая работа даст мне больше самостоятельности',
            },
            {
                id: 3,
                text: 'если это очень нужно для организации',
            },
            {
                id: 4,
                text: 'при всех обстоятельствах я предпочел (предпочла) бы остаться на той работе, к которой привык (привыкла)',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 5,
        type: 'checkbox',
        text: 'Попробуйте определить, что для Вас означает Ваш заработок? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'плата за время и усилия, потраченные на выполнение работы',
            },
            {
                id: 1,
                text: 'это прежде всего плата за мои знания, квалификацию',
            },
            {
                id: 2,
                text: 'оплата за общие результаты деятельности организации',
            },
            {
                id: 3,
                text: 'мне нужен гарантированный заработок - пусть небольшой, но чтобы он был',
            },
            {
                id: 4,
                text: 'какой бы он ни был, я его заработал(а) сам(а)',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 6,
        type: 'checkbox',
        text: 'Заработная плата и премии',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 7,
        type: 'checkbox',
        text: 'Доплаты за квалификацию',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 8,
        type: 'checkbox',
        text: 'Доплаты за тяжелые и вредные условия работы',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 9,
        type: 'checkbox',
        text: 'Социальные выплаты и льготы, пособия',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 10,
        type: 'checkbox',
        text: 'Доходы от капитала, акций',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 11,
        type: 'checkbox',
        text: 'Любые дополнительные подработки',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 12,
        type: 'checkbox',
        text: 'Подработки, но не любые, а только по своей специальности',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 13,
        type: 'checkbox',
        text: 'Доходы от личного хозяйства, дачного участка и т.п.',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 14,
        type: 'checkbox',
        text: 'Выигрыш в лотерею, казино и т.п.',
        variants: [
            {
                id: 0,
                text: 'очень важно',
            },
            {
                id: 1,
                text: 'не очень важно',
            },
            {
                id: 2,
                text: 'совсем не важно',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 15,
        type: 'checkbox',
        text: 'На каких принципах, по-вашему, должны строиться отношения между работником и организацией? Дайте только один ответ',
        variants: [
            {
                id: 0,
                text: 'работник должен относиться к организации как к своему дому, отдавать ей все и вместе переживать трудности и подъемы. Организация должна соответственно оценивать преданность и труд работника',
            },
            {
                id: 1,
                text: 'работник продает организации свой труд, и если ему не дают хорошую цену, он вправе найти другого покупателя',
            },
            {
                id: 2,
                text: 'работник приходит в организацию для самореализации и относится к ней как к месту реализации своих способностей. Организация должна обеспечивать работнику такую возможность, извлекать из этого выгоду для себя и на этой основе развиваться',
            },
            {
                id: 3,
                text: 'работник тратит на организацию свои силы, а организация должна взамен гарантировать ему зарплату и социальные блага',
            },
        ],
        max: 1,
        isCalculable: true,
    },
    {
        id: 16,
        type: 'checkbox',
        text: 'Как Вы считаете, почему в процессе работы люди проявляют инициативу, вносят различные предложения? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'чувствуют особую ответственность за свою работу',
            },
            {
                id: 1,
                text: 'из-за стремления реализовать свои знания и опыт, выйти за установленные работой рамки',
            },
            {
                id: 2,
                text: 'чаще всего из-за желания улучшить работу своей организации',
            },
            {
                id: 3,
                text: 'просто хотят выделиться или завоевать расположение начальства',
            },
            {
                id: 4,
                text: 'хотят заработать, поскольку всякая полезная инициатива должна вознаграждаться',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 17,
        type: 'checkbox',
        text: 'Какое суждение о коллективной работе Вам ближе? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'коллектив для меня очень важен, одному хороших результатов не добиться',
            },
            {
                id: 1,
                text: 'предпочитаю работать автономно, но чувствую себя так же хорошо, когда работаю вместе с интересными людьми',
            },
            {
                id: 2,
                text: 'мне нужна свобода действий, а коллектив чаще всего эту свободу ограничивает',
            },
            {
                id: 3,
                text: 'можно работать и в коллективе, но платить должны по личным результатам',
            },
            {
                id: 4,
                text: 'мне нравится работать в коллективе, так как там я среди своих',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 18,
        type: 'checkbox',
        text: 'Представьте, что у Вас появилась возможность стать владельцем Вашей организации. Воспользуетесь ли Вы этой возможностью? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'да, так как я смогу участвовать в управлении организацией',
            },
            {
                id: 1,
                text: 'да, потому что это может увеличить мой доход',
            },
            {
                id: 2,
                text: 'да, так как настоящий работник должен быть совлпадельцем',
            },
            {
                id: 3,
                text: 'вряд ли: на заработке это не скажется, участие в управлении меня не интересует, а работе это помешает',
            },
            {
                id: 4,
                text: 'нет, не нужны мне лишние заботы',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 19,
        type: 'checkbox',
        text: 'Представьте, что Вы сейчас ищете работу. Вам предлагают несколько вариантов. Какой из них Вы выберете? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'наиболее интересную, творческую работу',
            },
            {
                id: 1,
                text: 'наиболее самостоятельную, независимую работу',
            },
            {
                id: 2,
                text: 'за которую больше платят',
            },
            {
                id: 3,
                text: 'чтобы за не слишком большие деньги не требовалось особенно "надрываться"',
            },
            {
                id: 4,
                text: 'не могу представить, что я уйду из нашей организации',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 20,
        type: 'checkbox',
        text: 'Что Вы прежде всего учитываете, когда оцениваете успехи другого работника в Вашей организации? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'его зарплату, доходы, материальное положение',
            },
            {
                id: 1,
                text: 'уровень его профессионализма, квалификации',
            },
            {
                id: 2,
                text: 'насколько хорошо он "устроился"',
            },
            {
                id: 3,
                text: 'насколько его уважают в организации',
            },
            {
                id: 4,
                text: 'насколько он самостоятелен, независим',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 21,
        type: 'checkbox',
        text: 'Если положение в Вашей организации ухудшится, на какие перемены в Вашей работе и положении Вы согласитесь ради того, чтобы остаться на работе? Можете дать сколько угодно ответов',
        variants: [
            {
                id: 0,
                text: 'освоить новую профессию',
            },
            {
                id: 1,
                text: 'работать неполный рабочий день или перейти на менее квалифицированную работу и меньше получать',
            },
            {
                id: 2,
                text: 'перейти на менее удобный режим работы',
            },
            {
                id: 3,
                text: 'работать более интенсивно',
            },
            {
                id: 4,
                text: 'соглашусь просто терпеть, потому что деваться некуда',
            },
            {
                id: 5,
                text: 'скорее всего я просто уйду из этой организации',
            },
        ],
        max: 0,
        isCalculable: true,
    },
    {
        id: 22,
        type: 'checkbox',
        text: 'Если Вы руководитель, то что Вас привлекает прежде всего в этом положении? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'возможность принимать самостоятельные, ответственные решения',
            },
            {
                id: 1,
                text: 'возможность принести наибольшую пользу организации',
            },
            {
                id: 2,
                text: 'высокий уровень оплаты',
            },
            {
                id: 3,
                text: 'возможность организовывать работу других людей',
            },
            {
                id: 4,
                text: 'возможность наилучшим образом применить свои знания и умения',
            },
            {
                id: 5,
                text: 'ничего особенно не привлекает, за положение руководителя не держусь',
            },
        ],
        max: 2,
        isCalculable: true,
    },
    {
        id: 23,
        type: 'checkbox',
        text: 'Если Вы не являетесь руководителем, то хотели бы Вы им стать? Можете дать один или два варианта ответа',
        variants: [
            {
                id: 0,
                text: 'да, поскольку это даст возможность принимать самостоятельные, ответственные решения',
            },
            {
                id: 1,
                text: 'не против, если это нужно для пользы дела',
            },
            {
                id: 2,
                text: 'да, так как при этом я смогу лучше применить свои знания и умения',
            },
            {
                id: 3,
                text: 'да, если это будет должным образом оплачиваться',
            },
            {
                id: 4,
                text: 'нет, профессионал может отвечать только за самого себя',
            },
            {
                id: 5,
                text: 'нет, руководство меня не привлекает, а хорошо заработать я могу и на своем месте',
            },
            {
                id: 6,
                text: 'да, чем я хуже других',
            },
            {
                id: 7,
                text: 'нет, это слишком большая нагрузка для меня',
            },
        ],
        max: 2,
        isCalculable: true,
    },
]

export const sendInfo = {
    serviceId: 'service_21gr3nn',
    templateId: 'template_8u5pfsv',
    publicKey: 'b_xhwTPd5JUj2cv8C',
}

export const motKeysArray = [
    [],
    [
        [4], [2], [3], [1], [5],
    ],
    [
        [1], [3], [4], [2], [5],
    ],
    [
        [3], [2], [5], [4], [1],
    ],
    [
        [1], [2], [4], [3], [5],
    ],
    [
        [1], [2], [3], [5], [4],
    ],
    [
        [1], [], [], [], [],
    ],
    [
        [1], [1], [3], [3], [],
    ],
    [
        [1], [3], [3], [], [1],
    ],
    [
        [], [3], [], [], [1],
    ],
    [
        [3], [3], [3], [1], [3],
    ],
    [
        [1], [], [3], [], [1],
    ],
    [
        [1], [1], [], [3], [3],
    ],
    [
        [], [], [], [1], [],
    ],
    [
        [3], [3], [], [], [1],
    ],
    [
        [2], [3], [1], [1], [4],
    ],
    [
        [5], [2], [3], [1], [4],
    ],
    [
        [4], [2], [1], [3], [5],
    ],
    [
        [2], [4], [1], [3, 1], [5],
    ],
    [
        [3], [1], [5], [2], [4],
    ],
    [
        [1], [2], [4], [5], [3],
    ],
    [
        [4, 6], [1, 4, 7], [1, 2, 4, 5], [1, 2, 4], [2, 5],
    ],
    [
        [3, 6], [5], [2], [1], [4],
    ],
    [
        [4, 6], [3, 5], [2], [1, 3], [7, 8],
    ],
]

export const motColumns = [
    'Инструментальный тип',
    'Профессиональный тип',
    'Патриотический тип',
    'Хозяйский тип',
    'Люмпензированный тип',
]