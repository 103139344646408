import React from 'react'

const Greeting = () => {
  return (
    <div className="width-two-thirds margin-inline-auto padding-all-large margin-bottom-large text-justify-full">
        <p className='text-indent margin-bottom-large'>Добрый день! Перед Вами задание, успешное решение которого показывает, как Вы работаете в условиях многозадачности, с большим потоком неструктурированной информации и при ограниченном ресурсе времени.</p>
        <p className='text-indent margin-bottom-large'>Деятельность в Исполнительном комитете на любой позиции предполагает работу подобного рода, поэтому нам важны результаты выполнения этого задания.</p>
        <p className='text-indent'>Ниже представлены подробные условия задания, прочитайте внимательно всю информацию:</p>
        <p className='text-indent-large'>- описание ситуации;</p>
        <p className='text-indent-large'>- список участников (ближайшee окружение);</p>
        <p className='text-indent-large margin-bottom-large'>- карточки с сообщениями. Всего 14 записок!</p>
        <p className='text-indent margin-bottom-large'>Обратите внимание, при выполнении задания вы столкнетесь с временными конфликтами, когда задачи приходятся на одно и тоже время. Если Вы увидели накладки по времени, то внесите ваш вариант решения в ежедневник в графу «решение».</p>
        <p className='text-indent'>Желаем успешного выполнения!</p>
    </div>
  )
}

export default Greeting