import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useInitForm } from './hooks/useInitForm'
import { getInputs } from './helpers/getInputs'

import FormPresentation from './FormPresentation'

const Form = ({ formData, sendInfo, analysis }) => {
    const { data } = useSelector(state => state.form)

    const initForm = useInitForm(formData)

    const [inputs, setInputs] = useState([])

    useEffect(() => {
        initForm()
    }, [initForm])

    useEffect(() => {
        const inputs = getInputs(formData, data)

        setInputs(inputs)
    }, [data, formData])

    return (
        <div className="_container _mainText">
            {
                inputs.length !== 0 ?

                    <FormPresentation inputs={inputs} sendInfo={sendInfo} analysis={analysis} />

                :

                    null
            }
        </div>
    )
}

export default React.memo(Form)