import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'


import React, { Profiler } from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'

import './styles/UI/MyBottomInput.css'
import './styles/UI/MyTextarea.css'
import './styles/UI/MyCheckbox.css'
import './styles/UI/MySelect.css'
import './styles/UI/MyTable.css'
import './styles/UI/MyButton.css'
import './styles/UI/MyInput.css'
import './styles/UI/MyPopup.css'
import './styles/UI/TextParagraph.css'
import './styles/UI/Timer.css'

import './styles/CheckboxQuestion.css'

import './styles/UI/SendButton.css'
import './styles/FinishPage.css'

import './styles/main.css'
import './styles/app.css'
import './styles/motivation.css'
import './styles/form.css'
import './styles/managerForm.css'
import './styles/orgtest.css'
import './styles/orgtestruk.css'
import './styles/table.css'

import './styles/Navbar.css'

import './styles/excelParser.css'

import './styles/professional.css'

import './styles/modifications.css'

import './styles/information.css'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)