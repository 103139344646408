import React from 'react'

import { Provider } from 'react-redux'

import { Routes, Route } from 'react-router-dom'

import { StartPage, AnotherReserveForm, CityDevelopment, DirectorForm, ExcelParser, FormPage, ManagerForm, Mot, MotivationPage, OrgTest, OrgTestRuk, QuestionnaireBelgorodPage, QuestionnarieKAIPage, ReserveForm, RussianTest, TablePage, Zags, FinishPage, ActivityAnalyticsPage } from './pages/index.js'

import orgTestStore from './orgtest/app/store'
import orgTestRukStore from './orgtestruk/app/store'

import mainStore from './store/store'
import ExperienceAnalysisPage from './pages/ExperienceAnalysisPage.jsx'

const App = () => {
  return (
    <div className='mainText'>
      <Routes>
        <Route path="/" element={<StartPage />} />

        <Route path="/table" element={<TablePage />} />

        <Route path="/excelParser" element={<ExcelParser />} />

        <Route path="/finish" element={<FinishPage />} />

        <Route path="/orgtest" element={<Provider store={orgTestStore}><OrgTest /></Provider>} />
        <Route path="/form" element={<Provider store={mainStore}><FormPage /></Provider>} />
        <Route path="/motivationProfile" element={<Provider store={mainStore}><MotivationPage /></Provider>} />
        <Route path="/russianTest" element={<Provider store={mainStore}><RussianTest /></Provider>} />
        <Route path="/orgtestruk" element={<Provider store={orgTestRukStore}><OrgTestRuk /></Provider>} />
        <Route path="/managerForm" element={<Provider store={mainStore}><ManagerForm /></Provider>} />

        <Route path='/reserveForm' element={<Provider store={mainStore}><ReserveForm /></Provider>} />

        <Route path='/cityDevelopment' element={<Provider store={mainStore}><CityDevelopment /></Provider>} />

        <Route path='/directorForm' element={<Provider store={mainStore}><DirectorForm /></Provider>} />

        <Route path='/anotherReserveForm' element={<Provider store={mainStore}><AnotherReserveForm /></Provider>} />

        <Route path="/mot" element={<Provider store={mainStore}><Mot /></Provider>} />

        <Route path="/zags" element={<Provider store={mainStore}><Zags /></Provider>} />

        <Route path="questionnarieKai" element={<Provider store={mainStore}> <QuestionnarieKAIPage /></Provider>} />

        <Route path="questionnarie" element={<Provider store={mainStore}> <QuestionnaireBelgorodPage /></Provider>} />

        <Route path="/activityAnalytics" element={<Provider store={mainStore}><ActivityAnalyticsPage /></Provider>} />

        <Route path="/experienceAnalysis" element={<Provider store={mainStore}><ExperienceAnalysisPage /></Provider>} />
      </Routes>
    </div>
  )
}

export default App
