export const formData = [
    {
        id: 0,
        type: 'bottomInput',
        text: 'ФИО',
        placeholder: '',
    },
    {
        id: 1,
        type: 'bottomInput',
        text: 'Дата рождения',
        placeholder: '',
    },
    {
        id: 2,
        type: 'bottomInput',
        text: 'Место рождения',
        placeholder: '',
    },
    {
        id: 3,
        type: 'bottomInput',
        text: 'Семейное положение',
        placeholder: '',
    },
    {
        id: 4,
        type: 'bottomInput',
        text: 'Дети',
        placeholder: '(количество, возраст)',
    },
    {
        id: 5,
        type: 'bottomInput',
        text: 'Адрес регистрации',
        placeholder: '',
    },
    {
        id: 6,
        type: 'bottomInput',
        text: 'Адрес проживания',
        placeholder: '',
    },
    {
        id: 7,
        type: 'bottomInput',
        text: 'Контактный телефон',
        placeholder: '',
    },
    {
        id: 8,
        type: 'textarea',
        text: 'На какие результаты направлена деятельность Института развития города?',
        placeholder: '',
    },
    {
        id: 9,
        type: 'textarea',
        text: 'На какие результаты направлена деятельность Вашего отдела?',
        placeholder: '',
    },
    {
        id: 10,
        type: 'table',
        text: 'Образование (учебные заведения, курсы)',
        columns: [
            {
                id: 0,
                text: 'Год поступления и окончания',
            },
            {
                id: 1,
                text: 'Учебное заведение',
            },
            {
                id: 2,
                text: 'Факультет, специальность',
            },
            {
                id: 3,
                text: 'Уровень образования',
            },
        ],
    },
    {
        id: 11,
        type: 'table',
        text: 'Опыт работы',
        columns: [
            {
                id: 0,
                text: 'Период работы (м.г/м.г)',
            },
            {
                id: 1,
                text: 'Должность, место работы',
            },
            {
                id: 2,
                text: 'Причина поиска (смены) работы',
            },
        ],
    },
    {
        id: 12,
        type: 'textarea',
        text: 'Перечислите подробно все должностные обязанности, выполняемые Вами.',
        placeholder: '',
    },
    {
        id: 13,
        type: 'textarea',
        text: 'Перечислите три Ваших главных личных результата, достижением которых Вы гордитесь.',
        placeholder: '',
    },
    {
        id: 14,
        type: 'textarea',
        text: 'В подготовке каких проектов Вы принимали участие? Опишите Ваш личный вклад в их реализацию.',
        placeholder: '',
    },
    {
        id: 15,
        type: 'textarea',
        text: 'Опишите зоны профессиональной деятельности, в которых Вы обладаете наибольшим практическим опытом и специальными знаниями.',
        placeholder: '',
    },
    {
        id: 16,
        type: 'textarea',
        text: 'Ваши преимущества.',
        placeholder: '(деловые, личностные качества)',
    },
    {
        id: 17,
        type: 'textarea',
        text: 'Что, по Вашему мнению, стимулирует людей работать наиболее эффективно?',
        placeholder: '',
    },
    {
        id: 18,
        type: 'textarea',
        text: 'Чем привлекательна работа в Институте развития города?',
        placeholder: '',
    },
    {
        id: 19,
        type: 'checkbox',
        text: 'Во время проведения переговоров, какими Вы предпочитаете пользоваться стратегиями (отметить)',
        variants: [
            {
                id: 0,
                text: 'противоборство, отстаивание своих интересов',
            },
            {
                id: 1,
                text: 'компромисс',
            },
            {
                id: 2,
                text: 'сотрудничество',
            },
            {
                id: 3,
                text: 'уступка',
            },
            {
                id: 4,
                text: 'избегание',
            },
        ],
        max: 0,
    },
    {
        id: 20,
        type: 'checkbox',
        text: 'При каких условиях Вы готовы возглавить решение задачи (отметьте 2 варианта):',
        variants: [
            {
                id: 0,
                text: 'Вы являетесь начальником в данной группе',
            },
            {
                id: 1,
                text: 'Вы точно знаете, как решать данную задачу',
            },
            {
                id: 2,
                text: 'кроме Вас больше некому взять на себя ответственность',
            },
            {
                id: 3,
                text: 'если есть более опытные коллеги, не буду мешать им руководить',
            },
            {
                id: 4,
                text: 'это дело руководителя назначать ответственных за задачу',
            },
        ],
        max: 2,
    },
    {
        id: 21,
        type: 'checkbox',
        text: 'В каком случае Вы готовы (или не готовы) отстаивать свою точку зрения, которую считаете правильной, перед вышестоящим руководством? Отметьте один вариант.',
        variants: [
            {
                id: 0,
                text: 'не буду спорить, так как начальник отвечает за все',
            },
            {
                id: 1,
                text: 'выскажу свое мнение, но спорить не буду',
            },
            {
                id: 2,
                text: 'буду отстаивать, если лично отвечаю за конечный результат',
            },
            {
                id: 3,
                text: 'буду отстаивать, если не отвечаю за конечный результат, но от моего результата в значительной степени зависит успех работы',
            },
            {
                id: 4,
                text: 'буду отстаивать, чтобы не пострадала репутация организации',
            },
        ],
        max: 1,
    },
    {
        id: 22,
        type: 'textarea',
        text: 'Что в перспективе Вы ожидаете от работы в Институте развития города?',
        placeholder: '',
    },
    {
        id: 23,
        type: 'textarea',
        text: 'Увлечения, хобби',
        placeholder: '',
    },
    {
        id: 24,
        type: 'checkboxAgreement',
        agreements: [
            {
                id: 0,
                text: 'Я подтверждаю достоверность всего изложенного выше и не возражаю против проверки.',
            },
            {
                id: 1,
                text: 'Я согласен (-на) пройти оценку компетенций и не претендую на получение материалов и результатов оценки.',
            },
        ],
        max: 0,
    },
]

export const sendInfo = {
    serviceId: 'service_kzkjo86',
    templateId: 'template_8m021m9',
    publicKey: 'OVUK1ODoKmjBhdm8q',
}