import { React } from 'react'
import { Greeting, Person, TaskOne, Slider, Paths, Information, AfterTaskOne, TaskThree, PathsAnalysis } from '../orgtest/components'
import { useDispatch, useSelector } from 'react-redux'
import { setStartTime } from '../orgtest/features/counter/PersonReducer'
import MediaQuery from 'react-responsive'

import Navbar from '../components/Navbar'

const OrgTest = () => {
  const dispatch = useDispatch()

  const startDateTime = new Date().toString()

  const isFinish = useSelector((state) => state.person.isFinish)

  dispatch(setStartTime(startDateTime))

  return (
    <div className="background">
      <MediaQuery minWidth={1640}>
        <div className={`${isFinish === true ? 'element-hidden' : ''}`}>
            <Navbar />
            <Greeting />
            <Person />
            <TaskOne />
            <Slider />
            <TaskThree />
            <Information />
            <Paths />
            <AfterTaskOne />
            <PathsAnalysis />
        </div>

        <div className={`${isFinish === true ? '' : 'element-hidden'} width-full display-flex`}>
            <p className="width-one-third margin-inline-auto padding-all border-main border-radius background-color-white text-justify-center">Результат успешно отправлен</p>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1639}>
        <Navbar />
        <p className="width-one-third margin-inline-auto padding-all border-main border-radius background-color-white text-justify-center">Мобильная версия не поддерживается</p>
      </MediaQuery>
    </div>
  );
}

export default OrgTest;