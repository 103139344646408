import React, { useState } from 'react'
import AddFile from './components/AddFile'
import UsersCards from './components/UsersCards/UsersCards'
import { isEmptyTestObject } from './helpers/isEmptyTestObject'

const Parser = () => {

    const [testObject, setTestObject] = useState({
        mainHeader: '',
        usersHeaders: [],
        answersHeaders: [],
        usersCards: [],
    })

    const show = () => {
        // console.log(testObject)
    }
    
    return (
        <article className='width-full'>
            <AddFile isEmptyObject={isEmptyTestObject(testObject)} setObject={setTestObject} />

            <UsersCards isEmptyObject={isEmptyTestObject(testObject)} mainHeader={testObject.mainHeader} usersCards={testObject.usersCards} />

            {/* <button onClick={show}>Показать</button> */}
        </article>
    )
}

export default Parser