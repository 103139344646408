import React from 'react'

const UserFullCardQuestion = ({ question }) => {
    return (
        <div className=''>
            <h5 className='font-weight-bold margin-bottom'>Вопрос: {question?.questionId}</h5>

            <table className='width-full'>
                <thead>
                    <tr><th className='userTableTh text-justify-center'>Текст вопроса</th><th className='userTableTh text-justify-center'>Ответ</th><th className='userTableTh text-justify-center'>Статус ответа</th></tr>
                </thead>

                <tbody>
                    <tr><td className='userTableTd'>{question?.questionText}</td><td className='userTableTd'>{question?.questionAnswer}</td><td className='userTableTd text-justify-center'>{question?.questionStatus}</td></tr>
                </tbody>
            </table>
        </div>
    )
}

export default UserFullCardQuestion