import React from 'react'
import { useDispatch } from 'react-redux'
import { setPersonName, setPersonSurname, setPersonMiddleName } from '../features/counter/PersonReducer'

const Person = () => {

  const dispatch = useDispatch()

  const setName = (event) => {
    dispatch(setPersonName(event.target.value))
  }

  const setSurname = (event) => {
    dispatch(setPersonSurname(event.target.value))
  }

  const setMiddleName = (event) => {
    dispatch(setPersonMiddleName(event.target.value))
  }

  return (
    <div className="width-full height-3-rem margin-bottom-large display-flex justify-content-center">
      <form className="display-flex flex-direction-row">
        <input 
          className="personInput" 
          type="text" 
          onChange={setSurname}
          placeholder="Введите фамилию"
        />

        <input 
          className="personInput" 
          type="text"
          onChange={setName}
          placeholder="Введите имя"
        />

        <input 
          className="personInput" 
          type="text" 
          onChange={setMiddleName}
          placeholder="Введите отчество"
        />
      </form>
        
      
    </div>
  )
}

export default Person