import { motColumns } from '../data/motData'
import { cleanCheckboxArray } from './checkboxArray'

const languageLevelArray = [
    'начальный',
    'средний',
    'продвинутый',
    'родной',
]

export const createStringParagraph = (input) => {
    const { type, text, placeholder, value } = input

    return `<p><span style="font-weight: bold;">${type === 'simpleInput' ? placeholder : text}:</span> ${value}</p>`
}

export const createCheckboxParagraph = (input) => {
    const { text, variants, value } = input

    const cleanValue = cleanCheckboxArray(value)

    let result = `<p style="font-weight: bold;">${text}:</p><ul>`

    for (let i = 0; i < cleanValue.length; i++) {
        const current = cleanValue[i]

        if (typeof current === 'number') {
            const currentValue = variants[current].text

            result += `<li>${currentValue}</li>`
        }
    }

    if (input.withInput) {
        result += `<li>Свой ответ: ${cleanValue[cleanValue.length - 1]}</li>`
    }

    result += '</ul>'

    return result
}

export const createMultipleCheckboxParagraph = (input) => {
    const { text, variants, value } = input

    const variantRegex = /\d/

    const cleanValue = cleanCheckboxArray(value)

    let result = `<p style="font-weight: bold;">${text}:</p><ul>`

    for (let i = 0; i < cleanValue.length; i++) {
        const current = cleanValue[i]

        if (current.match(variantRegex)) {
            const [variantId, variantValue] = current.split('.')

            const currentValue = variants[variantId].text

            result += `<li>${currentValue}, ${languageLevelArray[variantValue]}</li>`
        }
    }

    if (input.withInput) {
        const userVariant = cleanValue[cleanValue.length - 1]

        result += `${userVariant.length !== 0 ? `<li>Свой ответ: ${userVariant}</li>` : ''}`
    }

    result += '</ul>'

    return result
}

export const createTable = (input) => {
    const { text, columns, value } = input

    let result = `<table style="border-collapse: collapse;"><caption style="text-align: start; font-weight: bold;">${text}</caption><thead><tr>`

    for (let i = 0; i < columns.length; i++) {
        result += `<th style="padding: 5px; border: 2px solid black;">${columns[i].text}</th>`
    }

    result += '</tr></thead><tbody>'

    for (let i = 0; i < value.length; i++) {
        result += '<tr>'

        const currentArray = value[i]

        for (let k = 0; k < currentArray.length; k++) {
            result += `<td style="padding: 5px; border: 2px solid black;">${currentArray[k]}</td>`
        }

        result += '</tr>'
    }

    result += '</tbody></table>'

    return result
}

export const createAgreement = (input) => {
    const values = input.agreements
    let result = ''

    for (let i = 0; i < values.length; i++) {
        result += `<p>${values[i].text}</p>`
    }

    return result
}

export const createDateParagraph = () => {
    const date = new Date()
    const dateString = date.toLocaleString('ru-RU')

    return `<p>Дата: ${dateString}</p>`
}

export const createMotTable = (result) => {
    let resultString = `<table style="border-collapse: collapse;"><caption style="text-align: start; font-weight: bold;">Результаты:</caption>`

    for (let i = 0; i < motColumns.length; i++) {
        resultString += `<tr><th style="padding: 5px; border: 2px solid black;">${motColumns[i]}</th><td style="padding: 5px; border: 2px solid black;">${result[i]}</td></tr>`
    }

    resultString += '</table>'

    return resultString
}

export const createRussianTestTable = (variant, results, rightAnswers, mark) => {
    let resultString = `<div><table style="border-collapse: collapse;"><caption style="text-align: start; font-weight: bold;">Вариант ${variant}:</caption><tr><th style="padding: 5px; border: 2px solid black;">Номер</th><th style="padding: 5px; border: 2px solid black;">Выбранный вариант</th><th style="padding: 5px; border: 2px solid black;">Результат</th></tr>`

    for (let number of Object.keys(results)) {
        resultString += `<tr><td style="padding: 5px; border: 2px solid black;">${number}</td><td style="padding: 5px; border: 2px solid black;">${results[number].variant}</td>`

        if (results[number].isRight) {
            resultString += `<td style="padding: 5px; border: 2px solid black; color: green;">Верно</td>`
        } else {
            resultString += `<td style="padding: 5px; border: 2px solid black; color: red;">Неверно</td>`
        }

        resultString += '</tr>'
    }

    resultString += '</table>'

    resultString += `<p>Правильных ответов: ${rightAnswers}</p><p>Итоговая оценка: ${mark}</p></div>`

    return resultString
}

export const createMotivationProfileTable = (result) => {
    const factorsNames = ['Высокий заработок', 'Физические условия работы', 'Потребность в инструкциях', 'Социальные контакты', 'Взаимоотношения', 'Признание', 'Стремление к достижениям', 'Власть и влиятельность', 'Разнообразие и перемены', 'Креативность', 'Самосовершенствование', 'Интересная и полезная работа']

    let resultString = `<div><table style="border-collapse: collapse;">`

    for (let i = 0; i < Object.keys(result).length; i++) {
        resultString += `<tr><td style="padding: 5px; border: 2px solid black; font-weight: bold;">${factorsNames[i]}</td><td style="padding: 5px; border: 2px solid black;">${result[i + 1]}</td></tr>`
    }

    resultString += '</table></div>'

    return resultString
}