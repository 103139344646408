import { useState } from 'react'
import { createTableRowArray } from '../helpers/createTableRowArray'

export const useInputsValues = (tableId, columns, addFunction, deleteFunction) => {
    const array = []

    for (let i = 0; i < columns.length; i++) {
        array.push({
            id: i,
            placeholder: columns[i].text,
            value: '',
        })
    }

    const [inputsValues, setInputsValues] = useState(array)
    const [isOpen, setIsOpen] = useState(false)

    const onChange = (id, event) => {
        const oldArray = [...inputsValues]
        
        oldArray[id].value = event.target.value

        setInputsValues(oldArray)
    }

    const clear = () => {
        setInputsValues(array)
    }

    const open = () => {
        setIsOpen(true)
    }

    const close = () => {
        setIsOpen(false)
        clear()
    }

    const addValue = () => {
        const object = createTableRowArray(inputsValues)

        addFunction(tableId, object)
        clear()
        close()
    }

    const deleteValue = () => {
        deleteFunction(tableId)
    }

    return {
        inputsValues,
        onChange,
        addValue,
        deleteValue,
        isOpen,
        open,
        close,
    }
}

