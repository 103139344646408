import { useCallback, useState } from 'react'

const createInitialArray = (variants) => {
    const array = []

    for (let i = 0; i < variants.length; i++) {
        array.push('')
    }
    return array
}

export const createInputs = (value, variants) => {
    const inputs = []

    for (let i = 0; i < variants.length; i++) {
        inputs.push({
            id: i,
            value: value[i],
        })
    }
    return inputs
}

export const useMotivationInputs = (variants) => {
    const initialState = createInitialArray(variants)
    const [value, setValue] = useState(initialState)
    const inputs = [
        {
            id: 0,
            value: value[0],
        },
        {
            id: 1,
            value: value[1],
        },
        {
            id: 2,
            value: value[2],
        },
        {
            id: 3,
            value: value[3],
        },
    ]

    const onChange = useCallback((evt, inputId) => {
        const array = [...value]

        array[inputId] = evt.target.value

        setValue(array)
    }, [value])

    const clear = useCallback(() => {
        setValue(createInitialArray(variants))
    }, [variants])

    return {
        value,
        inputs,
        onChange,
        clear,
    }
}