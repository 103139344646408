import React from 'react'

import UserCard from './components/UserCard'
import UserPopup from './components/UserPopup/UserPopup'

import { usePopup } from './components/hooks/usePopup'
import UserFullCard from './components/UserFullCard/UserFullCard'
import { generateDocument } from './components/helpers/generateDocument'

const UsersCards = ({ isEmptyObject, mainHeader, usersCards }) => {

    const { currentCard, isOpen, open, close } = usePopup()

    return (
        <section className={`${isEmptyObject ? 'element-hidden' : ''} width-full`}>
            <h2 className='text-justify-center font-weight-bold'>{mainHeader}</h2>

            <div className='usersCards'>
                {
                    usersCards.map((card) => (
                        <UserCard key={card?.cardId} card={card} open={open} />
                    ))
                }
            </div>

            <div className='width-full display-flex justify-content-center margin-top-large margin-bottom-large'>
                <button className='buttonStyle' onClick={() => generateDocument(usersCards)}>Сгенерировать</button>
            </div>

            <UserPopup 
                isOpen={isOpen}
                children={
                    <UserFullCard currentCard={currentCard} close={close} />
                } 
            />
        </section>
    )
}

export default UsersCards