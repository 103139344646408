import React from 'react'

const MyRow = ({ columns }) => {
    return (
        <tr>
            {
                columns.map((column, index) => (
                    <td key={index} className="my-table__cell">{column}</td>
                ))   
            }
        </tr>
    )
}

export default React.memo(MyRow)