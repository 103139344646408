import React from 'react'

const TextParagraph = ({ input }) => {
    const { header, text } = input

    return (
        <div className="text-paragraph">
            <h2 className="text-paragraph__header">{header}</h2>

            <p className="text-paragraph__text">{text}</p>
        </div>
    )
}

export default React.memo(TextParagraph)