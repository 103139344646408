import React from 'react'

import Navbar from '../components/Navbar'

const FinishPage = () => {
    return (
        <div className="finish-page-container background">
            <Navbar />
            <p className="finish-page__text">Результат успешно отправлен</p>
        </div>
    )
}

export default FinishPage