import Form from '../components/Form/Form'

import { formData, sendInfo } from '../data/questionnaireKaiData'
import { formAnalysis } from '../helpers/analysis'

const QuestionnarieKAIPage = () => {
    return (
        <div className="margin-top-large">
            <Form formData={formData} sendInfo={sendInfo} analysis={formAnalysis} />
        </div>
    )
}

export default QuestionnarieKAIPage