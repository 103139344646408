import React from 'react'

const RoutePopup = ({closeFunction, addFunction, firstValue, firstValueOnChange, secondValue, secondValueOnChange, thirdValue, thirdValueOnChange}) => {
    return (
        <div className="width-full background-color-white border-main border-radius">
            <div className="width-full display-flex flex-direction-row margin-top-large margin-bottom-large justify-content-center">
                <input value={firstValue} onChange={firstValueOnChange} placeholder="Пункт посещения" className="orgInput width-half margin-right" />
                <input value={secondValue} onChange={secondValueOnChange} placeholder="Вход" className="orgInput width-one-fifths margin-right" />
                <input value={thirdValue} onChange={thirdValueOnChange} placeholder="Выход" className="orgInput width-one-fifths" />
            </div>

            <div className="display-flex flex-direction-row justify-content-center margin-bottom-large">
                <button onClick={addFunction} className="buttonStyle margin-right">Сохранить</button>
                <button onClick={closeFunction} className="buttonStyle">Закрыть</button>
            </div>
        </div>
    )
}

export default RoutePopup