import emailjs from '@emailjs/browser'

import { useState, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { setResultObject } from '../../../../store/reducers/FormReducer'

import { useNavigate } from 'react-router-dom'

export const useTimer = (input, inputs, analysis, sendInfo) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sendWithoutChecking = useCallback(() => {
        const { serviceId, templateId, publicKey } = sendInfo

        return () => {
            const resultObject = analysis(inputs)
            dispatch(setResultObject(resultObject))

            emailjs.send(
                serviceId,
                templateId,
                resultObject,
                publicKey)
                .then((result) => {
                    console.log(result.text)
                }, (error) => {
                    console.log(error.text)
                })

            navigate('/finish')
        }
    }, [inputs])

    const send = sendWithoutChecking()

    const [[hours, minutes, seconds], setTimer] = useState([input.hours, input.minutes, input.seconds])

    const tick = () => {
        if (hours === 0 && minutes === 0 && seconds === 0) {
            send()
        } else if (minutes === 0 && seconds === 0) {
            setTimer([hours - 1, 59, 59])
        } else if (seconds === 0) {
            setTimer([hours, minutes - 1, 59])
        } else {
            setTimer([hours, minutes, seconds - 1])
        }
    }

    return {
        hours,
        minutes,
        seconds,
        tick,
    }
}