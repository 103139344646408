import React, { useState } from 'react'

import Navbar from '../components/Navbar'
import { login } from '../helpers/api/login'

const StartPage = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const submit = async (evt) => {
        evt.preventDefault()

        const result = await login(username, password)
        console.log(result)
    }

    return (
        <div className="background">
            <Navbar />

            <form onSubmit={submit}>
                <input type="text" value={username} onChange={(evt) => { setUsername(evt.target.value) }} />

                <input type="text" value={password} onChange={(evt) => { setPassword(evt.target.value) }} />

                <button type='submit'>Войти</button>
            </form>
        </div>
    )
}

export default StartPage