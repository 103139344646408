const returnEmptyString = () => { return '' }

const returnEmptyArray = () => { return [] }

const returnEmptyObject = () => { return {} }

const returnCheckboxArray = (inputData) => {
    if (inputData.withInput === true) {
        const array = []
        array[inputData.variants.length] = ''

        return array
    }

    return []
}

export const returnFunctions = {
    'bottomInput': returnEmptyString,
    'simpleInput': returnEmptyString,
    'textarea': returnEmptyString,
    'select': returnEmptyString,
    'table': returnEmptyArray,
    'checkboxAgreement': returnEmptyArray,
    'motivation': returnEmptyObject,
    'checkbox': returnCheckboxArray,
    'multiCheckbox': returnCheckboxArray,
}