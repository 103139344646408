const checkAgreement = (input) => {
    const { agreements, value } = input

    if (agreements.length !== value.length) {
        return false
    }

    return true
}

const checkResult = (object) => {
    if (object.valuesResult === false || object.agreementResult === false) {
        return false
    }

    return true
}

export const checkAllValues = (inputs) => {
    const object = {
        valuesResult: true,
        message: '',
    }

    for (let input of inputs) {
        const currentType = input.type

        if (currentType === 'checkboxAgreement') {
            object.agreementResult = checkAgreement(input)
        }

        if (currentType === 'bottomInput' || currentType === 'textarea' || currentType === 'select' || currentType === 'checkbox' || currentType === 'table') {
            if (input.value.length === 0) {
                object.valuesResult = false
            }
        }

        if (currentType === 'motivation') {
            if (input.questions.length !== Object.keys(input.value).length) {
                object.valuesResult = false
            }
        }
    }
    return checkResult(object)
}