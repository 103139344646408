import { configureStore } from '@reduxjs/toolkit'
import TaskThreeReducer from '../features/counter/TaskThreeReducer'
import PathsReducer from '../features/counter/PathsReducer'
import AfterTaskOneReducer from '../features/counter/AfterTaskOneReducer'
import PersonReducer from '../features/counter/PersonReducer'
import PathsAnalysisReducer from '../features/counter/PathsAnalysisReducer'

export default configureStore({
    reducer: {
        taskThree: TaskThreeReducer,
        paths: PathsReducer,
        afterTaskOne: AfterTaskOneReducer,
        person: PersonReducer,
        pathsAnalysis: PathsAnalysisReducer,
    }
})