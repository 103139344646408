import {React, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeCheckedValue, changeCheckedValueString, setMissingValue, setDescriptionValue } from '../features/counter/AfterTaskOneReducer'

const AfterTaskOne = () => {

    const dispatch = useDispatch()

    const checkedValue = useSelector((state) => state.afterTaskOne.checkedValue)
    const missingValue = useSelector((state) => state.afterTaskOne.missingValue)
    const descriptionValue = useSelector((state) => state.afterTaskOne.descriptionValue)
    

    const checkedChange = () => {
        dispatch(changeCheckedValue())
        dispatch(changeCheckedValueString())
    }

    const missingChange = (event) => {
        dispatch(setMissingValue(event.target.value))
    }

    const descriptionChange = (event) => {
        dispatch(setDescriptionValue(event.target.value))
    }

    const missingTextareaRef = useRef(null)
    const descriptionTextareaRef = useRef(null)

    useEffect(() => {
        if (missingTextareaRef && missingTextareaRef.current) {
            missingTextareaRef.current.style.height = '0px'
            const scrollHeight = missingTextareaRef.current.scrollHeight
            missingTextareaRef.current.style.height = scrollHeight + 'px'
        }
    }, [missingValue])

    useEffect(() => {
        if (descriptionTextareaRef && descriptionTextareaRef.current) {
            descriptionTextareaRef.current.style.height = '0px'
            const scrollHeight = descriptionTextareaRef.current.scrollHeight
            descriptionTextareaRef.current.style.height = scrollHeight + 'px'
        }
    }, [descriptionValue])

    return (
        <div className="width-two-thirds margin-inline-auto margin-top-huge display-flex flex-direction-column padding-all">
        
            <label className="font-weight-bold width-half">
                Все ли пункты посещены:
                <input
                    type="checkbox"
                    value={checkedValue}
                    onChange={checkedChange}
                    className="margin-left-min width-min height-min accent background-color-white"
                />
            </label>
        
            <div className="width-full display-flex flex-direction-row">
                <div className="width-half display-flex flex-direction-column margin-right">
                    <h1 className="font-weight-bold">Отсутствуют:</h1>
                    <textarea
                        ref={missingTextareaRef}
                        type="text"
                        value={missingValue}
                        onChange={missingChange}
                        className="padding-all border-main border-radius input-resize-none input-overflow-hidden input-outline-none"
                    />
                </div>

                <div className="width-half display-flex flex-direction-column">
                    <h1 className="font-weight-bold">Опишите причины непосещения (кратко):</h1>
                    <textarea
                        ref={descriptionTextareaRef}
                        type="text"
                        value={descriptionValue}
                        onChange={descriptionChange}
                        className="padding-all border-main border-radius input-resize-none input-overflow-hidden input-outline-none"
                    />
                </div>
            </div>
        </div>
    )
}

export default AfterTaskOne