import React, { useState } from 'react'
import InformationDialog from './InformationDialog'

import Form from '../Form/Form'

const StartComponent = ({ information, formData, sendInfo, analysis }) => {
    const [isStarted, setIsStarted] = useState(false)

    return (
        <div>
            {
                isStarted ?

                    <Form information={information} formData={formData} sendInfo={sendInfo} analysis={analysis} />

                :

                    <InformationDialog children={information} close={() => setIsStarted(true)} />
            }
        </div>
    )
}

export default StartComponent