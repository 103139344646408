import React from 'react'

const MultipleCheckbox = ({ questionId, variantId, variantValue, text, max, value, onChange }) => {

    return (
        <label className="my-checkbox-label">
            <input className="my-checkbox visually-hidden" type="checkbox" checked={value.includes(variantValue) ? true : false} onChange={() => onChange(questionId, variantId, variantValue, max)} />

            <span className="my-checkbox-custom"></span>

            <span className="my-checkbox__text">{text}</span>
        </label>
    )
}

export default MultipleCheckbox