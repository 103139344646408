import React from 'react'

const ButtonsRow = ({ firstButton, secondButton }) => {
    return (
        <div className="my-table__buttons-container">
            <button className="my-button" onClick={firstButton.buttonFunction}>{firstButton.buttonText}</button>
            <button onClick={secondButton.buttonFunction} className="my-button">{secondButton.buttonText}</button>
        </div>
    )
}

export default React.memo(ButtonsRow)